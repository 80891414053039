import { Button, InputAdornment, TextField } from "@mui/material";
import { unitConversion } from "../../../../../../_shared/utils/uiHelpers";
import React, { useEffect, useState } from "react";
import _ from "lodash";

type NumberInputPropsType = {
  label: string | undefined | null;
  name: string | undefined;
  value: number | undefined | null;
  error: boolean;
  isEditing: boolean;
  onBlur: any;
  onChange: any;
  helperText: any;
  unit: any;
  onUnitChange: any;
};

const NumberField = (props: NumberInputPropsType) => {
  const units = ["lbs", "gms", "in", "cm"];

  const toggleUnit = () => {
    let newUnit = props.unit;
    if (props.isEditing) {
      if (props.unit === "lbs") {
        newUnit = "gms";
      } else if (props.unit === "gms") {
        newUnit = "lbs";
      } else if (props.unit === "cm³") {
        newUnit = "in³";
      } else if (props.unit === "in³") {
        newUnit = "cm³";
      }
      props.onUnitChange({
        name: props.name,
        newUnit: newUnit,
        newValue: unitConversion(props.value, props.unit, newUnit),
      });
    }
  };

  return (
    <React.Fragment>
      <TextField
        error={props.error}
        fullWidth
        helperText={props.helperText}
        label={props.label}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={props.value != null ? props.value : ""}
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="text"
                sx={{ minWidth: "15px", padding: 0, textTransform: "none" }}
                onClick={toggleUnit}
                disabled={!props.isEditing}
              >
                {props.unit}
              </Button>
            </InputAdornment>
          ),
          inputMode: "numeric",
        }}
        disabled={!props.isEditing}
      />
    </React.Fragment>
  );
};

export default NumberField;
