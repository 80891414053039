import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateProductGroupMutation } from "../../../../../redux/features/commerce/products/productGroupsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { APP } from "../../../../../_shared/utils/_urls";
import { ProductGroupType } from "../../../../../redux/features/commerce/products/types";

type AddProductGroupProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

const AddProductGroupModal = (props: AddProductGroupProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const [createProductGroup, { isLoading }] = useCreateProductGroupMutation();
  const [productGroupToAdd] = useState<
    ProductGroupType | Record<string, any>
  >();

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const submitHandler = (values: any) => {
    const payload = merge({}, productGroupToAdd, values);

    createProductGroup(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          navigate(`${APP.PRODUCT_GROUPS}/${result.createdProductGroup.id}`);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      is_active: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new Product Group</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.name}
            />
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              label="Description"
              name="description"
              multiline
              maxRows={5}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_active}
                  onChange={handleIsActive}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} //disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProductGroupModal;
