import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { APP } from "../../../_shared/utils/_urls";
import * as Yup from "yup";
import { PASSWORD_PATTERN } from "../../../_shared/utils/uiHelpers";
import lang from "../../../lang/en";
import {
  Alert,
  Box,
  FormControl,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useResetPasswordMutation } from "../../../redux/features/auth/authApi";
import { LoadingButton } from "@mui/lab";
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
  getErrorMessage,
} from "../../../_shared/utils/apiHelpers";

const UpdatePasswordPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetPassword, { isLoading, isError, error }] =
    useResetPasswordMutation();

  const submitHandler = (values: any) => {
    const payload = values;
    payload.hash = searchParams.get("hash");
    payload.email = searchParams.get("email");

    resetPassword(payload)
      .unwrap()
      .then((result) => {
        formik.resetForm();
        if (result) {
          navigate(APP.LOGIN);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmation_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("New Password is required")
        .max(255)
        .matches(PASSWORD_PATTERN, lang.error.invalid_password),
      confirmation_password: Yup.string()
        .required("You must re-enter the password is required")
        .max(255)
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .matches(PASSWORD_PATTERN, lang.error.invalid_password),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Paper sx={{ width: 300, backgroundColor: "#ffffff" }}>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Box sx={{ p: 2 }}>
            <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={2} sx={{ mt: 3 }}>
                <FormControl variant="outlined" fullWidth required>
                  <TextField
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    fullWidth
                    label="New Password"
                    name="password"
                    type="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    required
                    value={formik.values.password}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth required>
                  <TextField
                    error={Boolean(
                      formik.touched.confirmation_password &&
                        formik.errors.confirmation_password
                    )}
                    helperText={
                      formik.touched.confirmation_password &&
                      formik.errors.confirmation_password
                    }
                    {...formik.getFieldProps("confirmation_password")}
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    name="confirmation_password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    required
                    value={formik.values.confirmation_password}
                  />
                </FormControl>
              </Stack>

              <LoadingButton
                variant="contained"
                type="submit"
                disabled={isLoading}
                fullWidth={true}
                sx={{ mt: 2 }}
                loading={isLoading}
              >
                {isLoading ? "Processing ..." : "Submit"}
              </LoadingButton>
            </form>
          </Box>
        </Paper>
      </Box>
    </FormikProvider>
  );
};

export default UpdatePasswordPage;
