import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useGetPreviewQuery } from "../../../../../redux/features/tests/testsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  TestType,
  UpdateTestStatusRequestType,
} from "../../../../../redux/features/tests/types";
import { useAppSelector } from "../../../../../redux/hooks";
import { useState } from "react";

type GenerateTokenProps = {
  show: boolean;
  test: TestType | undefined;
  close: () => void;
};

const GeneratePreviewModal = (props: GenerateTokenProps) => {
  const { data = undefined, isFetching } = useGetPreviewQuery(
    props?.test?.id!,
    { skip: !props.show }
  );

  const onViewPreview = () => {
    if (data) {
      window.open(data?.data.url, "_blank", "popup=yes,menu=no,location=no");
    }
  };

  const handleClose = () => {
    props.close();
  };
  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>Generate Preview</DialogTitle>
      <DialogContent
        sx={{ mt: 3, justifyContent: "center", textAlign: "center" }}
      >
        {isFetching ? (
          <CircularProgress />
        ) : (
          <Stack spacing={2}>
            <Link component="button" onClick={onViewPreview}>
              Go To Preview
            </Link>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneratePreviewModal;
