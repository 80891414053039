import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  DexaBmdType,
  TestType,
  UpdateTestResultType,
} from "../../../../../../redux/features/tests/types";
import * as Yup from "yup";
import styles from "../dexa.module.css";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import {
  getAgeFromDateOfBirth,
  roundOff,
  unitConversion,
  validHeight,
  validWeight,
} from "../../../../../../_shared/utils/uiHelpers";
import NumberField from "../../shared/NumberField";
import ErrorPopup from "../../error-popup";

type DexaTestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

export const inputCheck = (formik: FormikProps<any>) => {
  const BMD_MARGIN = 4; // bmd should be between -4 and 4
  const ERROR_MARGIN = 1;

  let validForm: boolean = true;
  const errors: FormikErrors<DexaBmdType> = {};
  const error_message = " ";

  if (!validHeight(formik.values.height)) {
    errors.height = error_message;
  }
  if (!validWeight(formik.values.weight)) {
    errors.weight = error_message;
  }

  if (formik.values.weight != null) {
    let hasWeightError = false;

    // bmi should equal weight/height^2 * 703 (+/- 1)
    if (formik.values.height != null) {
      let bmi_check =
        (formik.values.weight / (formik.values.height * formik.values.height)) *
        703;
      if (
        formik.values.bmi != null &&
        Math.abs(formik.values.bmi - bmi_check) > ERROR_MARGIN
      ) {
        errors.bmi = error_message;
        errors.height = error_message;
        hasWeightError = true;
      }
    }
  }

  // bmd should be between -4 and 4
  if (formik.values.bmd != null) {
    if (Math.abs(formik.values.bmd * 1.0) > BMD_MARGIN + ERROR_MARGIN) {
      errors.bmd = error_message;
    }
  }

  if (Object.keys(errors).length !== 0) {
    console.log(errors);
    formik.setErrors({ ...errors });
    validForm = false;
  }
  return validForm;
};

const DexaBmdLimitedTest = (props: DexaTestType) => {
  const [results, setResults] = useState<DexaBmdType>();
  const [hipImage, sethipImage] = useState<any>();
  const [hipBmdImage, setHipBmdImage] = useState<any>();
  const [spineImage, setSpineImage] = useState<any>();
  const [spineBmdImage, setSpineBmdImage] = useState<any>();
  const [fullBodyImage, setFullBodyImage] = useState<any>();
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);
  const [errorPopup, displayErrorPopup] = useState<boolean>(false);

  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

  const imageSelectHandler = (event: any) => {
    setIsProcessingImage(true);
    const image_type = event.target.name;
    let fileToConvert = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      if (image_type === "hip_image_file") {
        sethipImage(reader.result);
      } else if (image_type === "hip_bmd_image_file") {
        setHipBmdImage(reader.result);
      } else if (image_type === "spine_image_file") {
        setSpineImage(reader.result);
      } else if (image_type === "spine_bmd_image_file") {
        setSpineBmdImage(reader.result);
      } else if (image_type === "full_body_image_file") {
        setFullBodyImage(reader.result);
      }

      setIsProcessingImage(false);
    };
    reader.readAsDataURL(fileToConvert);
  };

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    values.hip_image = hipImage;
    values.hip_bmd_image = hipBmdImage;
    values.spine_image = spineImage;
    values.spine_bmd_image = spineBmdImage;
    values.full_body_image = fullBodyImage;
    values.screen_type = "Limited";

    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
        setSubmitAttempt(0);
      });
  };

  const onErrorPopupClose = () => {
    displayErrorPopup(false);
  };

  const onErrorOverride = () => {
    displayErrorPopup(false);
    formik.setSubmitting(true);
    submitHandler(formik.values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      race: results?.race || "",
      weight: results?.weight || null,
      height: results?.height || null,
      bmd: results?.bmd || null,
      z_score: results?.z_score || null,
      bmi: results?.bmi || null,
      /* Neck */
      neck_area: results?.neck_area || null,
      neck_bmc: results?.neck_bmc || null,
      neck_bmd: results?.neck_bmd || null,
      neck_t_score: results?.neck_t_score || null,
      neck_z_score: results?.neck_z_score || null,

      neck_total_area: results?.neck_total_area || null,
      neck_total_bmc: results?.neck_total_bmc || null,
      neck_total_bmd: results?.neck_total_bmd || null,
      neck_total_t_score: results?.neck_total_t_score || null,
      neck_total_z_score: results?.neck_total_z_score || null,

      /* Hip */
      hip_l_one_area: results?.hip_l_one_area || null,
      hip_l_one_bmc: results?.hip_l_one_bmc || null,
      hip_l_one_bmd: results?.hip_l_one_bmd || null,
      hip_l_one_t_score: results?.hip_l_one_t_score || null,
      hip_l_one_z_score: results?.hip_l_one_z_score || null,
      hip_l_two_area: results?.hip_l_two_area || null,
      hip_l_two_bmc: results?.hip_l_two_bmc || null,
      hip_l_two_bmd: results?.hip_l_two_bmd || null,
      hip_l_two_t_score: results?.hip_l_two_t_score || null,
      hip_l_two_z_score: results?.hip_l_two_z_score || null,
      hip_l_three_area: results?.hip_l_three_area || null,
      hip_l_three_bmc: results?.hip_l_three_bmc || null,
      hip_l_three_bmd: results?.hip_l_three_bmd || null,
      hip_l_three_t_score: results?.hip_l_three_t_score || null,
      hip_l_three_z_score: results?.hip_l_three_z_score || null,
      hip_l_four_area: results?.hip_l_four_area || null,
      hip_l_four_bmc: results?.hip_l_four_bmc || null,
      hip_l_four_bmd: results?.hip_l_four_bmd || null,
      hip_l_four_t_score: results?.hip_l_four_t_score || null,
      hip_l_four_z_score: results?.total_diff_z_score || null,
      hip_total_area: results?.hip_total_area || null,
      hip_total_bmc: results?.hip_total_bmc || null,
      hip_total_bmd: results?.hip_total_bmd || null,
      hip_total_t_score: results?.hip_total_t_score || null,
      hip_total_z_score: results?.hip_total_z_score || null,
      major_osteoporotic_fracture: results?.major_osteoporotic_fracture || null,
      hip_fracture: results?.hip_fracture || null,
      original_scan_url: results?.original_scan_url || "",
      physician_read_url: results?.physician_read_url || "",

      hip_image: results?.hip_image || "",
      hip_bmd_image: results?.hip_bmd_image || "",
      spine_image: results?.spine_image || "",
      spine_bmd_image: results?.spine_bmd_image || "",
      full_body_image: results?.full_body_image || "",
    },
    validationSchema: Yup.object({
      original_scan_url: Yup.string().url(
        "You must enter a valid URL with http(s) in the URL."
      ),

      age: Yup.number().positive().typeError("Please enter a valid number."),
      height: Yup.number().typeError("Please enter a valid number."),
      weight: Yup.number().typeError("Please enter a valid number."),
      bmi: Yup.number().typeError("Please enter a valid number."),
      bmd: Yup.number().nullable().typeError("Please enter a valid number."),
      z_score: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        let validForm = inputCheck(formik);

        if (validForm) {
          helpers.setSubmitting(true);
          submitHandler(values);
        } else {
          setSubmitAttempt(submitAttempt + 1);
          displayErrorPopup(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    if (formik.values.weight != null && formik.values.height != null) {
      let bmi =
        (formik.values.weight! /
          (formik.values.height! * formik.values.height!)) *
        703;
      formik.setFieldValue("bmi", roundOff(bmi, 1));
    }
  }, [formik.values.weight, formik.values.height]);

  if (results !== undefined) {
    return (
      <Container>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Age"
                name="age"
                required
                size="small"
                error={Boolean(formik.touched.age && formik.errors.age)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.age}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required size="small">
                <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
                <Select
                  labelId="genderAtBirthLabel"
                  name="gender"
                  label="Gender at birth"
                  value={formik.values.gender}
                  error={Boolean(
                    formik.touched?.gender && formik.errors?.gender
                  )}
                  onChange={formik.handleChange}
                  disabled={!props.isEditing}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required size="small">
                <InputLabel id="race">Race</InputLabel>
                <Select
                  labelId="race"
                  name="race"
                  label="Race"
                  value={formik.values.race}
                  error={Boolean(formik.touched?.race && formik.errors?.race)}
                  onChange={formik.handleChange}
                  disabled={!props.isEditing}
                >
                  <MenuItem value={"Black"}>Black</MenuItem>
                  <MenuItem value={"Hispanic"}>Hispanic</MenuItem>
                  <MenuItem value={"White"}>White</MenuItem>
                  <MenuItem value={"All"}>Unknown</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.height && formik.errors.height)}
                fullWidth
                helperText={formik.touched.height && formik.errors.height}
                label="Height in Inches"
                name="height"
                size="small"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.height}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">inches</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.weight && formik.errors.weight)}
                fullWidth
                helperText={formik.touched.weight && formik.errors.weight}
                label="Weight"
                size="small"
                name="weight"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.weight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.bmi && formik.errors.bmi)}
                fullWidth
                helperText={formik.touched.bmi && formik.errors.bmi}
                label="BMI"
                name="bmi"
                size="small"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.bmi || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ textAlign: "left", mb: -1, color: "#b8a8b6" }}
              >
                Total BMD
              </Typography>
            </Grid>

            <Grid item md={4} xs={12}>
              <NumberField
                label="BMD"
                name="bmd"
                value={formik.values.bmd}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.bmd && formik.errors.bmd}
                error={Boolean(formik.touched.bmd && formik.errors.bmd)}
                unit="g/cm²"
                onUnitChange={() => {}}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.z_score && formik.errors.z_score)}
                fullWidth
                helperText={formik.touched.z_score && formik.errors.z_score}
                label="Z-Score"
                name="z_score"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.z_score}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                sx={{ textAlign: "left", mb: -1, color: "#b8a8b6" }}
              >
                Fracture Risk
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <NumberField
                label="Major Osteopotic Fracture"
                name="major_osteoporotic_fracture"
                value={formik.values.major_osteoporotic_fracture}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.major_osteoporotic_fracture &&
                  formik.errors.major_osteoporotic_fracture
                }
                error={Boolean(
                  formik.touched.major_osteoporotic_fracture &&
                    formik.errors.major_osteoporotic_fracture
                )}
                unit=""
                onUnitChange={() => {}}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.hip_fracture && formik.errors.hip_fracture
                )}
                fullWidth
                helperText={
                  formik.touched.hip_fracture && formik.errors.hip_fracture
                }
                label="Hip Fracture"
                name="hip_fracture"
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.hip_fracture}
                disabled={!props.isEditing}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                gutterBottom={false}
                sx={{ mb: -1, textAlign: "left", color: "#b8a8b6" }}
              >
                Neck
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, mx: 0 }} columns={20}>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Neck Area"
                  name="neck_area"
                  value={formik.values.neck_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_area && formik.errors.neck_area
                  }
                  error={Boolean(
                    formik.touched.neck_area && formik.errors.neck_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Neck BMC"
                  name="neck_bmc"
                  value={formik.values.neck_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.neck_bmc && formik.errors.neck_bmc}
                  error={Boolean(
                    formik.touched.neck_bmc && formik.errors.neck_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Neck BMD"
                  name="neck_bmd"
                  value={formik.values.neck_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.neck_bmd && formik.errors.neck_bmd}
                  error={Boolean(
                    formik.touched.neck_bmd && formik.errors.neck_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Neck T-Score"
                  name="neck_t_score"
                  value={formik.values.neck_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_t_score && formik.errors.neck_t_score
                  }
                  error={Boolean(
                    formik.touched.neck_t_score && formik.errors.neck_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Neck Z-Score"
                  name="neck_z_score"
                  value={formik.values.neck_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_z_score && formik.errors.neck_z_score
                  }
                  error={Boolean(
                    formik.touched.neck_z_score && formik.errors.neck_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total Area"
                  name="neck_total_area"
                  value={formik.values.neck_total_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_total_area &&
                    formik.errors.neck_total_area
                  }
                  error={Boolean(
                    formik.touched.neck_total_area &&
                      formik.errors.neck_total_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total BMC"
                  name="neck_total_bmc"
                  value={formik.values.neck_total_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_total_bmc &&
                    formik.errors.neck_total_bmc
                  }
                  error={Boolean(
                    formik.touched.neck_total_bmc &&
                      formik.errors.neck_total_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total BMD"
                  name="neck_total_bmd"
                  value={formik.values.neck_total_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_total_bmd &&
                    formik.errors.neck_total_bmd
                  }
                  error={Boolean(
                    formik.touched.neck_total_bmd &&
                      formik.errors.neck_total_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total T-Score"
                  name="neck_total_t_score"
                  value={formik.values.neck_total_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_total_t_score &&
                    formik.errors.neck_total_t_score
                  }
                  error={Boolean(
                    formik.touched.neck_total_t_score &&
                      formik.errors.neck_total_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total Z-Score"
                  name="neck_total_z_score"
                  value={formik.values.neck_total_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.neck_total_z_score &&
                    formik.errors.neck_total_z_score
                  }
                  error={Boolean(
                    formik.touched.neck_total_z_score &&
                      formik.errors.neck_total_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography
                variant="h6"
                gutterBottom={false}
                sx={{ mb: -1, textAlign: "left", color: "#b8a8b6" }}
              >
                Hip
              </Typography>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1, mx: 0 }} columns={20}>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L1 Area"
                  name="hip_l_one_area"
                  value={formik.values.hip_l_one_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_one_area &&
                    formik.errors.hip_l_one_area
                  }
                  error={Boolean(
                    formik.touched.hip_l_one_area &&
                      formik.errors.hip_l_one_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L1 BMC"
                  name="hip_l_one_bmc"
                  value={formik.values.hip_l_one_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_one_bmc && formik.errors.hip_l_one_bmc
                  }
                  error={Boolean(
                    formik.touched.hip_l_one_bmc && formik.errors.hip_l_one_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L1 BMD"
                  name="hip_l_one_bmd"
                  value={formik.values.hip_l_one_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_one_bmd && formik.errors.hip_l_one_bmd
                  }
                  error={Boolean(
                    formik.touched.hip_l_one_bmd && formik.errors.hip_l_one_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L1 T-Score"
                  name="hip_l_one_t_score"
                  value={formik.values.hip_l_one_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_one_t_score &&
                    formik.errors.hip_l_one_t_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_one_t_score &&
                      formik.errors.hip_l_one_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L1 Z-Score"
                  name="hip_l_one_z_score"
                  value={formik.values.hip_l_one_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_one_z_score &&
                    formik.errors.hip_l_one_z_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_one_z_score &&
                      formik.errors.hip_l_one_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L2 Area"
                  name="hip_l_two_area"
                  value={formik.values.hip_l_two_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_two_area &&
                    formik.errors.hip_l_two_area
                  }
                  error={Boolean(
                    formik.touched.hip_l_two_area &&
                      formik.errors.hip_l_two_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L2 BMC"
                  name="hip_l_two_bmc"
                  value={formik.values.hip_l_two_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_two_bmc && formik.errors.hip_l_two_bmc
                  }
                  error={Boolean(
                    formik.touched.hip_l_two_bmc && formik.errors.hip_l_two_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L2 BMD"
                  name="hip_l_two_bmd"
                  value={formik.values.hip_l_two_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_two_bmd && formik.errors.hip_l_two_bmd
                  }
                  error={Boolean(
                    formik.touched.hip_l_two_bmd && formik.errors.hip_l_two_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L2 T-Score"
                  name="hip_l_two_t_score"
                  value={formik.values.hip_l_two_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_two_t_score &&
                    formik.errors.hip_l_two_t_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_two_t_score &&
                      formik.errors.hip_l_two_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L2 Z-Score"
                  name="hip_l_two_z_score"
                  value={formik.values.hip_l_two_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_two_z_score &&
                    formik.errors.hip_l_two_z_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_two_z_score &&
                      formik.errors.hip_l_two_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L3 Area"
                  name="hip_l_three_area"
                  value={formik.values.hip_l_three_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_three_area &&
                    formik.errors.hip_l_three_area
                  }
                  error={Boolean(
                    formik.touched.hip_l_three_area &&
                      formik.errors.hip_l_three_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L3 BMC"
                  name="hip_l_three_bmc"
                  value={formik.values.hip_l_three_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_three_bmc &&
                    formik.errors.hip_l_three_bmc
                  }
                  error={Boolean(
                    formik.touched.hip_l_three_bmc &&
                      formik.errors.hip_l_three_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L3 BMD"
                  name="hip_l_three_bmd"
                  value={formik.values.hip_l_three_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_three_bmd &&
                    formik.errors.hip_l_three_bmd
                  }
                  error={Boolean(
                    formik.touched.hip_l_three_bmd &&
                      formik.errors.hip_l_three_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L3 T-Score"
                  name="hip_l_three_t_score"
                  value={formik.values.hip_l_three_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_three_t_score &&
                    formik.errors.hip_l_three_t_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_three_t_score &&
                      formik.errors.hip_l_three_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L3 Z-Score"
                  name="hip_l_three_z_score"
                  value={formik.values.hip_l_three_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_three_z_score &&
                    formik.errors.hip_l_three_z_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_three_z_score &&
                      formik.errors.hip_l_three_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L4 Area"
                  name="hip_l_four_area"
                  value={formik.values.hip_l_four_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_four_area &&
                    formik.errors.hip_l_four_area
                  }
                  error={Boolean(
                    formik.touched.hip_l_four_area &&
                      formik.errors.hip_l_four_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L4 BMC"
                  name="hip_l_four_bmc"
                  value={formik.values.hip_l_four_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_four_bmc &&
                    formik.errors.hip_l_four_bmc
                  }
                  error={Boolean(
                    formik.touched.hip_l_four_bmc &&
                      formik.errors.hip_l_four_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L4 BMD"
                  name="hip_l_four_bmd"
                  value={formik.values.hip_l_four_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_four_bmd &&
                    formik.errors.hip_l_four_bmd
                  }
                  error={Boolean(
                    formik.touched.hip_l_four_bmd &&
                      formik.errors.hip_l_four_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L4 T-Score"
                  name="hip_l_four_t_score"
                  value={formik.values.hip_l_four_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_four_t_score &&
                    formik.errors.hip_l_four_t_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_four_t_score &&
                      formik.errors.hip_l_four_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="L4 Z-Score"
                  name="hip_l_four_z_score"
                  value={formik.values.hip_l_four_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_l_four_z_score &&
                    formik.errors.hip_l_four_z_score
                  }
                  error={Boolean(
                    formik.touched.hip_l_four_z_score &&
                      formik.errors.hip_l_four_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total Area"
                  name="hip_total_area"
                  value={formik.values.hip_total_area}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_total_area &&
                    formik.errors.hip_total_area
                  }
                  error={Boolean(
                    formik.touched.hip_total_area &&
                      formik.errors.hip_total_area
                  )}
                  unit="cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total BMC"
                  name="hip_total_bmc"
                  value={formik.values.hip_total_bmc}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_total_bmc && formik.errors.hip_total_bmc
                  }
                  error={Boolean(
                    formik.touched.hip_total_bmc && formik.errors.hip_total_bmc
                  )}
                  unit="g"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total BMD"
                  name="hip_total_bmd"
                  value={formik.values.hip_total_bmd}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_total_bmd && formik.errors.hip_total_bmd
                  }
                  error={Boolean(
                    formik.touched.hip_total_bmd && formik.errors.hip_total_bmd
                  )}
                  unit="g/cm²"
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total T-Score"
                  name="hip_total_t_score"
                  value={formik.values.hip_total_t_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_total_t_score &&
                    formik.errors.hip_total_t_score
                  }
                  error={Boolean(
                    formik.touched.hip_total_t_score &&
                      formik.errors.hip_total_t_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
              <Grid item lg={4} md={5} sm={10} xs={20}>
                <NumberField
                  label="Total Z-Score"
                  name="hip_total_z_score"
                  value={formik.values.hip_total_z_score}
                  isEditing={props.isEditing}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.hip_total_z_score &&
                    formik.errors.hip_total_z_score
                  }
                  error={Boolean(
                    formik.touched.hip_total_z_score &&
                      formik.errors.hip_total_z_score
                  )}
                  unit=""
                  onUnitChange={() => {}}
                />
              </Grid>
            </Grid>

            <Grid item md={12} sx={{ mt: 2 }}>
              <TextField
                error={Boolean(
                  formik.touched.original_scan_url &&
                    formik.errors.original_scan_url
                )}
                fullWidth
                label="Link to Original Scan URL"
                name="original_scan_url"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.original_scan_url}
                disabled={!props.isEditing}
                helperText={
                  formik.touched.original_scan_url &&
                  formik.errors.original_scan_url
                }
              />
            </Grid>
            <Grid item md={12} sx={{ mt: 2 }}>
              <TextField
                error={Boolean(
                  formik.touched.physician_read_url &&
                    formik.errors.physician_read_url
                )}
                fullWidth
                label="Link to Physician Read"
                name="physician_read_url"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.physician_read_url}
                disabled={!props.isEditing}
                helperText={
                  formik.touched.physician_read_url &&
                  formik.errors.physician_read_url
                }
              />
            </Grid>
          </Grid>
          <Grid item md={12} sx={{ mt: 3 }}>
            {props.isEditing && (
              <Box>
                Hip Image:&nbsp;
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="hip_image_file"
                  />
                </LoadingButton>
              </Box>
            )}
            {(hipImage || formik.values.hip_image) && (
              <p>
                Hip Image:
                <br />
                <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                  <img
                    src={hipImage || formik.values.hip_image}
                    alt="hip image"
                    className={styles.BodyImagePreview}
                  />
                </Paper>
              </p>
            )}
          </Grid>
          <Grid item md={12} sx={{ mt: 3 }}>
            {props.isEditing && (
              <Box>
                Hip BMD Image:&nbsp;
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="hip_bmd_image_file"
                  />
                </LoadingButton>
              </Box>
            )}
            {(hipBmdImage || formik.values.hip_bmd_image) && (
              <p>
                Hip BMD Image:
                <br />
                <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                  <img
                    src={hipBmdImage || formik.values.hip_bmd_image}
                    alt="hip bmd image"
                    className={styles.BodyImagePreview}
                  />
                </Paper>
              </p>
            )}
          </Grid>
          <Grid item md={12} sx={{ mt: 3 }}>
            {props.isEditing && (
              <Box>
                Spine Image:&nbsp;
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="spine_image_file"
                  />
                </LoadingButton>
              </Box>
            )}
            {(spineImage || formik.values.spine_image) && (
              <p>
                Spine Image:
                <br />
                <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                  <img
                    src={spineImage || formik.values.spine_image}
                    alt="spine image"
                    className={styles.BodyImagePreview}
                  />
                </Paper>
              </p>
            )}
          </Grid>

          <Grid item md={12} sx={{ mt: 3 }}>
            {props.isEditing && (
              <Box>
                Spine BMD Image:&nbsp;
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="spine_bmd_image_file"
                  />
                </LoadingButton>
              </Box>
            )}
            {(spineBmdImage || formik.values.spine_bmd_image) && (
              <p>
                Spine Bmd Image:
                <br />
                <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                  <img
                    src={spineBmdImage || formik.values.spine_bmd_image}
                    alt="spine image"
                    className={styles.BodyImagePreview}
                  />
                </Paper>
              </p>
            )}
          </Grid>
          <Grid item md={12} sx={{ mt: 3 }}>
            {props.isEditing && (
              <Box>
                Full Body Image:&nbsp;
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="full_body_image_file"
                  />
                </LoadingButton>
              </Box>
            )}
            {(fullBodyImage || formik.values.full_body_image) && (
              <p>
                Full Body Image:
                <br />
                <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                  <img
                    src={fullBodyImage || formik.values.full_body_image}
                    alt="spine image"
                    className={styles.BodyImagePreview}
                  />
                </Paper>
              </p>
            )}
          </Grid>
          {props.isEditing && (
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 3, justifyContent: "flex-end" }}
              >
                <Button
                  variant="text"
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Stack>
              <p>&nbsp;</p>
            </Box>
          )}
          <ErrorPopup
            show={errorPopup}
            submits={submitAttempt}
            onClose={onErrorPopupClose}
            onOverride={onErrorOverride}
          />
        </form>
      </Container>
    );
  } else {
    return <p>Loading ...</p>;
  }
};

export default DexaBmdLimitedTest;
