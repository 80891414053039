import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import React from "react";
import {
  AvailabilityType,
  AvailableDateType,
} from "../../../../../../../redux/features/schedule/types";
import { useGetPreviewQuery } from "../../../../../../../redux/features/schedule/scheduleApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import _ from "lodash";
import ButtonGroup, { OptionType } from "../ButtonGroup";
import { formatTimeString } from "../../../../../../../_shared/utils/uiHelpers";

type PreviewAvailabilityProps = {
  partner_test_id: string | undefined;
  show: boolean;
  doneShowing: () => void;
};

const AvailalableDay = (
  props: PickersDayProps<Date> & { highlightedDays?: number[] }
) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.getDate()) >= 0;

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      className=""
    />
  );
};

const PreviewAvailabilityModal = (props: PreviewAvailabilityProps) => {
  const [availability, setAvailability] = useState<AvailabilityType>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedAvailableDate, setSelectedAvailableDate] =
    useState<AvailableDateType | null>();
  const handleClose = () => {
    props.doneShowing();
  };

  const handleTimeChange = (value: string | null) => {
    setSelectedTime(value);
  };

  const {
    data = undefined,
    isLoading,
    isSuccess,
    refetch,
  } = useGetPreviewQuery(props.partner_test_id || skipToken);

  useEffect(() => {
    if (isSuccess) {
      setAvailability(data?.availability);
    }
  }, [data]);

  console.log(selectedDate);

  useEffect(() => {
    const selectedAvalableDate = _.find(availability?.availableDates, (ad) => {
      return ad.available_date === selectedDate?.toISOString().split("T")[0];
    });
    setSelectedAvailableDate(selectedAvalableDate);
    //setSelectedTime(null);
  }, [selectedDate]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Preview Availability</DialogTitle>

      <DialogContent>
        <React.Fragment>
          <Grid container spacing={{ xs: 0, sm: 0, md: 2 }}>
            <Grid
              item
              className="availability-picker"
              sx={{
                width: { xs: "100%", sm: "300px", md: "350px" },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateCalendar
                  renderLoading={() => <DayCalendarSkeleton />}
                  disablePast={true}
                  loading={isLoading}
                  views={["day"]}
                  showDaysOutsideCurrentMonth
                  slots={{
                    day: AvailalableDay,
                  }}
                  shouldDisableDate={(day: Date) => {
                    return (
                      _.findIndex(availability?.availableDates, (ad) => {
                        return (
                          ad.available_date === day.toISOString().split("T")[0]
                        );
                      }) === -1
                    );
                  }}
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              sx={{
                height: "100%",
                overflow: "auto",
                width: { xs: "100%", sm: "100%", md: "auto" },
              }}
              xs={12}
              sm
              md
            >
              {selectedAvailableDate?.available_times && (
                <Box sx={{ height: "100%", overflow: "auto" }}>
                  <ButtonGroup
                    readonly={true}
                    options={_.map(
                      selectedAvailableDate?.available_times,
                      (at) => {
                        return {
                          value: at.available_time,
                          label: formatTimeString(at.available_time),
                        } as OptionType;
                      }
                    )}
                    value={selectedTime}
                    onChange={handleTimeChange}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewAvailabilityModal;
