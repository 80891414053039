import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPartnerQuery } from "../../../redux/features/partners/partnersApi";
import { PartnerType } from "../../../redux/features/partners/types";
import { APP } from "../../../_shared/utils/_urls";
import {
  formatAudit,
  formatShortDate,
  friendlyName,
} from "../../../_shared/utils/uiHelpers";

import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormInfo from "../../../_shared/components/FormInfo";
import UpdatePartnerModal from "./components/UpdatePartnerModal";
import DeletePartnerModal from "./components/DeletePartnerModal";
import { isNull } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import TestsForPartner from "./components/Tests";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const PartnerDetailPage = () => {
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showDeletePartnerModal, setShowDeletePartnerModal] = useState(false);
  const [partner, setPartner] = useState<PartnerType>();
  const params = useParams();
  const navigate = useNavigate();
  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetPartnerQuery(params.partner_id ?? skipToken);
  const [value, setValue] = React.useState(0);

  console.log(partner);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditProfileModal(true);
  };

  const onDoneEditing = (reload: boolean) => {
    setShowEditProfileModal(false);
    if (reload) {
      refetch();
    }
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeletePartnerModal(true);
  };

  const onDoneDeleting = (show: boolean) => {
    setShowDeletePartnerModal(show);
  };

  useEffect(() => {
    if (isSuccess) {
      setPartner(data);
    }
  }, [data]);

  if (partner !== undefined) {
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Breadcrumbs>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    navigate(`${APP.PARTNERS}`);
                  }}
                >
                  Partners
                </Link>
                <Typography color="text.primary">{partner?.name}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack direction="row">
                <Button variant="text" color="secondary" onClick={onEdit}>
                  Edit
                </Button>
                <Button variant="text" color="secondary" onClick={onDelete}>
                  Delete
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">{partner?.name}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            ></Grid>
          </Grid>
          <hr />
          <Stack spacing={2} sx={{ my: 3 }}>
            <FormInfo label="Abbreviation" value={partner?.abbreviation} />
            <FormInfo label="Phone" value={partner?.phone_number} />
            <FormInfo
              label="Address"
              value={
                <React.Fragment>
                  {partner?.address_line_1}
                  <br />
                  {partner?.address_line_2}
                  {partner?.address_line_2 !== null &&
                    partner?.address_line_2 !== "" && <br />}
                  {partner?.city}
                  {", "}
                  {partner?.state}
                  {", "}
                  {partner?.postal_code}
                </React.Fragment>
              }
            />
            <FormInfo
              label="Dropbox Folder Path"
              value={partner?.dropbox_path}
            />
            <FormInfo label="Contact Email" value={partner?.contact_email} />
            <FormInfo label="Timezone" value={partner?.timezone} />
            <FormInfo
              label="Other Identifier"
              value={partner?.other_identifier}
            />
            <FormInfo
              label="Created"
              value={formatAudit(
                partner?.created_by_name,
                partner?.created_at,
                ""
              )}
            />
            <FormInfo
              label="Updated"
              value={formatAudit(
                partner?.updated_by_name,
                partner?.updated_at,
                ""
              )}
            />
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Partner Tests"
            >
              <Tab label="Tests" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TestsForPartner partner_id={partner?.id} />
          </TabPanel>
        </Container>
        <UpdatePartnerModal
          partnerToUpdate={partner as PartnerType}
          doneEditing={onDoneEditing}
          show={showEditProfileModal}
        />
        <DeletePartnerModal
          partnerToDelete={partner as PartnerType}
          doneDeleting={onDoneDeleting}
          show={showDeletePartnerModal}
        />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default PartnerDetailPage;
