import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  GetProductsResult,
  GetProductResult,
  ProductType,
  CreateProductResult,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const ProductsApi = createApi({
  reducerPath: "ProductApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllProducts: builder.query<ProductType[], {}>({
      query() {
        return {
          url: `products`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetProductsResult) => {
        return result.data;
      },
    }),
    getProductsByProductGroup: builder.query<ProductType[], string>({
      query(product_group_id) {
        return {
          url: `products/groups/${product_group_id}/products`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetProductsResult) => {
        return result.data;
      },
    }),
    getProductsByProductType: builder.query<ProductType[], string>({
      query(product_type_name) {
        return {
          url: `products/product_type/${product_type_name}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetProductsResult) => {
        return result.data;
      }
    }),
    getProduct: builder.query<ProductType, string>({
      query(Product_id) {
        return {
          url: `products/${Product_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetProductResult) => {
        return result.data;
      },
    }),
    updateProduct: builder.mutation<
      ProductType,
      Partial<ProductType> & Pick<ProductType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `products/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetProductResult) => {
        return result.data;
      },
    }),
    createProduct: builder.mutation<CreateProductResult, Partial<ProductType>>({
      query(data) {
        return {
          url: `products`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateProductResult => {
        return result.data;
      },
    }),
    deleteProduct: builder.mutation<boolean, string>({
      query(Product_id) {
        return {
          url: `products/${Product_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetProductsByProductGroupQuery,
  useGetProductsByProductTypeQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useCreateProductMutation,
} = ProductsApi;
