import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeletePartnerTestMutation } from "../../../../../redux/features/partnerTests/partnerTestsApi";
import { PartnerTestType } from "../../../../../redux/features/partnerTests/types";

type DeletePartnerTestProps = {
  partnerTest: PartnerTestType | undefined;
  show: boolean;
  doneDeleting: (reload: boolean) => void;
};

const DeletePartnerTestModal = (props: DeletePartnerTestProps) => {
  const handleClose = () => {
    props.doneDeleting(false);
  };
  const [deletePartnerTest, { isLoading }] = useDeletePartnerTestMutation();

  const submitHandler = (e: any) => {
    e.preventDefault();
    deletePartnerTest(props.partnerTest!)
      .unwrap()
      .then((result) => {
        if (result) {
          props.doneDeleting(true);
        }
        console.log(result);
      });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        {`Delete the test ${props.partnerTest?.test_type}?`}
      </DialogTitle>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the test for the partner?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} //disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeletePartnerTestModal;
