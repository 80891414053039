import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Link,
  Box,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { APP } from "../../../../_shared/utils/_urls";
import DeleteGiftCertificateModal from "./components/DeleteGiftCertificateModal";
import FormInfo from "../../../../_shared/components/FormInfo";
import { formatAudit, getShortDate } from "../../../../_shared/utils/uiHelpers";
import UpdateGiftCertificateModal from "./components/UpdateGiftCertificateModal";
import ProductsList from "./components/ProductsList";
import { GiftCertificateType } from "../../../../redux/features/commerce/gift-certificates/types";
import { useGetGiftCertificateQuery } from "../../../../redux/features/commerce/gift-certificates/giftCertificatesApi";

const GiftCertificateDetailPage = () => {
  const params = useParams();
  const [GiftCertificate, setGiftCertificate] = useState<GiftCertificateType>();
  const [showDeleteGiftCertificateModal, setShowDeleteGiftCertificateModal] =
    useState(false);
  const [showEditGiftCertificateModal, setShowEditGiftCertificateModal] =
    useState(false);
  const navigate = useNavigate();

  const { data, isSuccess, refetch } = useGetGiftCertificateQuery(
    params.gift_certificate_id ?? skipToken
  );

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditGiftCertificateModal(true);
  };
  const onDoneEditing = () => {
    refetch();
    setShowEditGiftCertificateModal(false);
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeleteGiftCertificateModal(true);
  };
  const onDoneDeleting = () => {
    setShowDeleteGiftCertificateModal(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setGiftCertificate(data);
    }
  }, [data]);

  if (isSuccess)
    return (
      <React.Fragment>
        <Grid container columns={2}>
          <Grid item sm={1}>
            <Breadcrumbs sx={{ mt: 1 }}>
              <Typography color="inherit">Commerce</Typography>
              <Typography color="inherit">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    navigate(`${APP.GIFT_CERTIFICATES}`);
                  }}
                >
                  Gift Certificates
                </Link>
              </Typography>

              <Typography color="text.primary">
                {GiftCertificate?.name}
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Stack direction="row">
              <Button variant="text" color="secondary" onClick={onEdit}>
                Edit
              </Button>
              <Button variant="text" color="secondary" onClick={onDelete}>
                Delete
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Container maxWidth={false}>
          <Grid container columns={2} spacing={2}>
            <Grid item sm={1}>
              <FormInfo label="Name" value={GiftCertificate?.name} />
              <FormInfo
                label="Description"
                value={GiftCertificate?.description}
              />
              <FormInfo label="Price" value={GiftCertificate?.price} />
              <FormInfo label="Terms" value={GiftCertificate?.terms_name} />
              <FormInfo
                label="Is Active"
                value={GiftCertificate?.is_active ? "Yes" : "No"}
              />
              <FormInfo
                label="Created"
                value={formatAudit(
                  GiftCertificate?.created_by_name,
                  GiftCertificate?.created_at,
                  ""
                )}
              />
              <FormInfo
                label="Updated"
                value={formatAudit(
                  GiftCertificate?.updated_by_name,
                  GiftCertificate?.updated_at,
                  ""
                )}
              />
            </Grid>
            <Grid item sm={1}>
              <ProductsList products={GiftCertificate?.products} />
            </Grid>
          </Grid>
        </Container>
        <DeleteGiftCertificateModal
          giftCertificateToDelete={GiftCertificate as GiftCertificateType}
          doneDeleting={onDoneDeleting}
          show={showDeleteGiftCertificateModal}
        />
        <UpdateGiftCertificateModal
          giftCertificateToUpdate={GiftCertificate as GiftCertificateType}
          doneEditing={onDoneEditing}
          show={showEditGiftCertificateModal}
        />
      </React.Fragment>
    );
  else return <p>Loading...</p>;
};

export default GiftCertificateDetailPage;
