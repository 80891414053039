import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { customersApi } from "./features/customers/customersApi";
import { testsApi } from "./features/tests/testsApi";
import { authApi } from "./features/auth/authApi";
import { partnersApi } from "./features/partners/partnersApi";
import { partnerTestsApi } from "./features/partnerTests/partnerTestsApi";
import { profileApi } from "./features/profile/profileApi";
import { ProductGroupsApi } from "./features/commerce/products/productGroupsApi";
import { ProductsApi } from "./features/commerce/products/productsApi";
import { SettingsApi } from "./features/settings/settingsApi";
import { CheckoutPromosApi } from "./features/commerce/promos/promosApi";
import { GiftCertificatesApi } from "./features/commerce/gift-certificates/giftCertificatesApi";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import currentUserReducer from "./features/auth/currentUser.slice";
import { CheckoutOrdersApi } from "./features/commerce/orders/ordersApi";
import { TermsApi } from "./features/commerce/terms/termsApi";
import { ProductTestsApi } from "./features/commerce/products/productTestsApi";
import { schedulingApi } from "./features/schedule/scheduleApi";
import { formTemplatesApi } from "./features/forms/formTemplatesApi";
import { formsApi } from "./features/forms/formsApi";

const persistConfig = {
  key: "root",
  storage: storage,
  version: 1,
  blacklist: [
    customersApi.reducerPath,
    authApi.reducerPath,
    testsApi.reducerPath,
    partnersApi.reducerPath,
    profileApi.reducerPath,
    partnerTestsApi.reducerPath,
    ProductGroupsApi.reducerPath,
    ProductsApi.reducerPath,
    SettingsApi.reducerPath,
    CheckoutOrdersApi.reducerPath,
    CheckoutPromosApi.reducerPath,
    GiftCertificatesApi.reducerPath,
    TermsApi.reducerPath,
    ProductTestsApi.reducerPath,
    schedulingApi.reducerPath,
    formTemplatesApi.reducerPath,
    formsApi.reducerPath,
  ],
};

const reducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [testsApi.reducerPath]: testsApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [partnerTestsApi.reducerPath]: partnerTestsApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [ProductGroupsApi.reducerPath]: ProductGroupsApi.reducer,
  [ProductsApi.reducerPath]: ProductsApi.reducer,
  [SettingsApi.reducerPath]: SettingsApi.reducer,
  [CheckoutOrdersApi.reducerPath]: CheckoutOrdersApi.reducer,
  [CheckoutPromosApi.reducerPath]: CheckoutPromosApi.reducer,
  [GiftCertificatesApi.reducerPath]: GiftCertificatesApi.reducer,
  [TermsApi.reducerPath]: TermsApi.reducer,
  [ProductTestsApi.reducerPath]: ProductTestsApi.reducer,
  [schedulingApi.reducerPath]: schedulingApi.reducer,
  [formTemplatesApi.reducerPath]: formTemplatesApi.reducer,
  [formsApi.reducerPath]: formsApi.reducer,
  currentUserState: currentUserReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      customersApi.middleware,
      testsApi.middleware,
      partnersApi.middleware,
      partnerTestsApi.middleware,
      ProductGroupsApi.middleware,
      ProductsApi.middleware,
      SettingsApi.middleware,
      CheckoutOrdersApi.middleware,
      CheckoutPromosApi.middleware,
      GiftCertificatesApi.middleware,
      TermsApi.middleware,
      ProductTestsApi.middleware,
      schedulingApi.middleware,
      formTemplatesApi.middleware,
      formsApi.middleware,
    ]);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
