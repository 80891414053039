import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserTestsQuery } from "../../../../../redux/features/tests/testsApi";
import { TestType } from "../../../../../redux/features/tests/types";
import Link from "@mui/material/Link";
import { APP } from "../../../../../_shared/utils/_urls";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import React from "react";

type CustomerAppointmentsProps = {
  customer_id: string;
};

const AppointmentsForCustomer = (props: CustomerAppointmentsProps) => {
  const navigate = useNavigate();
  const [tests, setTests] = useState<TestType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "appointment_date",
      headerName: "Appointment",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          onClick={() => {
            navigate(`${APP.APPOINTMENTS}/${params.row.id}`);
          }}
        >
          {params.row.appointment_date
            ? formatDateTime(params.row.appointment_date)
            : "Not Scheduled"}
        </Link>
      ),
    },
    {
      field: "test_type",
      headerName: "Test",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === null ? "Customer" : params.value;
      },
    },
    {
      field: "partner_name",
      headerName: "Partner",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
    {
      field: "sent",
      headerName: "Sent",
      valueGetter: (params) => {
        return `${
          params.row.sent !== undefined ? formatDateTime(params.row.sent) : "--"
        }`;
      },
    },
    {
      field: "flagged",
      headerName: "Flagged",
      renderCell: (params: GridRenderCellParams) => (
        <FlagOutlinedIcon color="disabled" />
      ),
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
  } = useGetUserTestsQuery(props.customer_id);

  useEffect(() => {
    if (isSuccess) {
      setTests(data);
    }
  });

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={tests}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default AppointmentsForCustomer;
