import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser } from './types';

interface UserState {
    currentUser: CurrentUser | null;
    token: string | undefined;
}

const initialState: UserState = {
    currentUser: null,
    token: undefined
}

export const currentUserSlice = createSlice({
    initialState,
    name: 'currrentUserSlice',
    reducers: {
        logout: (state) => {
            state.currentUser = null;
            state.token = undefined; 
        },
        setCurrentUser: (state, action: PayloadAction<{currentUser: CurrentUser, token:string | undefined}>) => {
            state.currentUser = action.payload.currentUser;
            state.token = action.payload.token;
        }
    }
});

export default currentUserSlice.reducer;
export const { logout, setCurrentUser } = currentUserSlice.actions;