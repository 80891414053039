import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  GetCheckoutPromoCodeResultType,
  GetCheckoutPromoCodesResultType,
  CheckoutPromoCodeType,
  UpdateCheckoutPromoCodeDataResultType,
  UpdateCheckoutPromoCodeResultType,
  CreateCheckoutPromoCodeResultType,
  CreateCheckoutPromoCodeDataResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const CheckoutPromosApi = createApi({
  reducerPath: "CheckoutPromoCodesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPromoCodes: builder.query<CheckoutPromoCodeType[], {}>({
      query() {
        return {
          url: "promo-codes",
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result: GetCheckoutPromoCodesResultType) => {
        return result.data;
      },
    }),
    getPromoCode: builder.query<CheckoutPromoCodeType, string>({
      query(order_id) {
        return {
          url: `promo-codes/${order_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetCheckoutPromoCodeResultType) => {
        return result.data;
      },
    }),
    updatePromoCode: builder.mutation<
      UpdateCheckoutPromoCodeDataResultType,
      Partial<CheckoutPromoCodeType> & Pick<CheckoutPromoCodeType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `promo-codes/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: UpdateCheckoutPromoCodeResultType) => {
        return result.data;
      },
    }),
    createPromoCode: builder.mutation<
      CreateCheckoutPromoCodeDataResultType,
      Partial<CheckoutPromoCodeType>
    >({
      query(data) {
        return {
          url: `promo-codes`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: CreateCheckoutPromoCodeResultType) => {
        return result.data;
      },
    }),
    deletePromoCode: builder.mutation<boolean, string>({
      query(promo_id) {
        return {
          url: `promo-codes/${promo_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetPromoCodesQuery,
  useGetPromoCodeQuery,
  useCreatePromoCodeMutation,
  useDeletePromoCodeMutation,
  useUpdatePromoCodeMutation,
} = CheckoutPromosApi;
