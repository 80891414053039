import { Stack } from "@mui/material";
import {
  add,
  format,
  lightFormat,
  parse,
  parseISO,
  parseJSON,
  toDate,
} from "date-fns";
import { differenceInYears } from "date-fns";
import { round } from "lodash";

export const friendlyName = (
  first_name: string | undefined,
  last_name: string | undefined
): string => {
  return `${first_name} ${last_name}`;
};

export const unitConversion = (
  value: number | null | undefined,
  convertFromUnit: string,
  convertToUnit: string | undefined
): number => {
  const gramToPound = 0.0022046226;
  const cm3ToIn3 = 0.06102374409;
  console.log(value, convertFromUnit, convertToUnit);
  let returnValue = 0;
  if (value !== null && value !== undefined && convertToUnit !== undefined) {
    if (convertFromUnit === "gms" && convertToUnit === "lbs") {
      returnValue = roundOff(value * gramToPound, 1);
    } else if (convertFromUnit === "lbs" && convertToUnit === "gms") {
      returnValue = roundOff(value / gramToPound, 1);
    } else if (convertFromUnit === "cm³" && convertToUnit === "in³") {
      returnValue = roundOff(value * cm3ToIn3, 1);
    } else if (convertFromUnit === "in³" && convertToUnit === "cm³") {
      returnValue = roundOff(value / cm3ToIn3, 1);
    } else {
      return NaN;
    }
  }

  return returnValue;
};

//We could use date-fns or moment here but trying to limit the external references.
export const formatDateTime = (date: Date | undefined): string => {
  if (date !== undefined && date !== null) {
    const strDate = date.toString();
    const isoDate = parseISO(strDate.slice(0, -1));
    return format(new Date(isoDate), "MM/dd/yyyy hh:mm aa");
  }
  return "";
};

export const formatShortDate = (date: Date | undefined): string => {
  console.log(date);
  if (date !== undefined && date !== null) {
    const dateString = date.toString();
    const dateStringWithoutTime = dateString.substring(
      0,
      dateString.indexOf("T")
    );
    const formatted_date = format(
      new Date(parseISO(dateStringWithoutTime)),
      "MM/dd/yyyy"
    );
    return formatted_date;
  }
  return "";
};

export const dateWithoutTimezone = (date: Date): Date => {
  const tzoffest = date.getTimezoneOffset() * 60000;
  const withoutTimezone = new Date(date.valueOf() - tzoffest)
    .toISOString()
    .slice(0, -1);
  return new Date(withoutTimezone);
};

export const formatAudit = (
  name: string | undefined,
  date: Date | undefined,
  prefix: string
) => {
  if (name !== undefined && date !== undefined) {
    return `${prefix} ${name === null ? "" : "by " + name} at ${formatDateTime(
      date
    )}`;
  } else {
    return "";
  }
};

export const getAgeFromDateOfBirth = (dob: Date | undefined): number => {
  let age = 0;
  if (dob !== undefined) {
    age = differenceInYears(new Date(), new Date(dob));
  }
  return age;
};

export const PASSWORD_PATTERN =
  /^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/;

export function getLongDate(date: Date | undefined) {
  if (date !== undefined) {
    const dateToFormat = new Date(date);
    return format(dateToFormat, "MMMM do, yyyy");
  }
  return "";
}

export const roundOff = (num: number, places: number) => {
  const x = Math.pow(10, places);
  return Math.round(num * x) / x;
};

export const getOrdinalNumber = (num: number): string => {
  let ordinal: string;

  switch (num) {
    case 1 || 21 || 31 || 41 || 51 || 61 || 71 || 81 || 91:
      ordinal = "st";
      break;
    case 2 || 22 || 32 || 42 || 52 || 62 || 72 || 82 || 92:
      ordinal = "nd";
      break;
    case 3 || 23 || 33 || 43 || 53 || 63 || 73 || 83 || 93:
      ordinal = "rd";
      break;
    default:
      ordinal = "th";
      break;
  }

  return `${num}${ordinal}`;
};

//We could use date-fns or moment here but trying to limit the external references.
export function getShortDate(date: Date | undefined) {
  if (date !== undefined && date !== null) {
    // const dateToFormat = new Date(date);
    // const year = dateToFormat.getFullYear();
    // const day = dateToFormat.getDay();
    // const month = dateToFormat.getMonth();
    // const formattedDated = `${month}/${day}/${year}`;
    // return formattedDated.toString();
    const dateToFormat = new Date(date);
    return format(dateToFormat, "MM/dd/yyyy");
  }
  return "";
}

export const checkNumberIsEmpty = (value: any): boolean => {
  if (value === "") return true;
  else if (value === "0") return true;
  else if (value === null) return true;
  else if (value.length === 0) return true;
  return false;
};

export const displayData = (label: any, value: any) => {};

export const validHeight = (height: number) => {
  const HEIGHT_MIN = 48; // in.
  const HEIGHT_MAX = 84; // in.

  return height >= HEIGHT_MIN && height <= HEIGHT_MAX; // true if valid
};

export const validWeight = (weight: number) => {
  const WEIGHT_MIN = 50; // lbs.
  const WEIGHT_MAX = 300; // lbs.

  return weight >= WEIGHT_MIN && weight <= WEIGHT_MAX; // true if valid
};

export const formatTimeString = (date: string | undefined): string => {
  if (date === undefined) return "";
  const newDate = new Date(date);
  return newDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};
