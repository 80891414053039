import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetPromoCodesQuery } from "../../../../../redux/features/commerce/promos/promosApi";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../../_shared/utils/_urls";
import { CheckoutPromoCodeType } from "../../../../../redux/features/commerce/promos/types";
import React from "react";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";

const PromosGrid = () => {
  const navigate = useNavigate();
  const [promos, setPromos] = useState<CheckoutPromoCodeType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: "Promo Code",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Link
            onClick={() => {
              navigate(`${APP.PROMO_CODES}/${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        </React.Fragment>
      ),
    },
    { field: "discount_type", headerName: "Type" },
    {
      field: "discount_value",
      headerName: "Discount Amount",
      renderCell: (params: GridRenderCellParams) =>
        params.row.discount_type === "Value"
          ? `\$${params.value}`
          : `${params.value}%`,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.created_at)} by ${
          params.row.created_by_name
        }`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetPromoCodesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setPromos(data);
    }
  });

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <Box style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          density="compact"
          disableRowSelectionOnClick={true}
          rows={promos}
          columns={columns}
          loading={isFetching}
        />
      </Box>
    </Box>
  );
};

export default PromosGrid;
