import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetCustomersQuery } from "../../../../redux/features/customers/customersApi";
import { CustomerType } from "../../../../redux/features/customers/types";
import {
  formatDateTime,
  friendlyName,
} from "../../../../_shared/utils/uiHelpers";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../_shared/utils/_urls";
import _ from "lodash";

type FilterProps = {
  searchText: string | undefined;
};

const CustomersGrid = (props: FilterProps) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          onClick={() => {
            navigate(`${APP.CUSTOMERS}/${params.row.id}`);
          }}
        >
          {friendlyName(params.row.first_name, params.row.last_name)}
        </Link>
      ),
      valueGetter: (params: GridRenderCellParams) => {
        return `${params.row.first_name} ${params.row.last_name}`;
      },
    },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone_number", headerName: "Phone", flex: 1 },
    {
      field: "is_verified",
      headerName: "Verified",
      width: 50,
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    // {
    //   field: "customer_type",
    //   headerName: "Type",
    //   flex: 1,
    //   valueFormatter: (params) => {
    //     return params.value === null ? "Customer" : params.value;
    //   },
    // },
    // {
    //   field: "created",
    //   headerName: "Created",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${formatDateTime(params.row.created_at)} by ${
    //       params.row.created_by_name
    //     }`;
    //   },
    // },
    // {
    //   field: "updated",
    //   headerName: "Updated",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     return `${formatDateTime(params.row.updated_at)} by ${
    //       params.row.updated_by_name
    //     }`;
    //   },
    // },
  ];
  const { data = [], isFetching, isSuccess } = useGetCustomersQuery({});

  const filteredCustomers = _.filter(customers, (u) => {
    const uValues = _.values(u);
    return (
      _.findIndex(uValues, (v: any) => {
        return _.toUpper(v).includes(_.toUpper(props.searchText));
      }) !== -1
    );
  });

  useEffect(
    _.debounce(() => {}, 250),
    [props.searchText]
  );

  useEffect(() => {
    if (isSuccess) {
      setCustomers(data);
    }
  });

  return (
    <Box style={{ width: "100%", height: "740px" }}>
      <DataGridPro
        pagination
        disableRowSelectionOnClick={true}
        rows={filteredCustomers}
        columns={columns}
        loading={isFetching}
        sx={{ height: "100%" }}
      />
    </Box>
  );
};

export default CustomersGrid;
