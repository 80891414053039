import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";

type FilterProps = {
  searchText: string | undefined;
  onUpdateSearchText: (updatedSearchText: string) => void;
};

const CustomersFilter = (props: FilterProps) => {
  const clearSearch = () => {
    props.onUpdateSearchText("");
  };

  const updateSearchText = (updatedText: string) => {
    props.onUpdateSearchText(updatedText);
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        fullWidth
        label="Search"
        value={props.searchText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          updateSearchText(event.target.value);
        }}
        InputProps={{
          endAdornment: props.searchText !== undefined &&
            props.searchText.length > 0 && (
              <InputAdornment position="end">
                <Button
                  variant="text"
                  sx={{ minWidth: "15px", padding: 0, textTransform: "none" }}
                  onClick={clearSearch}
                >
                  X
                </Button>
              </InputAdornment>
            ),
        }}
      />
    </Stack>
  );
};

export default CustomersFilter;
