import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  HydrostaticType,
  TestType,
  UpdateTestResultType,
} from "../../../../../redux/features/tests/types";
import * as Yup from "yup";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import { getAgeFromDateOfBirth } from "../../../../../_shared/utils/uiHelpers";

type HydrostaticTestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

const HyrdostaticTest = (props: HydrostaticTestType) => {
  const [results, setResults] = useState<HydrostaticType>();

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      height: results?.height || 0,
      total_mass: results?.total_mass || 0,
      fat_free_mass: results?.fat_free_mass || 0,
      fat_mass: results?.fat_mass || 0,
      z_score: results?.z_score || 0,
      body_density: results?.body_density || 0,
      lung_volume: results?.lung_volume || 0,
      water_temperature: results?.water_temperature || 0,
    },
    validationSchema: Yup.object({
      total_mass: Yup.number().positive("Total mass must be greater than 0"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Age"
            name="age"
            required
            error={Boolean(formik.touched.age && formik.errors.age)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.age}
            disabled={!props.isEditing}
            helperText="Age derived from DOB"
          />
          <FormControl fullWidth required>
            <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
            <Select
              labelId="genderAtBirthLabel"
              name="gender"
              label="Gender at birth"
              value={formik.values.gender}
              error={Boolean(formik.touched?.gender && formik.errors?.gender)}
              onChange={formik.handleChange}
              disabled={!props.isEditing}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
          <TextField
            error={Boolean(formik.touched.height && formik.errors.height)}
            fullWidth
            helperText={formik.touched.height && formik.errors.height}
            label="Height in Inches"
            name="height"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.height}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inches</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.total_mass && formik.errors.total_mass
            )}
            fullWidth
            helperText={formik.touched.total_mass && formik.errors.total_mass}
            label="Total Mass"
            name="total_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.total_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.fat_free_mass && formik.errors.fat_free_mass
            )}
            fullWidth
            helperText={
              formik.touched.fat_free_mass && formik.errors.fat_free_mass
            }
            label="Fat Free Mass"
            name="fat_free_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fat_free_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.fat_mass && formik.errors.fat_mass)}
            fullWidth
            helperText={formik.touched.fat_mass && formik.errors.fat_mass}
            label="Fat Mass"
            name="fat_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fat_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.body_density && formik.errors.body_density
            )}
            fullWidth
            helperText={formik.touched.z_score && formik.errors.body_density}
            label="Body Density"
            name="body_density"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.body_density}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">kg/L</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.lung_volume && formik.errors.lung_volume
            )}
            fullWidth
            helperText={formik.touched.lung_volume && formik.errors.lung_volume}
            label="Lung Volume"
            name="lung_volume"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.lung_volume}
            InputProps={{
              endAdornment: <InputAdornment position="end">L</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.water_temperature &&
                formik.errors.water_temperature
            )}
            fullWidth
            helperText={
              formik.touched.water_temperature &&
              formik.errors.water_temperature
            }
            label="Water Temperature"
            name="water_temperature"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.water_temperature}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Celsius</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.z_score && formik.errors.z_score)}
            fullWidth
            helperText={formik.touched.z_score && formik.errors.z_score}
            label="Z-Score"
            name="z_score"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.z_score}
            disabled={!props.isEditing}
          />
        </Stack>
        {props.isEditing && (
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 3, justifyContent: "flex-end" }}
            >
              <Button
                variant="text"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                disabled={isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Stack>
            <p>&nbsp;</p>
          </Box>
        )}
      </form>
    </Container>
  );
};

export default HyrdostaticTest;
