import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { ProfileType } from "../../redux/features/profile/types";
import PhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import Email from "@mui/icons-material/Email";
import { useGetProfileQuery } from "../../redux/features/profile/profileApi";
import EditProfileModal from "./components/ProfileUpdate";
import UpdatePasswordModal from "./components/PasswordUpdate";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.grey[50],
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const formatPhone = (phoneNumber: string | undefined) => {
  return phoneNumber;
};

const ProfilePage = () => {
  const [profile, setProfile] = useState<ProfileType>();
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetProfileQuery({});

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditProfileModal(true);
  };

  const onDoneEditing = () => {
    refetch();
    setShowEditProfileModal(false);
  };
  const onChangePassword = (e: any) => {
    e.preventDefault();
    setShowUpdatePasswordModal(true);
  };

  const onDoneUpdatingPassword = () => {
    setShowUpdatePasswordModal(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setProfile(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs sx={{ mt: 1 }}>
            <Typography color="text.primary">Profile</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="text" color="secondary" onClick={onChangePassword}>
            Change Password
          </Button>
          <Button variant="text" color="secondary" onClick={onEdit}>
            Edit Profile
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={5}
        sx={{ mt: 1 }}
        columns={{ xs: 1, sm: 9, md: 12, lg: 12 }}
      >
        <Grid item lg={2} md={3} sm={9} xs={1}>
          <h3>
            {profile?.first_name} {profile?.last_name}
          </h3>
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={1}>
          <Item>
            <PhoneIcon sx={{ mx: "auto" }} />
            <p>{formatPhone(profile?.phone_number)}</p>
            <Email sx={{ mx: "auto" }} />
            <p>{profile?.email}</p>
          </Item>
        </Grid>
      </Grid>
      <EditProfileModal
        profile={profile}
        doneEditing={onDoneEditing}
        show={showEditProfileModal}
      />
      <UpdatePasswordModal
        doneUpdating={onDoneUpdatingPassword}
        show={showUpdatePasswordModal}
      />
    </React.Fragment>
  );
};

export default ProfilePage;
