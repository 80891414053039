import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store";
import {
  GetFormTemplateResultType,
  GetFormTemplatesResultType,
  FormTemplateType,
  CreateFormTemplateResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const formTemplatesApi = createApi({
  reducerPath: "formTemplateApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFormTemplates: builder.query<FormTemplateType[], {}>({
      query() {
        return {
          url: "form-templates",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetFormTemplatesResultType) => {
        return result.data;
      },
    }),
    getFormTemplate: builder.query<FormTemplateType, string>({
      query(form_template_id) {
        return {
          url: `form-templates/${form_template_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetFormTemplateResultType) => {
        return result.data;
      },
    }),
    updateFormTemplate: builder.mutation<
      FormTemplateType,
      Partial<FormTemplateType> & Pick<FormTemplateType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `form-templates/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetFormTemplateResultType) => {
        return result.data;
      },
    }),
    createFormTemplate: builder.mutation<
      CreateFormTemplateResultType,
      Partial<FormTemplateType>
    >({
      query(data) {
        return {
          url: `form-templates`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateFormTemplateResultType => {
        return result.data;
      },
    }),
    deleteFormTemplate: builder.mutation<boolean, string>({
      query(form_template_id) {
        return {
          url: `form-templates/${form_template_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetFormTemplateQuery,
  useGetFormTemplatesQuery,
  useUpdateFormTemplateMutation,
  useDeleteFormTemplateMutation,
  useCreateFormTemplateMutation,
} = formTemplatesApi;
