import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  DexaType,
  TestType,
  UpdateTestResultType,
} from "../../../../../../redux/features/tests/types";
import * as Yup from "yup";
import styles from "../dexa.module.css";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import {
  unitConversion,
  checkNumberIsEmpty,
  getAgeFromDateOfBirth,
  roundOff,
  validHeight,
  validWeight,
} from "../../../../../../_shared/utils/uiHelpers";
import NumberField from "../../shared/NumberField";
import ErrorPopup from "../../error-popup";

type DexaTestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

export const inputCheck = (formik: FormikProps<any>) => {
  const MAX_ARM_PERCENT = 0.07; // max arm percentage is 7% of body weight
  const MAX_LEG_PERCENT = 0.17; // max leg percentage is 17% of body weight
  const MAX_DIFFERENCE = 1.5; // max difference for arms and legs is 1.5
  const BMD_MARGIN = 4; // bmd should be between -4 and 4
  const ERROR_MARGIN = 1;

  let validForm: boolean = true;
  const errors: FormikErrors<DexaType> = {};
  const error_message = " ";

  if (!validHeight(formik.values.height)) {
    errors.height = error_message;
  }
  if (!validWeight(formik.values.total_body_weight)) {
    errors.total_body_weight = error_message;
  }

  if (formik.values.total_body_weight != null) {
    let hasRightArmError = false;
    let hasLeftArmError = false;
    let hasRightLegError = false;
    let hasLeftLegError = false;
    let hasWeightError = false;

    // bmi should equal weight/height^2 * 703 (+/- 1)
    if (formik.values.height != null) {
      let bmi_check =
        (formik.values.total_body_weight /
          (formik.values.height * formik.values.height)) *
        703;
      if (
        formik.values.bmi != null &&
        Math.abs(formik.values.bmi - bmi_check) > ERROR_MARGIN
      ) {
        errors.bmi = error_message;
        errors.height = error_message;
        hasWeightError = true;
      }
    }

    // each arm should make up about MAX_ARM_PERCENT of body weight
    if (
      formik.values.left_arm_fat_mass != null &&
      formik.values.left_arm_lean_mass != null
    ) {
      let left_arm_fat = formik.values.left_arm_fat_mass * 1.0;
      let left_arm_lean = formik.values.left_arm_lean_mass * 1.0;

      if (formik.values.left_arm_fat_mass_unit === "gms")
        left_arm_fat =
          unitConversion(formik.values.left_arm_fat_mass, "gms", "lbs") * 1.0;
      if (formik.values.left_arm_lean_mass_unit === "gms")
        left_arm_lean =
          unitConversion(formik.values.left_arm_lean_mass, "gms", "lbs") * 1.0;

      let left_arm_check = roundOff(
        (left_arm_fat + left_arm_lean) / formik.values.total_body_weight,
        2
      );

      if (left_arm_check > MAX_ARM_PERCENT + ERROR_MARGIN / 100) {
        hasLeftArmError = true;
        hasWeightError = true;
      }
      // lean should be greater than fat
      if (left_arm_fat > left_arm_lean) {
        hasLeftArmError = true;
      }
    }
    if (
      formik.values.right_arm_fat_mass != null &&
      formik.values.right_arm_lean_mass != null
    ) {
      let right_arm_fat = formik.values.right_arm_fat_mass * 1.0;
      let right_arm_lean = formik.values.right_arm_lean_mass * 1.0;

      if (formik.values.right_arm_fat_mass_unit === "gms")
        right_arm_fat =
          unitConversion(formik.values.right_arm_fat_mass, "gms", "lbs") * 1.0;
      if (formik.values.right_arm_lean_mass_unit === "gms")
        right_arm_lean =
          unitConversion(formik.values.right_arm_lean_mass, "gms", "lbs") * 1.0;

      let right_arm_check = roundOff(
        (right_arm_fat + right_arm_lean) / formik.values.total_body_weight,
        2
      );

      if (right_arm_check > MAX_ARM_PERCENT + ERROR_MARGIN / 100) {
        hasRightArmError = true;
        hasWeightError = true;
      }
      // lean should be greater than fat
      if (right_arm_fat > right_arm_lean) {
        hasRightArmError = true;
      }
    }

    // each leg should make up about MAX_LEG_PERCENT of body weight
    if (
      formik.values.left_leg_fat_mass != null &&
      formik.values.left_leg_lean_mass != null
    ) {
      let left_leg_fat = formik.values.left_leg_fat_mass * 1.0;
      let left_leg_lean = formik.values.left_leg_lean_mass * 1.0;

      if (formik.values.left_leg_fat_mass_unit === "gms")
        left_leg_fat =
          unitConversion(formik.values.left_leg_fat_mass, "gms", "lbs") * 1.0;
      if (formik.values.left_leg_lean_mass_unit === "gms")
        left_leg_lean =
          unitConversion(formik.values.left_leg_lean_mass, "gms", "lbs") * 1.0;

      let leftLegCheck = roundOff(
        (left_leg_fat + left_leg_lean) / formik.values.total_body_weight,
        2
      );

      if (leftLegCheck > MAX_LEG_PERCENT + ERROR_MARGIN / 100) {
        hasLeftLegError = true;
        hasWeightError = true;
      }
      // lean should be greater than fat
      if (left_leg_fat > left_leg_lean) {
        hasLeftLegError = true;
      }
    }
    if (
      formik.values.right_leg_fat_mass != null &&
      formik.values.right_leg_lean_mass != null
    ) {
      let right_leg_fat = formik.values.right_leg_fat_mass * 1.0;
      let right_leg_lean = formik.values.right_leg_lean_mass * 1.0;

      if (formik.values.right_leg_fat_mass_unit === "gms")
        right_leg_fat =
          unitConversion(formik.values.right_leg_fat_mass, "gms", "lbs") * 1.0;
      if (formik.values.right_leg_lean_mass_unit === "gms")
        right_leg_lean =
          unitConversion(formik.values.right_leg_lean_mass, "gms", "lbs") * 1.0;

      let rightLegCheck = roundOff(
        (right_leg_fat + right_leg_lean) / formik.values.total_body_weight,
        2
      );

      if (rightLegCheck > MAX_LEG_PERCENT + ERROR_MARGIN / 100) {
        hasRightLegError = true;
        hasWeightError = true;
      }
      // lean should be greater than fat
      if (right_leg_fat > right_leg_lean) {
        hasRightLegError = true;
      }
    }

    if (hasLeftArmError) {
      errors.left_arm_fat_mass = error_message;
      errors.left_arm_lean_mass = error_message;
      errors.left_arm_bmc = error_message;
      errors.left_arm_lean_and_bmc = error_message;
    }
    if (hasRightArmError) {
      errors.right_arm_fat_mass = error_message;
      errors.right_arm_lean_mass = error_message;
      errors.right_arm_bmc = error_message;
      errors.right_arm_lean_and_bmc = error_message;
    }
    if (hasLeftLegError) {
      errors.left_leg_fat_mass = error_message;
      errors.left_leg_lean_mass = error_message;
      errors.left_leg_bmc = error_message;
      errors.left_leg_lean_and_bmc = error_message;
    }
    if (hasRightLegError) {
      errors.right_leg_fat_mass = error_message;
      errors.right_leg_lean_mass = error_message;
      errors.right_leg_bmc = error_message;
      errors.right_leg_lean_and_bmc = error_message;
    }
    if (hasWeightError) {
      errors.total_body_weight = error_message;
    }
  }

  // difference between lean mass in arms and legs should not be greater than MAX_DIFFERENCE
  if (
    formik.values.right_arm_lean_mass != null &&
    formik.values.left_arm_lean_mass != null
  ) {
    let right_arm_lean = formik.values.right_arm_lean_mass * 1.0;
    let left_arm_lean = formik.values.left_arm_lean_mass * 1.0;

    if (formik.values.right_arm_lean_mass_unit === "gms")
      right_arm_lean =
        unitConversion(formik.values.right_arm_lean_mass, "gms", "lbs") * 1.0;
    if (formik.values.left_arm_lean_mass_unit === "gms")
      left_arm_lean =
        unitConversion(formik.values.left_arm_lean_mass, "gms", "lbs") * 1.0;

    if (
      Math.abs(right_arm_lean - left_arm_lean) >
      MAX_DIFFERENCE + ERROR_MARGIN
    ) {
      errors.right_arm_lean_mass = error_message;
      errors.left_arm_lean_mass = error_message;
      errors.left_arm_bmc = error_message;
      errors.left_arm_lean_and_bmc = error_message;
      errors.right_arm_bmc = error_message;
      errors.right_arm_lean_and_bmc = error_message;
    }
  }
  if (
    formik.values.right_leg_lean_mass != null &&
    formik.values.left_leg_lean_mass != null
  ) {
    let right_leg_lean = formik.values.right_leg_lean_mass * 1.0;
    let left_leg_lean = formik.values.left_leg_lean_mass * 1.0;

    if (formik.values.right_leg_lean_mass_unit === "gms")
      right_leg_lean =
        unitConversion(formik.values.right_leg_lean_mass, "gms", "lbs") * 1.0;
    if (formik.values.left_leg_lean_mass_unit === "gms")
      left_leg_lean =
        unitConversion(formik.values.left_leg_lean_mass, "gms", "lbs") * 1.0;

    if (
      Math.abs(right_leg_lean - left_leg_lean) >
      MAX_DIFFERENCE + ERROR_MARGIN
    ) {
      errors.right_leg_lean_mass = error_message;
      errors.left_leg_lean_mass = error_message;
      errors.left_leg_bmc = error_message;
      errors.left_leg_lean_and_bmc = error_message;
      errors.right_leg_bmc = error_message;
      errors.right_leg_lean_and_bmc = error_message;
    }
  }

  // difference between fat mass in arms and legs should not be greater than MAX_DIFFERENCE
  if (
    formik.values.right_arm_fat_mass != null &&
    formik.values.left_arm_fat_mass != null
  ) {
    let right_arm_fat = formik.values.right_arm_fat_mass * 1.0;
    let left_arm_fat = formik.values.left_arm_fat_mass * 1.0;

    if (formik.values.right_arm_fat_mass_unit === "gms")
      right_arm_fat =
        unitConversion(formik.values.right_arm_fat_mass, "gms", "lbs") * 1.0;
    if (formik.values.left_arm_fat_mass_unit === "gms")
      left_arm_fat =
        unitConversion(formik.values.left_arm_fat_mass, "gms", "lbs") * 1.0;

    if (
      Math.abs(right_arm_fat - left_arm_fat) >
      MAX_DIFFERENCE + ERROR_MARGIN
    ) {
      errors.right_arm_fat_mass = error_message;
      errors.left_arm_fat_mass = error_message;
    }
  }
  if (
    formik.values.right_leg_fat_mass != null &&
    formik.values.left_leg_fat_mass != null
  ) {
    let right_leg_fat = formik.values.right_leg_fat_mass * 1.0;
    let left_leg_fat = formik.values.left_leg_fat_mass * 1.0;

    if (formik.values.right_leg_fat_mass_unit === "gms")
      right_leg_fat =
        unitConversion(formik.values.right_leg_fat_mass, "gms", "lbs") * 1.0;
    if (formik.values.left_leg_fat_mass_unit === "gms")
      left_leg_fat =
        unitConversion(formik.values.left_leg_fat_mass, "gms", "lbs") * 1.0;

    if (
      Math.abs(right_leg_fat - left_leg_fat) >
      MAX_DIFFERENCE + ERROR_MARGIN
    ) {
      errors.right_leg_fat_mass = error_message;
      errors.left_leg_fat_mass = error_message;
    }
  }

  // android and gynoid mass should roughly be the sum of fat + lean
  if (formik.values.android_fat != null && formik.values.android_lean != null) {
    let android_fat = formik.values.android_fat * 1.0;
    let android_lean = formik.values.android_lean * 1.0;

    if (formik.values.android_fat_unit === "gms")
      android_fat =
        unitConversion(formik.values.android_fat, "gms", "lbs") * 1.0;
    if (formik.values.android_lean_unit === "gms")
      android_lean =
        unitConversion(formik.values.android_lean, "gms", "lbs") * 1.0;

    let hasError = false;

    if (formik.values.android_mass != null) {
      let android_mass = formik.values.android_mass * 1.0;
      if (formik.values.android_mass_unit === "gms")
        android_mass =
          unitConversion(formik.values.android_mass, "gms", "lbs") * 1.0;

      let android_mass_check = android_fat + android_lean;
      if (Math.abs(android_mass - android_mass_check) > ERROR_MARGIN) {
        errors.android_mass = error_message;
        hasError = true;
      }
    }
    // lean should be greater than fat
    if (android_fat > android_lean) {
      hasError = true;
    }
    if (hasError) {
      errors.android_fat = error_message;
      errors.android_lean = error_message;
    }
  }
  if (formik.values.gynoid_fat != null && formik.values.gynoid_lean != null) {
    let gynoid_fat = formik.values.gynoid_fat * 1.0;
    let gynoid_lean = formik.values.gynoid_lean * 1.0;

    if (formik.values.gynoid_fat_unit === "gms")
      gynoid_fat = unitConversion(formik.values.gynoid_fat, "gms", "lbs") * 1.0;
    if (formik.values.gynoid_lean_unit === "gms")
      gynoid_lean =
        unitConversion(formik.values.gynoid_lean, "gms", "lbs") * 1.0;

    let hasError = false;

    if (formik.values.gynoid_mass != null) {
      let gynoid_mass = formik.values.gynoid_mass * 1.0;
      if (formik.values.gynoid_mass_unit === "gms")
        gynoid_mass =
          unitConversion(formik.values.gynoid_mass, "gms", "lbs") * 1.0;

      let gynoid_mass_check = gynoid_fat + gynoid_lean;
      if (Math.abs(gynoid_mass - gynoid_mass_check) > ERROR_MARGIN) {
        errors.gynoid_mass = error_message;
        hasError = true;
      }
    }
    // lean should be greater than fat
    if (gynoid_fat > gynoid_lean) {
      hasError = true;
    }
    if (hasError) {
      errors.gynoid_fat = error_message;
      errors.gynoid_lean = error_message;
    }
  }

  if (
    formik.values.total_fat_mass != null &&
    formik.values.total_lean_mass != null &&
    formik.values.total_bone_mass != null
  ) {
    let total_fat = formik.values.total_fat_mass * 1.0;
    let total_lean = formik.values.total_lean_mass * 1.0;
    let total_bone = formik.values.total_bone_mass * 1.0;

    if (formik.values.total_fat_mass_unit === "gms")
      total_fat =
        unitConversion(formik.values.total_fat_mass, "gms", "lbs") * 1.0;
    if (formik.values.total_lean_mass_unit === "gms")
      total_lean =
        unitConversion(formik.values.total_lean_mass, "gms", "lbs") * 1.0;
    if (formik.values.total_bone_mass_unit === "gms")
      total_bone =
        unitConversion(formik.values.total_bone_mass, "gms", "lbs") * 1.0;

    let hasError = false;

    if (formik.values.total_mass != null) {
      let total_mass = formik.values.total_mass * 1.0;
      if (formik.values.total_mass_unit === "gms")
        total_mass =
          unitConversion(formik.values.total_mass, "gms", "lbs") * 1.0;

      let total_mass_check = total_fat + total_lean + total_bone;
      if (Math.abs(total_mass - total_mass_check) > ERROR_MARGIN) {
        errors.total_bone_mass = error_message;
        errors.total_mass = error_message;
        hasError = true;
      }
    }
    // lean should be greater than fat
    if (total_fat > total_lean) {
      hasError = true;
    }
    if (hasError) {
      errors.total_fat_mass = error_message;
      errors.total_lean_mass = error_message;
      errors.total_lean_and_bmc = error_message;
    }
  }

  // bmd should be between -4 and 4
  if (formik.values.bmd != null) {
    if (Math.abs(formik.values.bmd * 1.0) > BMD_MARGIN + ERROR_MARGIN) {
      errors.bmd = error_message;
    }
  }

  if (Object.keys(errors).length !== 0) {
    console.log(errors);
    formik.setErrors({ ...errors });
    validForm = false;
  }
  return validForm;
};

const DexaTest = (props: DexaTestType) => {
  const [results, setResults] = useState<DexaType>();
  const [bodyScanImage, setBodyScanImage] = useState<any>();
  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);
  const [errorPopup, displayErrorPopup] = useState<boolean>(false);

  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    values.body_scan_image = bodyScanImage;
    values.screen_type = "Standard";

    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
        setSubmitAttempt(0);
      });
  };

  const calculateTotalLean = () => {
    if (
      formik.values.total_lean_mass !== null &&
      formik.values.total_lean_and_bmc !== null &&
      formik.values.total_bone_mass !== null
    ) {
      let bmc_lbs = 0;
      if (formik.values.total_bone_mass_unit === "gms") {
        bmc_lbs = unitConversion(formik.values.total_bone_mass, "gms", "lbs");
      } else bmc_lbs = formik.values.total_bone_mass;

      formik.values.total_lean_and_bmc =
        formik.values.total_lean_mass * 1.0 + bmc_lbs * 1.0;
    }
  };

  const onErrorPopupClose = () => {
    displayErrorPopup(false);
  };

  const onErrorOverride = () => {
    displayErrorPopup(false);
    formik.setSubmitting(true);
    submitHandler(formik.values);
  };

  const handleUnitChange = (e: any) => {
    formik.setFieldValue(e.name, e.newValue);
    formik.setFieldValue(`${e.name}_unit`, e.newUnit);
    formik.setFieldTouched(e.name, true);
  };

  const imageSelectHandler = (event: any) => {
    setIsProcessingImage(true);
    let fileToConvert = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setBodyScanImage(reader.result);
      setIsProcessingImage(false);
    };
    reader.readAsDataURL(fileToConvert);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      screen_type: results?.screen_type || "Standard",
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      race: results?.race || "",

      total_mass: results?.total_mass || null,
      total_mass_unit: results?.total_mass_unit || "lbs",

      total_fat_mass: results?.total_fat_mass || null,
      total_fat_mass_unit: results?.total_fat_mass_unit || "lbs",

      total_lean_mass: results?.total_lean_mass || null,
      total_lean_mass_unit: results?.total_lean_mass_unit || "lbs",

      total_lean_and_bmc: results?.total_lean_and_bmc || null,
      total_lean_and_bmc_unit: results?.total_lean_and_bmc_unit || "lbs",

      android_mass: results?.android_mass || null,
      android_mass_unit: results?.android_mass_unit || "lbs",

      android_fat: results?.android_fat || null,
      android_fat_unit: results?.android_fat_unit || "lbs",

      android_lean: results?.android_lean || null,
      android_lean_unit: results?.android_lean_unit || "lbs",

      android_fat_percentage: results?.android_fat_percentage || 0,

      vat_fat_mass: results?.vat_fat_mass || null,
      vat_fat_mass_unit: results?.vat_fat_mass_unit || "lbs",
      vat_volume: results?.vat_volume || null,
      vat_volume_unit: results?.vat_volume_unit || "in³",

      gynoid_mass: results?.gynoid_mass || null,
      gynoid_mass_unit: results?.gynoid_mass_unit || "lbs",

      gynoid_fat: results?.gynoid_fat || null,
      gynoid_fat_unit: results?.gynoid_fat_unit || "lbs",

      gynoid_lean: results?.gynoid_lean || null,
      gynoid_lean_unit: results?.gynoid_lean_unit || "lbs",

      gynoid_fat_percentage: results?.gynoid_fat_percentage || 0,

      total_body_weight: results?.total_body_weight || null,
      height: results?.height || null,

      left_arm_fat_mass: results?.left_arm_fat_mass || null,
      left_arm_fat_mass_unit: results?.left_arm_fat_mass_unit || "lbs",

      left_arm_lean_mass: results?.left_arm_lean_mass || null,
      left_arm_lean_mass_unit: results?.left_arm_lean_mass_unit || "lbs",

      left_arm_bmc: results?.left_arm_bmc || null,
      left_arm_bmc_unit: results?.left_arm_bmc_unit || "gms",

      left_arm_lean_and_bmc: results?.left_arm_lean_and_bmc || null,
      left_arm_lean_and_bmc_unit: results?.left_arm_lean_and_bmc_unit || "lbs",

      right_arm_fat_mass: results?.right_arm_fat_mass || null,
      right_arm_fat_mass_unit: results?.right_arm_fat_mass_unit || "lbs",

      right_arm_bmc: results?.right_arm_bmc || null,
      right_arm_bmc_unit: results?.right_arm_bmc_unit || "gms",

      right_arm_lean_and_bmc: results?.right_arm_lean_and_bmc || null,
      right_arm_lean_and_bmc_unit:
        results?.right_arm_lean_and_bmc_unit || "lbs",

      right_arm_lean_mass: results?.right_arm_lean_mass || null,
      right_arm_lean_mass_unit: results?.right_arm_lean_mass_unit || "lbs",

      left_leg_lean_mass: results?.left_leg_lean_mass || null,
      left_leg_lean_mass_unit: results?.left_leg_lean_mass_unit || "lbs",

      left_leg_fat_mass: results?.left_leg_fat_mass || null,
      left_leg_fat_mass_unit: results?.left_leg_fat_mass_unit || "lbs",

      left_leg_bmc: results?.left_leg_bmc || null,
      left_leg_bmc_unit: results?.left_leg_bmc_unit || "gms",

      left_leg_lean_and_bmc: results?.left_leg_lean_and_bmc || null,
      left_leg_lean_and_bmc_unit: results?.left_leg_lean_and_bmc_unit || "lbs",

      right_leg_lean_mass: results?.right_leg_lean_mass || null,
      right_leg_lean_mass_unit: results?.right_leg_lean_mass_unit || "lbs",

      right_leg_fat_mass: results?.right_leg_fat_mass || null,
      right_leg_fat_mass_unit: results?.right_leg_fat_mass_unit || "lbs",

      right_leg_bmc: results?.right_leg_bmc || null,
      right_leg_bmc_unit: results?.right_leg_bmc_unit || "gms",

      right_leg_lean_and_bmc: results?.right_leg_lean_and_bmc || null,
      right_leg_lean_and_bmc_unit:
        results?.right_leg_lean_and_bmc_unit || "lbs",

      total_bone_mass: results?.total_bone_mass || null,
      total_bone_mass_unit: results?.total_bone_mass_unit || "lbs",

      bmd: results?.bmd || null,

      z_score: results?.z_score || null,
      bmi: results?.bmi || null,
      original_scan_url: results?.original_scan_url || "",
      body_scan_image: results?.body_scan_image || "",
    },
    validationSchema: Yup.object({
      original_scan_url: Yup.string().url(
        "You must enter a valid URL with http(s) in the URL."
      ),

      age: Yup.number().positive().typeError("Please enter a valid number."),
      height: Yup.number().typeError("Please enter a valid number."),
      weight: Yup.number().typeError("Please enter a valid number."),
      bmi: Yup.number().typeError("Please enter a valid number."),

      left_arm_lean_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      left_arm_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      right_arm_lean_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      right_arm_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      android_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      android_fat: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      android_lean: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      gynoid_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      gynoid_fat: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      gynoid_lean: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      left_leg_lean_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      left_leg_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      right_leg_lean_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      right_leg_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),

      total_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      total_lean_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      total_bone_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      total_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      vat_fat_mass: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      vat_volume: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
      bmd: Yup.number().nullable().typeError("Please enter a valid number."),
      z_score: Yup.number()
        .nullable()
        .typeError("Please enter a valid number."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        calculateTotalLean();
        let validForm = inputCheck(formik);

        if (validForm) {
          helpers.setSubmitting(true);
          submitHandler(values);
        } else {
          setSubmitAttempt(submitAttempt + 1);
          displayErrorPopup(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    if (
      formik.values.total_body_weight != null &&
      formik.values.height != null
    ) {
      let bmi =
        (formik.values.total_body_weight! /
          (formik.values.height! * formik.values.height!)) *
        703;
      formik.setFieldValue("bmi", roundOff(bmi, 1));
    }
  }, [formik.values.total_body_weight, formik.values.height]);

  useEffect(() => {
    if (
      !checkNumberIsEmpty(formik.values.android_fat) &&
      !checkNumberIsEmpty(formik.values.android_mass)
    ) {
      formik.setFieldValue(
        "android_fat_percentage",
        roundOff(
          (formik.values.android_fat! / formik.values.android_mass!) * 100,
          2
        )
      );
    } else {
      formik.setFieldValue("android_fat_percentage", 0);
    }
  }, [formik.values.android_fat, formik.values.android_mass]);

  useEffect(() => {
    if (
      !checkNumberIsEmpty(formik.values.gynoid_fat) &&
      !checkNumberIsEmpty(formik.values.gynoid_mass)
    ) {
      formik.setFieldValue(
        "gynoid_fat_percentage",
        roundOff(
          (formik.values.gynoid_fat! / formik.values.gynoid_mass!) * 100,
          2
        )
      );
    } else {
      formik.setFieldValue("gynoid_fat_percentage", 0);
    }
  }, [formik.values.gynoid_fat, formik.values.gynoid_mass]);

  if (results !== undefined) {
    return (
      <Container>
        <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                label="Age"
                name="age"
                required
                error={Boolean(formik.touched.age && formik.errors.age)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.age}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
                <Select
                  labelId="genderAtBirthLabel"
                  name="gender"
                  label="Gender at birth"
                  value={formik.values.gender}
                  error={Boolean(
                    formik.touched?.gender && formik.errors?.gender
                  )}
                  onChange={formik.handleChange}
                  disabled={!props.isEditing}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="race">Race</InputLabel>
                <Select
                  labelId="race"
                  name="race"
                  label="Race"
                  value={formik.values.race}
                  error={Boolean(formik.touched?.race && formik.errors?.race)}
                  onChange={formik.handleChange}
                  disabled={!props.isEditing}
                >
                  <MenuItem value={"Black"}>Black</MenuItem>
                  <MenuItem value={"Hispanic"}>Hispanic</MenuItem>
                  <MenuItem value={"White"}>White</MenuItem>
                  <MenuItem value={"All"}>Unknown</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.height && formik.errors.height)}
                fullWidth
                helperText={formik.touched.height && formik.errors.height}
                label="Height in Inches"
                name="height"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.height}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">inches</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(
                  formik.touched.total_body_weight &&
                    formik.errors.total_body_weight
                )}
                fullWidth
                helperText={
                  formik.touched.total_body_weight &&
                  formik.errors.total_body_weight
                }
                label="Weight"
                name="total_body_weight"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.total_body_weight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(formik.touched.bmi && formik.errors.bmi)}
                fullWidth
                helperText={formik.touched.bmi && formik.errors.bmi}
                label="BMI"
                name="bmi"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.bmi || ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={12} sx={{ mt: 6 }}>
              <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
                LEFT ARM
              </Typography>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Fat"
                    name="left_arm_fat_mass"
                    value={formik.values.left_arm_fat_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.left_arm_fat_mass &&
                      formik.errors.left_arm_fat_mass
                    }
                    error={Boolean(
                      formik.touched.left_arm_fat_mass &&
                        formik.errors.left_arm_fat_mass
                    )}
                    unit={formik.values.left_arm_fat_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Lean"
                    name="left_arm_lean_mass"
                    value={formik.values.left_arm_lean_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.left_arm_lean_mass &&
                      formik.errors.left_arm_lean_mass
                    }
                    error={Boolean(
                      formik.touched.left_arm_lean_mass &&
                        formik.errors.left_arm_lean_mass
                    )}
                    unit={formik.values.left_arm_lean_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", mb: 2, mt: 6 }}
              >
                WAIST (ANDROID)
              </Typography>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Mass"
                    name="android_mass"
                    value={formik.values.android_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.android_mass && formik.errors.android_mass
                    }
                    error={Boolean(
                      formik.touched.android_mass && formik.errors.android_mass
                    )}
                    unit={formik.values.android_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Fat"
                    name="android_fat"
                    value={formik.values.android_fat}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.android_fat && formik.errors.android_fat
                    }
                    error={Boolean(
                      formik.touched.android_fat && formik.errors.android_fat
                    )}
                    unit={formik.values.android_fat_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Lean"
                    name="android_lean"
                    value={formik.values.android_lean}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.android_lean && formik.errors.android_lean
                    }
                    error={Boolean(
                      formik.touched.android_lean && formik.errors.android_lean
                    )}
                    unit={formik.values.android_lean_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    error={Boolean(
                      formik.touched.android_fat_percentage &&
                        formik.errors.android_fat_percentage
                    )}
                    fullWidth
                    helperText={
                      formik.touched.android_fat_percentage &&
                      formik.errors.android_fat_percentage
                    }
                    label="Fat Percentage"
                    name="android_fat_percentage"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.android_fat_percentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputMode: "numeric",
                    }}
                    disabled={
                      !props.isEditing ||
                      !checkNumberIsEmpty(formik.values.android_fat) ||
                      !checkNumberIsEmpty(formik.values.android_mass)
                    }
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ textAlign: "center", mt: 6 }}>
                LEFT LEG
              </Typography>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Fat"
                    name="left_leg_fat_mass"
                    value={formik.values.left_leg_fat_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.left_leg_fat_mass &&
                      formik.errors.left_leg_fat_mass
                    }
                    error={Boolean(
                      formik.touched.left_leg_fat_mass &&
                        formik.errors.left_leg_fat_mass
                    )}
                    unit={formik.values.left_leg_fat_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Lean"
                    name="left_leg_lean_mass"
                    value={formik.values.left_leg_lean_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.left_leg_lean_mass &&
                      formik.errors.left_leg_lean_mass
                    }
                    error={Boolean(
                      formik.touched.left_leg_lean_mass &&
                        formik.errors.left_leg_lean_mass
                    )}
                    unit={formik.values.left_leg_lean_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
              </Grid>{" "}
            </Grid>
            <Grid item md={4} xs={12}>
              <img
                src="/img/DexaMan-09.png"
                title="fitnescity logo"
                className={styles.DexaMan}
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ mt: 6 }}>
              <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
                RIGHT ARM
              </Typography>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Fat"
                    name="right_arm_fat_mass"
                    value={formik.values.right_arm_fat_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.right_arm_fat_mass &&
                      formik.errors.right_arm_fat_mass
                    }
                    error={Boolean(
                      formik.touched.right_arm_fat_mass &&
                        formik.errors.right_arm_fat_mass
                    )}
                    unit={formik.values.right_arm_fat_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Lean"
                    name="right_arm_lean_mass"
                    value={formik.values.right_arm_lean_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.right_arm_lean_mass &&
                      formik.errors.right_arm_lean_mass
                    }
                    error={Boolean(
                      formik.touched.right_arm_lean_mass &&
                        formik.errors.right_arm_lean_mass
                    )}
                    unit={formik.values.right_arm_lean_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
              </Grid>{" "}
              <Typography
                variant="h6"
                sx={{ textAlign: "center", mb: 2, mt: 6 }}
              >
                HIPS (GYNOID)
              </Typography>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Mass"
                    name="gynoid_mass"
                    value={formik.values.gynoid_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.gynoid_mass && formik.errors.gynoid_mass
                    }
                    error={Boolean(
                      formik.touched.gynoid_mass && formik.errors.gynoid_mass
                    )}
                    unit={formik.values.gynoid_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Fat"
                    name="gynoid_fat"
                    value={formik.values.gynoid_fat}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.gynoid_fat && formik.errors.gynoid_fat
                    }
                    error={Boolean(
                      formik.touched.gynoid_fat && formik.errors.gynoid_fat
                    )}
                    unit={formik.values.gynoid_fat_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item lg={4} md={12}>
                  <NumberField
                    label="Lean"
                    name="gynoid_lean"
                    value={formik.values.gynoid_lean}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.gynoid_lean && formik.errors.gynoid_lean
                    }
                    error={Boolean(
                      formik.touched.gynoid_lean && formik.errors.gynoid_lean
                    )}
                    unit={formik.values.gynoid_lean_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    error={Boolean(
                      formik.touched.gynoid_fat_percentage &&
                        formik.errors.gynoid_fat_percentage
                    )}
                    fullWidth
                    helperText={
                      formik.touched.gynoid_fat_percentage &&
                      formik.errors.gynoid_fat_percentage
                    }
                    label="Fat Percentage"
                    name="gynoid_fat_percentage"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.gynoid_fat_percentage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                      inputMode: "numeric",
                    }}
                    disabled={
                      !props.isEditing ||
                      !checkNumberIsEmpty(formik.values.gynoid_fat) ||
                      !checkNumberIsEmpty(formik.values.gynoid_mass)
                    }
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ textAlign: "center", mt: 6 }}>
                RIGHT LEG
              </Typography>
              <Grid container spacing={2} rowSpacing={1}>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Fat"
                    name="right_leg_fat_mass"
                    value={formik.values.right_leg_fat_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.right_leg_fat_mass &&
                      formik.errors.right_leg_fat_mass
                    }
                    error={Boolean(
                      formik.touched.right_leg_fat_mass &&
                        formik.errors.right_leg_fat_mass
                    )}
                    unit={formik.values.right_leg_fat_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <NumberField
                    label="Lean"
                    name="right_leg_lean_mass"
                    value={formik.values.right_leg_lean_mass}
                    isEditing={props.isEditing}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={
                      formik.touched.right_leg_lean_mass &&
                      formik.errors.right_leg_lean_mass
                    }
                    error={Boolean(
                      formik.touched.right_leg_lean_mass &&
                        formik.errors.right_leg_lean_mass
                    )}
                    unit={formik.values.right_leg_lean_mass_unit}
                    onUnitChange={handleUnitChange}
                  />
                </Grid>
              </Grid>{" "}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12} sx={{ mt: 6 }}>
              <NumberField
                label="Total Fat Mass"
                name="total_fat_mass"
                value={formik.values.total_fat_mass}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.total_fat_mass && formik.errors.total_fat_mass
                }
                error={Boolean(
                  formik.touched.total_fat_mass && formik.errors.total_fat_mass
                )}
                unit={formik.values.total_fat_mass_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 6 }}>
              <NumberField
                label="Total Lean Mass"
                name="total_lean_mass"
                value={formik.values.total_lean_mass}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.total_lean_mass &&
                  formik.errors.total_lean_mass
                }
                error={Boolean(
                  formik.touched.total_lean_mass &&
                    formik.errors.total_lean_mass
                )}
                unit={formik.values.total_lean_mass_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 6 }}>
              <NumberField
                label="Total BMC"
                name="total_bone_mass"
                value={formik.values.total_bone_mass}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.total_bone_mass &&
                  formik.errors.total_bone_mass
                }
                error={Boolean(
                  formik.touched.total_bone_mass &&
                    formik.errors.total_bone_mass
                )}
                unit={formik.values.total_bone_mass_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 6 }}>
              <NumberField
                label="Total Mass"
                name="total_mass"
                value={formik.values.total_mass}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.total_mass && formik.errors.total_mass
                }
                error={Boolean(
                  formik.touched.total_mass && formik.errors.total_mass
                )}
                unit={formik.values.total_mass_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12} sx={{ mt: 3 }}>
              <NumberField
                label="Vat Fat Mass"
                name="vat_fat_mass"
                value={formik.values.vat_fat_mass}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.vat_fat_mass && formik.errors.vat_fat_mass
                }
                error={Boolean(
                  formik.touched.vat_fat_mass && formik.errors.vat_fat_mass
                )}
                unit={formik.values.vat_fat_mass_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 3 }}>
              <NumberField
                label="Vat Volume"
                name="vat_volume"
                value={formik.values.vat_volume}
                isEditing={props.isEditing}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.vat_volume && formik.errors.vat_volume
                }
                error={Boolean(
                  formik.touched.vat_volume && formik.errors.vat_volume
                )}
                unit={formik.values.vat_volume_unit}
                onUnitChange={handleUnitChange}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(formik.touched.bmd && formik.errors.bmd)}
                fullWidth
                helperText={formik.touched.bmd && formik.errors.bmd}
                label="Bone Mineral Density"
                name="bmd"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.bmd}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g/cm²</InputAdornment>
                  ),
                  inputMode: "numeric",
                }}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={3} xs={12} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(formik.touched.z_score && formik.errors.z_score)}
                fullWidth
                helperText={formik.touched.z_score && formik.errors.z_score}
                label="Z-Score"
                name="z_score"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.z_score}
                disabled={!props.isEditing}
              />
            </Grid>
            <Grid item md={12} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(
                  formik.touched.original_scan_url &&
                    formik.errors.original_scan_url
                )}
                fullWidth
                label="Link to Original Scan URL"
                name="original_scan_url"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.original_scan_url}
                disabled={!props.isEditing}
                helperText={
                  formik.touched.original_scan_url &&
                  formik.errors.original_scan_url
                }
              />
            </Grid>
            <Grid item md={12} sx={{ mt: 3 }}>
              {props.isEditing && (
                <LoadingButton
                  variant="contained"
                  component="label"
                  loading={isProcessingImage}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={imageSelectHandler}
                    name="body_image_file"
                  />
                </LoadingButton>
              )}
              {(bodyScanImage || formik.values.body_scan_image) && (
                <p>
                  Body Image:
                  <br />
                  <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                    <img
                      src={bodyScanImage || formik.values.body_scan_image}
                      alt="body image"
                      className={styles.BodyImagePreview}
                    />
                  </Paper>
                </p>
              )}
            </Grid>
          </Grid>
          {props.isEditing && (
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 3, justifyContent: "flex-end" }}
              >
                <Button
                  variant="text"
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Stack>
              <p>&nbsp;</p>
            </Box>
          )}
          <ErrorPopup
            show={errorPopup}
            submits={submitAttempt}
            onClose={onErrorPopupClose}
            onOverride={onErrorOverride}
          />
        </form>
      </Container>
    );
  } else {
    return <p>Loading ...</p>;
  }
};

export default DexaTest;
