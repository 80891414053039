import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetProfileResultType, ProfileType, UpdatePasswordResultType, UpdateProfileResultType, UpdatePasswordRequestType } from './types';
import { store } from '../../store';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/admin/`,
        prepareHeaders: (headers) => {
            headers.set('x-api-key', API_KEY);
            headers.set('Authorization', store.getState().currentUserState.token!);
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getProfile: builder.query<ProfileType,{}>
        ({
            query() {
                return {
                    url: `profile`,
                    method: 'GET'
                }
            },
            transformResponse: (result: GetProfileResultType) => {
                return result.data;
            }  
        }),
        updateProfile: builder.mutation<UpdateProfileResultType, Partial<ProfileType>>
        ({
            query({...data}) {
                return {
                    url: `profile`,
                    method: 'PUT',
                    body: data
                }
            },
            transformResponse: (result: any):UpdateProfileResultType => {
                return result.data;
            }
        }),
        updatePassword: builder.mutation<UpdatePasswordResultType, UpdatePasswordRequestType>
        (
            {
                query({...data}) {
                    return {
                        url: `profile/changepassword`,
                        method: 'POST',
                        body: data
                    }
                },
                transformResponse: (result: any):UpdatePasswordResultType => {
                    return result.data;
                }
            }
        )       
    })
});

export const {
    useGetProfileQuery,
    useUpdateProfileMutation,
    useUpdatePasswordMutation
} = profileApi;