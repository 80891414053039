import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Theme,
} from "@mui/material";
import { useGetAllProductsQuery } from "../../../../../../redux/features/commerce/products/productsApi";
import { isNull, merge } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useUpdateGiftCertificateMutation } from "../../../../../../redux/features/commerce/gift-certificates/giftCertificatesApi";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../../../../_shared/utils/apiHelpers";
import { ProductType } from "../../../../../../redux/features/commerce/products/types";
import { theme } from "../../../../../../_shared/theme";
import { GiftCertificateType } from "../../../../../../redux/features/commerce/gift-certificates/types";
import { useGetTermsQuery } from "../../../../../../redux/features/commerce/terms/termsApi";
import { TermType } from "../../../../../../redux/features/commerce/terms/types";
import _ from "lodash";

type UpdateGiftCertificateProps = {
  show: boolean;
  giftCertificateToUpdate: GiftCertificateType | Record<string, any>;
  doneEditing: (reload: boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  personName: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const UpdateGiftCertificateModal = (props: UpdateGiftCertificateProps) => {
  const handleClose = () => {
    props.doneEditing(false);
  };
  const [giftCertificateToUpdate, setGiftCertificateToUpdate] = useState<
    GiftCertificateType | Record<string, any>
  >();
  const [terms, setTerms] = useState<{ id: string; label: string }[]>([]);

  const [products, setProducts] = useState<ProductType[]>();
  const { data = [], isSuccess } = useGetAllProductsQuery({});
  const [updateGiftCertificate, { isLoading, isError, error }] =
    useUpdateGiftCertificateMutation();

  useEffect(() => {
    if (isSuccess) {
      setProducts(data);
    }
  }, [data]);

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const submitHandler = (values: any) => {
    const payload = merge({}, giftCertificateToUpdate, values);
    updateGiftCertificate(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const { data: termsData, isSuccess: isTermsSuccess } = useGetTermsQuery({});

  useEffect(() => {
    if (isTermsSuccess) {
      setTerms(
        termsData.map((t: TermType) => {
          return { label: t.name, id: t.id };
        })
      );
    }
  }, [termsData]);

  const handleProductChange = (
    event: SelectChangeEvent<typeof formik.values.product_ids>
  ) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "product_ids",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: giftCertificateToUpdate?.name || "",
      description: giftCertificateToUpdate?.description || "",
      price: giftCertificateToUpdate?.price || null,
      product_ids: giftCertificateToUpdate?.product_ids || [],
      terms_id: giftCertificateToUpdate?.terms_id || "",
      is_active: giftCertificateToUpdate?.is_active || true,
      products: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
      price: Yup.number().required("Price is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setGiftCertificateToUpdate(props.giftCertificateToUpdate);
  }, [props.giftCertificateToUpdate]);

  if (formik !== undefined) {
    return (
      <Dialog
        open={props.show}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Update Gift Certificate</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            {isError && isFetchBaseQueryError(error) && (
              <Alert severity="error">
                {getErrorMessage(error as FetchBaseQueryError)}
              </Alert>
            )}
            <Stack spacing={2} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(formik.touched.name && formik.errors.name)}
                fullWidth
                label="Name"
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.name}
              />
              <TextField
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                fullWidth
                label="Description"
                multiline
                rows={3}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <TextField
                error={Boolean(formik.touched.price && formik.errors.price)}
                fullWidth
                label="Price"
                name="price"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <FormControl>
                <InputLabel id="product_list_label">Products</InputLabel>
                <Select
                  labelId="product_list_label"
                  id="products"
                  multiple
                  value={formik.values.products}
                  onChange={handleProductChange}
                  input={<OutlinedInput id="products" label="Products" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {products?.map((product: any) => {
                        if (
                          selected.findIndex((s: any) => s === product.id) >= 0
                        ) {
                          return (
                            <Chip
                              key={product.id}
                              label={product.name}
                              sx={{ backgroundColor: product.color }}
                            />
                          );
                        }
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {products?.map((product) => (
                    <MenuItem
                      key={product.id}
                      value={product.id}
                      style={getStyles(
                        product.name,
                        formik.values.products,
                        theme
                      )}
                    >
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>For All Products Leave Blank</FormHelperText>
              </FormControl>
              <Autocomplete
                disablePortal
                value={_.find(terms, (t: any) => {
                  return t.id === formik.values.terms_id;
                })}
                id="term-combo"
                options={terms}
                onChange={(
                  event: any,
                  newValue: { id: string; label: string } | null
                ) => {
                  formik.setFieldValue("terms_id", newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} required label="Term" />
                )}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.is_active}
                    onChange={handleIsActive}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Active"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              disabled={isLoading}
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  } else {
    return <p>There was an issue loading the record.</p>;
  }
};

export default UpdateGiftCertificateModal;
