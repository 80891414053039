import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreatePartnerMutation } from "../../../../redux/features/partners/partnersApi";
import { useGetPartnersQuery } from "../../../../redux/features/partners/partnersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { PartnerType } from "../../../../redux/features/partners/types";
import { APP } from "../../../../_shared/utils/_urls";

type AddPartnerProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

const AddPartnerModal = (props: AddPartnerProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const { refetch } = useGetPartnersQuery({});
  const [createPartner, { isLoading }] = useCreatePartnerMutation();
  const [partnerToAdd, setPartnerToAdd] = useState<
    PartnerType | Record<string, any>
  >();

  const submitHandler = (values: any) => {
    const payload = merge({}, partnerToAdd, values);

    createPartner(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          refetch();
          navigate(`${APP.PARTNERS}/${result.createdPartner.id}`);
        }
        //navigate(`${APP.PARTNERS}/${params.row.id}`);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      phone_number: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      postal_code: "",
      other_identifier: "",
      dropbox_path: "",
      contact_email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("First name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new partner</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.name}
            />
            <TextField
              error={Boolean(
                formik.touched.phone_number && formik.errors.phone_number
              )}
              fullWidth
              label="Phone"
              name="phone_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.phone_number}
            />
            <TextField
              error={Boolean(
                formik.touched.address_line_1 && formik.errors.address_line_1
              )}
              fullWidth
              label="Address Line 1"
              name="address_line_1"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.address_line_1}
            />
            <TextField
              error={Boolean(
                formik.touched.address_line_2 && formik.errors.address_line_2
              )}
              fullWidth
              label="Address Line 2"
              name="address_line_2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address_line_2}
            />
            <TextField
              error={Boolean(formik.touched.city && formik.errors.city)}
              fullWidth
              label="City"
              name="city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.city}
            />
            <TextField
              error={Boolean(formik.touched.state && formik.errors.state)}
              fullWidth
              label="State"
              name="state"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.state}
            />
            <TextField
              error={Boolean(
                formik.touched.postal_code && formik.errors.postal_code
              )}
              fullWidth
              label="Postal Code"
              name="postal_code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.postal_code}
            />
            <TextField
              error={Boolean(
                formik.touched.other_identifier &&
                  formik.errors.other_identifier
              )}
              fullWidth
              label="Other Identifier"
              name="other_identifier"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.other_identifier}
            />
            <TextField
              error={Boolean(
                formik.touched.dropbox_path && formik.errors.dropbox_path
              )}
              fullWidth
              label="Dropbox Folder Path"
              name="dropbox_path"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.dropbox_path}
            />
            <TextField
              error={Boolean(
                formik.touched.contact_email && formik.errors.contact_email
              )}
              fullWidth
              helperText="Separate multiple emails with ','"
              label="Contact Email"
              name="contact_email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.contact_email}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} //disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPartnerModal;
