import { PartnerType } from "../../../../../redux/features/partners/types";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import {
  useGetPartnersQuery,
  useDeletePartnerMutation,
} from "../../../../../redux/features/partners/partnersApi";
import { APP } from "../../../../../_shared/utils/_urls";
import { useNavigate } from "react-router-dom";

type DeletePartnerProps = {
  partnerToDelete: PartnerType | Record<string, any>;
  show: boolean;
  doneDeleting: (reload: boolean) => void;
};

const DeletePartnerModal = (props: DeletePartnerProps) => {
  const handleClose = () => {
    props.doneDeleting(false);
  };
  const [deletePartner, { isLoading }] = useDeletePartnerMutation();
  const navigate = useNavigate();
  const { refetch } = useGetPartnersQuery({});

  const deleteHandler = () => {
    deletePartner(props.partnerToDelete.id)
      .unwrap()
      .then(() => {
        refetch();
        navigate(APP.PARTNERS);
      });
  };
  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to delete the partner {props.partnerToDelete.name}
        ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton onClick={deleteHandler} loading={isLoading}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePartnerModal;
