import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useUpdateTestStatusMutation } from "../../../../../redux/features/tests/testsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TestType,
  UpdateTestStatusRequestType,
} from "../../../../../redux/features/tests/types";
import { useAppSelector } from "../../../../../redux/hooks";

type UpdateStatusProps = {
  show: boolean;
  test: TestType | undefined;
  doneUpdatingStatus: (
    reload: boolean,
    updated_test: TestType | null | undefined
  ) => void;
};

const UpdateStatusModal = (props: UpdateStatusProps) => {
  const [updateTestStatus, { isLoading }] = useUpdateTestStatusMutation();
  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );

  const showNoteField = () => {
    return formik.values.status === "Flagged";
  };

  const showCompleteAlert = () => {
    return (
      (formik.values.status === "Complete" ||
        formik.values.status === "CompleteAndSend") &&
      props.test?.email_sent
    );
  };

  const showCompleteSendEmailAlert = () => {
    return (
      formik.values.status === "CompleteAndSend" && !props.test?.email_sent
    );
  };

  const canComplete = () => {
    return (
      currentUser?.role === "SUPERADMIN" ||
      currentUser?.id !== props.test?.updated_by
    );
  };

  const handleClose = () => {
    props.doneUpdatingStatus(false, null);
  };

  const submitHandler = (values: any) => {
    const payload = {
      appointment_id: props.test?.id!,
      status: values.status,
      note: values.note,
    } as UpdateTestStatusRequestType;

    updateTestStatus(payload)
      .unwrap()
      .then((result: any) => {
        formik.setSubmitting(false);
        props.doneUpdatingStatus(true, result.updatedTest);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: props.test?.status,
      note: props.test?.note !== null ? props.test?.note : "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });
  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Update Appointment Status</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ mb: 5 }}>
          <Stack spacing={2}>
            <FormControl fullWidth required>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                name="status"
                label="Status"
                value={formik.values.status}
                error={Boolean(formik.touched?.status && formik.errors?.status)}
                onChange={formik.handleChange}
              >
                {props.test?.status === "Scheduled" && (
                  <MenuItem value={"NoShow"}>No Show</MenuItem>
                )}
                {props.test?.status === "NoShow" && (
                  <MenuItem value={"Scheduled"}>Scheduled</MenuItem>
                )}
                {(props.test?.status === "Scheduled" ||
                  props.test?.status === "NeedsApproval" ||
                  props.test?.status === "Flagged" ||
                  props.test?.status === "NoShow") && (
                  <MenuItem value={"NeedsApproval"}>Needs Approval</MenuItem>
                )}
                {(props.test?.status === "Scheduled" ||
                  props.test?.status === "NeedsApproval" ||
                  props.test?.status === "Flagged" ||
                  props.test?.status === "NoShow") && (
                  <MenuItem value={"Flagged"}>Flagged</MenuItem>
                )}
                {(props.test?.status === "NeedsApproval" ||
                  props.test?.status === "Complete" ||
                  props.test?.status === "CompleteAndSend" ||
                  props.test?.status === "Flagged") && (
                  <MenuItem value={"Complete"}>Complete</MenuItem>
                )}
                {(props.test?.status === "NeedsApproval" ||
                  props.test?.status === "Complete" ||
                  props.test?.status === "CompleteAndSend" ||
                  props.test?.status === "Flagged") && (
                  <MenuItem value={"CompleteAndSend"}>
                    Complete - Send Results to Customer
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            {showNoteField() && (
              <FormControl fullWidth>
                <TextField
                  id="note"
                  label="Note"
                  multiline
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.note}
                />
              </FormControl>
            )}
            {showCompleteAlert() && (
              <Alert severity="info">
                The customer has been previously sent the results. updating the
                record will NOT resend the results.
              </Alert>
            )}

            {showCompleteSendEmailAlert() && (
              <Alert severity="info">
                By clicking save this will mark the record as complete and send
                a notification to the customer that their results are ready.
              </Alert>
            )}
            {!canComplete() &&
              (formik.values.status === "Complete" ||
                formik.values.status === "CompleteAndSent") && (
                <Alert severity="warning">
                  You do not have the ability to mark this record as complete.
                </Alert>
              )}

            <p></p>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton
            disabled={
              isLoading ||
              (!canComplete &&
                (formik.values.status === "Complete" ||
                  formik.values.status === "CompleteAndSent"))
            }
            type="submit"
            loading={isLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateStatusModal;
