import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  RmrType,
  TestType,
  UpdateTestResultType,
} from "../../../../../redux/features/tests/types";
import * as Yup from "yup";
import styles from "./rmr.module.css";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import { getAgeFromDateOfBirth, validHeight, validWeight } from "../../../../../_shared/utils/uiHelpers";
import ErrorPopup from "../error-popup";

type RmrTestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

const inputCheck = (formik : FormikProps<any>) => {
  const RMR_MIN = 1000;
  const RMR_MAX = 3000;

  let validForm : boolean = true;
  const errors : FormikErrors<RmrType> = {};
  const error_message = " ";

  if (!validHeight(formik.values.height)) {
    errors.height = error_message;
  }
  if (!validWeight(formik.values.weight)) {
    errors.weight = error_message;
  }
  
  if (formik.values.measured_rmr != null) {
    let rmr = formik.values.measured_rmr * 1.0;
    if (rmr < RMR_MIN || rmr > RMR_MAX) {
      errors.measured_rmr = error_message;
    }
  }

  if (Object.keys(errors).length !== 0) {
    console.log(errors);
    formik.setErrors({...errors});
    validForm = false;
  }
  return validForm;
}

const RmrTest = (props: RmrTestType) => {
  const [results, setResults] = useState<RmrType>();
  const [errorPopup, displayErrorPopup] = useState<boolean>(false);
  
  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
        setSubmitAttempt(0);
      });
  };

  const onErrorPopupClose = () => {
    displayErrorPopup(false);
  }

  const onErrorOverride = () => {
    displayErrorPopup(false);
    formik.setSubmitting(true);
    submitHandler(formik.values);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      weight: results?.weight || 0,
      height: results?.height || 0,
      measured_rmr: results?.measured_rmr || 0,
      rer: results?.rer || null,
    },
    validationSchema: Yup.object({
      age: Yup.number().positive(),
      height: Yup.number().typeError("Please enter a valid number."),
      weight: Yup.number().typeError("Please enter a valid number."),
      measured_rmr: Yup.number().typeError("Please enter a valid number."),
      rer: Yup.number().nullable().positive().max(2),
    }),
    onSubmit: async (values, helpers) => {
      try {
        let validForm = inputCheck(formik);

        if (validForm) {
          helpers.setSubmitting(true);
          submitHandler(values);
        } else {
          setSubmitAttempt(submitAttempt + 1);
          displayErrorPopup(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateOnBlur:false,
    validateOnChange:false,
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Age"
            name="age"
            required
            error={Boolean(formik.touched.age && formik.errors.age)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.age}
            disabled={!props.isEditing}
            helperText="Age derived from DOB"
          />
          <FormControl fullWidth required>
            <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
            <Select
              labelId="genderAtBirthLabel"
              name="gender"
              label="Gender at birth"
              value={formik.values.gender}
              error={Boolean(formik.touched?.gender && formik.errors?.gender)}
              onChange={formik.handleChange}
              disabled={!props.isEditing}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
          <TextField
            error={Boolean(formik.touched.height && formik.errors.height)}
            fullWidth
            helperText={formik.touched.height && formik.errors.height}
            label="Height in Inches"
            name="height"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.height}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inches</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.weight && formik.errors.weight)}
            fullWidth
            helperText={formik.touched.weight && formik.errors.weight}
            label="Weight"
            name="weight"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.weight}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.measured_rmr && formik.errors.measured_rmr
            )}
            fullWidth
            helperText={
              formik.touched.measured_rmr && formik.errors.measured_rmr
            }
            label="Measured RMR"
            name="measured_rmr"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.measured_rmr}
            InputProps={{
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.rer && formik.errors.rer)}
            fullWidth
            helperText={formik.touched.rer && formik.errors.rer}
            label="Respiratory Exchange Ratio"
            name="rer"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.rer}
            disabled={!props.isEditing}
          />
        </Stack>
        {props.isEditing && (
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 3, justifyContent: "flex-end" }}
            >
              <Button
                variant="text"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                disabled={isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Stack>
            <p>&nbsp;</p>
          </Box>
        )}
        <ErrorPopup
            show={errorPopup}
            submits={submitAttempt}
            onClose={onErrorPopupClose}
            onOverride={onErrorOverride}
        />
      </form>
    </Container>
  );
};

export default RmrTest;
