import { Grid, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { preProcessFile } from "typescript";
import styles from "./formInfo.module.css";

interface FormInfoType {
  label: string | undefined | null | "";
  value: string | undefined | null | ReactNode | "";
}

const FormInfo = (props: FormInfoType) => {
  return (
    // <Grid container spacing={2} columns={2}>
    //   <Grid
    //     item
    //     sx={{ display: "flex", justifyContent: "flex-end" }}
    //     md={1}
    //     sm={2}
    //   >
    //     <Typography>
    //       {props.label}
    //     </Typography>
    //   </Grid>
    //   <Grid
    //     item
    //     sx={{ display: "flex", justifyContent: "flex-start" }}
    //     md={1}
    //     sm={2}
    //   >
    //     <p>{props.value}</p>
    //   </Grid>
    // </Grid>
    <Stack direction="row" spacing={2} sx={{ my: 1 }}>
      <Typography fontWeight="bold">{props.label}</Typography>
      <Typography>{props.value}</Typography>
    </Stack>
  );
};

export default FormInfo;
