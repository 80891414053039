import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { ProductTestType } from "../../../../../../../../../redux/features/commerce/products/types";
import FormInfo from "../../../../../../../../../_shared/components/FormInfo";
import {
  useDeleteProductTestMutation,
  useUpdateProductTestMutation,
} from "../../../../../../../../../redux/features/commerce/products/productTestsApi";
import { PartnerTestType } from "../../../../../../../../../redux/features/partnerTests/types";
import { useGetPartnerTestsByTestTypeQuery } from "../../../../../../../../../redux/features/partnerTests/partnerTestsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";

type DeleteProductProps = {
  show: boolean;
  productTestToDelete: ProductTestType | Record<string, any> | undefined;
  doneDeleting: (reload: boolean) => void;
};

const DeleteProductTestModal = (props: DeleteProductProps) => {
  const handleClose = () => {
    props.doneDeleting(false);
  };

  const [deleteProductTest, { isLoading }] = useDeleteProductTestMutation();

  const submitHandler = (e: any) => {
    e.preventDefault();
    deleteProductTest(props.productTestToDelete?.id)
      .unwrap()
      .then((result) => {
        if (result) {
          props.doneDeleting(true);
        }
      });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>{`Delete the test type ${props.productTestToDelete?.test_type}?`}</DialogTitle>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the test type{" "}
            {props.productTestToDelete?.test_type}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteProductTestModal;
