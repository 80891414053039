import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type FilterProps = {
  statuses: any[] | undefined;
  startDate: Date;
  endDate: Date;
  statusOptions: any[];
  changeHandler: (filter: any) => void;
};

const OrdersFilter = (props: FilterProps) => {
  const handleStatusesChange = (statuses: any[] | null) => {
    const filter = {
      statuses: statuses,
      startDate: props.startDate,
      endDate: props.endDate,
    };
    props.changeHandler(filter);
  };

  const handleBeginDateChange = (startDate: Date | null) => {
    const filter = {
      statuses: props.statuses,
      startDate: startDate,
      endDate: props.endDate,
    };
    props.changeHandler(filter);
  };

  const handleEndDateChange = (endDate: Date | null) => {
    const filter = {
      statuses: props.statuses,
      startDate: props.startDate,
      endDate: endDate,
    };
    props.changeHandler(filter);
  };
  return (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        multiple
        fullWidth
        id="statuses"
        options={props.statusOptions!}
        getOptionLabel={(option) => option.label}
        getOptionKey={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Statuses"
            placeholder="Statuses"
          />
        )}
        onChange={(event: any, newValue: any[] | null) => {
          handleStatusesChange(newValue);
        }}
        value={props.statuses}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          sx={{ width: "400px" }}
          label="Start Date"
          value={props.startDate}
          onChange={(newValue) => handleBeginDateChange(newValue)}
        />

        <DatePicker
          sx={{ width: "400px" }}
          label="End Date"
          value={props.endDate}
          onChange={(newValue) => handleEndDateChange(newValue)}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default OrdersFilter;
