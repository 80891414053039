import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
    DexaType,
    TestType,
    UpdateTestResultType,
} from "../../../../../../redux/features/tests/types";
import * as Yup from "yup";
import styles from "../dexa.module.css";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import {
getAgeFromDateOfBirth,
roundOff,
unitConversion,
} from "../../../../../../_shared/utils/uiHelpers";
import NumberField from "../../shared/NumberField";
import { inputCheck } from "../dexa-standard";
import ErrorPopup from "../../error-popup";

type DexaTestType = {
    test: TestType | undefined;
    isEditing: boolean;
    doneEditing: (reload: boolean) => void;
};

const DexaLimitedTest = (props: DexaTestType) => {
    const [results, setResults] = useState<DexaType>();
    const [bodyScanImage, setBodyScanImage] = useState<any>();
    const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);
    const [errorPopup, displayErrorPopup] = useState<boolean>(false);
  
  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

    useEffect(() => {
        if (props?.test?.results !== undefined) {
          setResults(props?.test?.results as any);
        }
    }, [props]);

    const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

    const handleCancel = () => {
        props.doneEditing(false);
    };
    
    const submitHandler = (values: any) => {
        values.body_scan_image = bodyScanImage;
        values.screen_type = "Limited";

        const payload = {
            appointment_id: props.test?.id!,
            results: merge({}, results, values),
        } as UpdateTestResultType;

        updateTestResult(payload)
        .unwrap()
        .then(() => {
            formik.setSubmitting(false);
            props.doneEditing(true);
            setSubmitAttempt(0);
        });
    };

    const calcLeanMasses = () => {
        if (formik.values.left_arm_lean_and_bmc !== null && formik.values.left_arm_bmc !== null) {
            let bmc_lbs = unitConversion(formik.values.left_arm_bmc, "gms", "lbs");
            formik.values.left_arm_lean_mass = roundOff(formik.values.left_arm_lean_and_bmc - bmc_lbs, 1);
        }
        if (formik.values.right_arm_lean_and_bmc !== null && formik.values.right_arm_bmc !== null) {
            let bmc_lbs = unitConversion(formik.values.right_arm_bmc, "gms", "lbs");
            formik.values.right_arm_lean_mass = roundOff(formik.values.right_arm_lean_and_bmc - bmc_lbs, 1);
        }
        if (formik.values.left_leg_lean_and_bmc !== null && formik.values.left_leg_bmc !== null) {
            let bmc_lbs = unitConversion(formik.values.left_leg_bmc, "gms", "lbs");
            formik.values.left_leg_lean_mass = roundOff(formik.values.left_leg_lean_and_bmc - bmc_lbs, 1);
        }
        if (formik.values.right_leg_lean_and_bmc !== null && formik.values.right_leg_bmc !== null) {
            let bmc_lbs = unitConversion(formik.values.right_leg_bmc, "gms", "lbs");
            formik.values.right_leg_lean_mass = roundOff(formik.values.right_leg_lean_and_bmc - bmc_lbs, 1);
        }
        if (formik.values.total_lean_and_bmc !== null && formik.values.total_bone_mass !== null) {
            let bmc_lbs = 0;
            if (formik.values.total_bone_mass_unit === "gms") {
                bmc_lbs = unitConversion(formik.values.total_bone_mass, "gms", "lbs");
            } else bmc_lbs = formik.values.total_bone_mass;
            formik.values.total_lean_mass = roundOff(formik.values.total_lean_and_bmc - bmc_lbs, 1);
        }
    }

    const calcFatPercentage = () => {
        if (formik.values.android_fat != null && formik.values.android_lean != null) {
            formik.values.android_fat = roundOff(formik.values.android_fat * 1.0, 1);
            formik.values.android_lean = roundOff(formik.values.android_lean * 1.0, 1);
            formik.values.android_mass = roundOff(formik.values.android_fat + formik.values.android_lean, 1);
            formik.values.android_fat_percentage = roundOff(
                (formik.values.android_fat! / formik.values.android_mass!) * 100,
                2
            );
        }
        if (formik.values.gynoid_fat != null && formik.values.gynoid_lean != null) {
            formik.values.gynoid_fat = roundOff(formik.values.gynoid_fat * 1.0, 1);
            formik.values.gynoid_lean = roundOff(formik.values.gynoid_lean * 1.0, 1);
            formik.values.gynoid_mass = roundOff(formik.values.gynoid_fat + formik.values.gynoid_lean, 1);
            formik.values.gynoid_fat_percentage = roundOff(
                (formik.values.gynoid_fat! / formik.values.gynoid_mass!) * 100,
                2
            );
        }
    }

    const onErrorPopupClose = () => {
        displayErrorPopup(false);
    }
    
    const onErrorOverride = () => {
        displayErrorPopup(false);
        formik.setSubmitting(true);
        submitHandler(formik.values);
    }    

    const handleUnitChange = (e: any) => {
        formik.setFieldValue(e.name, e.newValue);
        formik.setFieldValue(`${e.name}_unit`, e.newUnit);
        formik.setFieldTouched(e.name, true);
    };

    const imageSelectHandler = (event: any) => {
        setIsProcessingImage(true);
        let fileToConvert = event.target.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
          setBodyScanImage(reader.result);
          setIsProcessingImage(false);
        };
        reader.readAsDataURL(fileToConvert);
    };
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          screen_type: results?.screen_type || "Standard",
          age:
            results?.age ||
            getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
            0,
          gender: results?.gender || props.test?.customer_profile?.gender,
          race: results?.race || "",
    
          total_mass: results?.total_mass || null,
          total_mass_unit: results?.total_mass_unit || "lbs",
    
          total_fat_mass: results?.total_fat_mass || null,
          total_fat_mass_unit: results?.total_fat_mass_unit || "lbs",
    
          total_lean_mass: results?.total_lean_mass || null,
          total_lean_mass_unit: results?.total_lean_mass_unit || "lbs",
          
          total_lean_and_bmc: results?.total_lean_and_bmc || null,
          total_lean_and_bmc_unit: results?.total_lean_and_bmc_unit || "lbs",

          android_mass: results?.android_mass || null,
          android_mass_unit: results?.android_mass_unit || "lbs",
    
          android_fat: results?.android_fat || null,
          android_fat_unit: results?.android_fat_unit || "lbs",
    
          android_lean: results?.android_lean || null,
          android_lean_unit: results?.android_lean_unit || "lbs",
    
          android_fat_percentage: results?.android_fat_percentage || 0,
    
          vat_fat_mass: results?.vat_fat_mass || null,
          vat_fat_mass_unit: results?.vat_fat_mass_unit || "lbs",
          vat_volume: results?.vat_volume || null,
          vat_volume_unit: results?.vat_volume_unit || "cm³",
    
          gynoid_mass: results?.gynoid_mass || null,
          gynoid_mass_unit: results?.gynoid_mass_unit || "lbs",
    
          gynoid_fat: results?.gynoid_fat || null,
          gynoid_fat_unit: results?.gynoid_fat_unit || "lbs",
    
          gynoid_lean: results?.gynoid_lean || null,
          gynoid_lean_unit: results?.gynoid_lean_unit || "lbs",
    
          gynoid_fat_percentage: results?.gynoid_fat_percentage || 0,
    
          total_body_weight: results?.total_body_weight || null,
          height: results?.height || null,
    
          left_arm_fat_mass: results?.left_arm_fat_mass || null,
          left_arm_fat_mass_unit: results?.left_arm_fat_mass_unit || "lbs",
    
          left_arm_lean_mass: results?.left_arm_lean_mass || null,
          left_arm_lean_mass_unit: results?.left_arm_lean_mass_unit || "lbs",

          left_arm_bmc: results?.left_arm_bmc || null,
          left_arm_bmc_unit: results?.left_arm_bmc_unit || "gms",

          left_arm_lean_and_bmc: results?.left_arm_lean_and_bmc || null,
          left_arm_lean_and_bmc_unit: results?.left_arm_lean_and_bmc_unit || "lbs",
    
          right_arm_fat_mass: results?.right_arm_fat_mass || null,
          right_arm_fat_mass_unit: results?.right_arm_fat_mass_unit || "lbs",

          right_arm_bmc: results?.right_arm_bmc || null,
          right_arm_bmc_unit: results?.right_arm_bmc_unit || "gms",

          right_arm_lean_and_bmc: results?.right_arm_lean_and_bmc || null,
          right_arm_lean_and_bmc_unit: results?.right_arm_lean_and_bmc_unit || "lbs",
    
          right_arm_lean_mass: results?.right_arm_lean_mass || null,
          right_arm_lean_mass_unit: results?.right_arm_lean_mass_unit || "lbs",
    
          left_leg_lean_mass: results?.left_leg_lean_mass || null,
          left_leg_lean_mass_unit: results?.left_leg_lean_mass_unit || "lbs",
    
          left_leg_fat_mass: results?.left_leg_fat_mass || null,
          left_leg_fat_mass_unit: results?.left_leg_fat_mass_unit || "lbs",

          left_leg_bmc: results?.left_leg_bmc || null,
          left_leg_bmc_unit: results?.left_leg_bmc_unit || "gms",

          left_leg_lean_and_bmc: results?.left_leg_lean_and_bmc || null,
          left_leg_lean_and_bmc_unit: results?.left_leg_lean_and_bmc_unit || "lbs",
    
          right_leg_lean_mass: results?.right_leg_lean_mass || null,
          right_leg_lean_mass_unit: results?.right_leg_lean_mass_unit || "lbs",
    
          right_leg_fat_mass: results?.right_leg_fat_mass || null,
          right_leg_fat_mass_unit: results?.right_leg_fat_mass_unit || "lbs",

          right_leg_bmc: results?.right_leg_bmc || null,
          right_leg_bmc_unit: results?.right_leg_bmc_unit || "gms",

          right_leg_lean_and_bmc: results?.right_leg_lean_and_bmc || null,
          right_leg_lean_and_bmc_unit: results?.right_leg_lean_and_bmc_unit || "lbs",
    
          total_bone_mass: results?.total_bone_mass || null,
          total_bone_mass_unit: results?.total_bone_mass_unit || "gms",
    
          bmd: results?.bmd || null,
    
          z_score: results?.z_score || null,
          bmi: results?.bmi || null,
          original_scan_url: results?.original_scan_url || "",
          body_scan_image: results?.body_scan_image || "",
        },
        validationSchema: Yup.object({
          original_scan_url: Yup.string().url(
            "You must enter a valid URL with http(s) in the URL."
          ),

          age: Yup.number().positive().typeError("Please enter a valid number."),
          height: Yup.number().typeError("Please enter a valid number."),
          weight: Yup.number().typeError("Please enter a valid number."),
          bmi: Yup.number().typeError("Please enter a valid number."),
          
          left_arm_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          left_arm_lean_and_bmc: Yup.number().nullable().typeError("Please enter a valid number."),
          left_arm_bmc: Yup.number().nullable().typeError("Please enter a valid number."),

          right_arm_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          right_arm_lean_and_bmc: Yup.number().nullable().typeError("Please enter a valid number."),
          right_arm_bmc: Yup.number().nullable().typeError("Please enter a valid number."),

          left_leg_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          left_leg_lean_and_bmc: Yup.number().nullable().typeError("Please enter a valid number."),
          left_leg_bmc: Yup.number().nullable().typeError("Please enter a valid number."),

          right_leg_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          right_leg_lean_and_bmc: Yup.number().nullable().typeError("Please enter a valid number."),
          right_leg_bmc: Yup.number().nullable().typeError("Please enter a valid number."),

          android_fat: Yup.number().nullable().typeError("Please enter a valid number."),
          android_lean: Yup.number().nullable().typeError("Please enter a valid number."),

          gynoid_fat: Yup.number().nullable().typeError("Please enter a valid number."),
          gynoid_lean: Yup.number().nullable().typeError("Please enter a valid number."),

          total_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          total_lean_and_bmc: Yup.number().nullable().typeError("Please enter a valid number."),
          total_bone_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          total_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          vat_fat_mass: Yup.number().nullable().typeError("Please enter a valid number."),
          vat_volume: Yup.number().nullable().typeError("Please enter a valid number."),
          bmd: Yup.number().nullable().typeError("Please enter a valid number."),
          z_score: Yup.number().nullable().typeError("Please enter a valid number."),
        }),
        onSubmit: async (values, helpers) => {
          try {
            calcLeanMasses();
            calcFatPercentage();
            let validForm = inputCheck(formik);

            if (validForm) {
                helpers.setSubmitting(true);
                submitHandler(values);
            } else {
                setSubmitAttempt(submitAttempt + 1);
                displayErrorPopup(true);
            }
          } catch (err) {
            console.log(err);
          }
        },
        validateOnBlur:false,
        validateOnChange:false,
    });

    useEffect(() => {
        if (formik.values.total_body_weight != null && formik.values.height != null) {
          let bmi = formik.values.total_body_weight! / (formik.values.height! * formik.values.height!) * 703;
          formik.setFieldValue("bmi", roundOff(bmi, 1));
        }
    }, [formik.values.total_body_weight, formik.values.height]);

    if (results !== undefined) {
        return (
            <Container>
                <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item md={4} xs={12}>
                            <TextField
                                fullWidth
                                label="Age"
                                name="age"
                                required
                                error={Boolean(formik.touched.age && formik.errors.age)}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.age}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
                                <Select
                                    labelId="genderAtBirthLabel"
                                    name="gender"
                                    label="Gender at birth"
                                    value={formik.values.gender}
                                    error={Boolean(
                                        formik.touched?.gender && formik.errors?.gender
                                    )}
                                    onChange={formik.handleChange}
                                    disabled={!props.isEditing}
                                >
                                    <MenuItem value={"Male"}>Male</MenuItem>
                                    <MenuItem value={"Female"}>Female</MenuItem>
                                    <MenuItem value={"Other"}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="race">Race</InputLabel>
                                <Select
                                    labelId="race"
                                    name="race"
                                    label="Race"
                                    value={formik.values.race}
                                    error={Boolean(formik.touched?.race && formik.errors?.race)}
                                    onChange={formik.handleChange}
                                    disabled={!props.isEditing}
                                >
                                    <MenuItem value={"Black"}>Black</MenuItem>
                                    <MenuItem value={"Hispanic"}>Hispanic</MenuItem>
                                    <MenuItem value={"White"}>White</MenuItem>
                                    <MenuItem value={"All"}>Unknown</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.height && formik.errors.height)}
                                fullWidth
                                helperText={formik.touched.height && formik.errors.height}
                                label="Height in Inches"
                                name="height"
                                required
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.height}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">inches</InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(
                                formik.touched.total_body_weight &&
                                    formik.errors.total_body_weight
                                )}
                                fullWidth
                                helperText={
                                formik.touched.total_body_weight &&
                                formik.errors.total_body_weight
                                }
                                label="Weight"
                                name="total_body_weight"
                                required
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.total_body_weight}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">lbs</InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.bmi && formik.errors.bmi)}
                                fullWidth
                                helperText={formik.touched.bmi && formik.errors.bmi}
                                label="BMI"
                                name="bmi"
                                required
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.bmi || ''}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">lbs</InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Left Arm
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_arm_fat_mass && 
                                    formik.errors.left_arm_fat_mass)}
                                fullWidth
                                helperText={formik.touched.left_arm_fat_mass && 
                                    formik.errors.left_arm_fat_mass}
                                label="Fat Mass"
                                name="left_arm_fat_mass"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_arm_fat_mass}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_arm_fat_mass_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_arm_lean_and_bmc && 
                                    formik.errors.left_arm_lean_and_bmc)}
                                fullWidth
                                helperText={formik.touched.left_arm_lean_and_bmc && 
                                    formik.errors.left_arm_lean_and_bmc}
                                label="Lean+BMC"
                                name="left_arm_lean_and_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_arm_lean_and_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_arm_lean_and_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />                 
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_arm_bmc && 
                                    formik.errors.left_arm_bmc)}
                                fullWidth
                                helperText={formik.touched.left_arm_bmc && 
                                    formik.errors.left_arm_bmc}
                                label="BMC"
                                name="left_arm_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_arm_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_arm_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Right Arm
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_arm_fat_mass && 
                                    formik.errors.right_arm_fat_mass)}
                                fullWidth
                                helperText={formik.touched.right_arm_fat_mass && 
                                    formik.errors.right_arm_fat_mass}
                                label="Fat Mass"
                                name="right_arm_fat_mass"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_arm_fat_mass}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_arm_fat_mass_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_arm_lean_and_bmc && 
                                    formik.errors.right_arm_lean_and_bmc)}
                                fullWidth
                                helperText={formik.touched.right_arm_lean_and_bmc && 
                                    formik.errors.right_arm_lean_and_bmc}
                                label="Lean+BMC"
                                name="right_arm_lean_and_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_arm_lean_and_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_arm_lean_and_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_arm_bmc && 
                                    formik.errors.right_arm_bmc)}
                                fullWidth
                                helperText={formik.touched.right_arm_bmc && 
                                    formik.errors.right_arm_bmc}
                                label="BMC"
                                name="right_arm_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_arm_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_arm_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Left Leg
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_leg_fat_mass && 
                                    formik.errors.left_leg_fat_mass)}
                                fullWidth
                                helperText={formik.touched.left_leg_fat_mass && 
                                    formik.errors.left_leg_fat_mass}
                                label="Fat Mass"
                                name="left_leg_fat_mass"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_leg_fat_mass}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_leg_fat_mass_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_leg_lean_and_bmc && 
                                    formik.errors.left_leg_lean_and_bmc)}
                                fullWidth
                                helperText={formik.touched.left_leg_lean_and_bmc && 
                                    formik.errors.left_leg_lean_and_bmc}
                                label="Lean+BMC"
                                name="left_leg_lean_and_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_leg_lean_and_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_leg_lean_and_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.left_leg_bmc && 
                                    formik.errors.left_leg_bmc)}
                                fullWidth
                                helperText={formik.touched.left_leg_bmc && 
                                    formik.errors.left_leg_bmc}
                                label="BMC"
                                name="left_leg_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.left_leg_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.left_leg_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Right Leg
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_leg_fat_mass && 
                                    formik.errors.right_leg_fat_mass)}
                                fullWidth
                                helperText={formik.touched.right_leg_fat_mass && 
                                    formik.errors.right_leg_fat_mass}
                                label="Fat Mass"
                                name="right_leg_fat_mass"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_leg_fat_mass}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_leg_fat_mass_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_leg_lean_and_bmc && 
                                    formik.errors.right_leg_lean_and_bmc)}
                                fullWidth
                                helperText={formik.touched.right_leg_lean_and_bmc && 
                                    formik.errors.right_leg_lean_and_bmc}
                                label="Lean+BMC"
                                name="right_leg_lean_and_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_leg_lean_and_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_leg_lean_and_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.right_leg_bmc && 
                                    formik.errors.right_leg_bmc)}
                                fullWidth
                                helperText={formik.touched.right_leg_bmc && 
                                    formik.errors.right_leg_bmc}
                                label="BMC"
                                name="right_leg_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.right_leg_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.right_leg_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Android
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.android_fat && 
                                    formik.errors.android_fat)}
                                fullWidth
                                helperText={formik.touched.android_fat && 
                                    formik.errors.android_fat}
                                label="Fat Mass"
                                name="android_fat"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.android_fat}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.android_fat_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.android_lean && 
                                    formik.errors.android_lean)}
                                fullWidth
                                helperText={formik.touched.android_lean && 
                                    formik.errors.android_lean}
                                label="Lean"
                                name="android_lean"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.android_lean}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.android_lean_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h6" sx={{ textAlign: "left", mb:-1, color: "#b8a8b6"}}>
                                Gynoid
                            </Typography>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.gynoid_fat && 
                                    formik.errors.gynoid_fat)}
                                fullWidth
                                helperText={formik.touched.gynoid_fat && 
                                    formik.errors.gynoid_fat}
                                label="Fat Mass"
                                name="gynoid_fat"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.gynoid_fat}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.gynoid_fat_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.gynoid_lean && 
                                    formik.errors.gynoid_lean)}
                                fullWidth
                                helperText={formik.touched.gynoid_lean && 
                                    formik.errors.gynoid_lean}
                                label="Lean"
                                name="gynoid_lean"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.gynoid_lean}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.gynoid_lean_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>

                        <Grid item md={4} xs={12} sx={{mt:2}}>
                            <TextField
                                error={Boolean(formik.touched.total_fat_mass && 
                                    formik.errors.total_fat_mass)}
                                fullWidth
                                helperText={formik.touched.total_fat_mass && 
                                    formik.errors.total_fat_mass}
                                label="Total Fat Mass"
                                name="total_fat_mass"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.total_fat_mass}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.total_fat_mass_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md={4} xs={12} sx={{mt:2}}>
                            <TextField
                                error={Boolean(formik.touched.total_lean_and_bmc && 
                                    formik.errors.total_lean_and_bmc)}
                                fullWidth
                                helperText={formik.touched.total_lean_and_bmc && 
                                    formik.errors.total_lean_and_bmc}
                                label="Total (Lean+BMC)"
                                name="total_lean_and_bmc"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.total_lean_and_bmc}
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formik.values.total_lean_and_bmc_unit}
                                    </InputAdornment>
                                ),
                                inputMode: "numeric",
                                }}
                                disabled={!props.isEditing}
                            />
                        </Grid>
                        <Grid item md = {4} xs={12} sx={{mt:2}}></Grid>
                        
                        <Grid item md={3} xs={12} sx={{mt:2}}></Grid>
                        <Grid item md={3} xs={12} sx={{mt:2}}>
                            <NumberField
                                label="Total BMC"
                                name="total_bone_mass"
                                value={formik.values.total_bone_mass}
                                isEditing={props.isEditing}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                formik.touched.total_bone_mass &&
                                formik.errors.total_bone_mass
                                }
                                error={Boolean(
                                formik.touched.total_bone_mass &&
                                    formik.errors.total_bone_mass
                                )}
                                unit={formik.values.total_bone_mass_unit}
                                onUnitChange={handleUnitChange}
                            />
                        </Grid>
                        <Grid item md={3} xs={12} sx={{mt:2}}>
                            <NumberField
                                label="Total Mass"
                                name="total_mass"
                                value={formik.values.total_mass}
                                isEditing={props.isEditing}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                formik.touched.total_mass &&
                                formik.errors.total_mass
                                }
                                error={Boolean(
                                formik.touched.total_mass &&
                                    formik.errors.total_mass
                                )}
                                unit={formik.values.total_mass_unit}
                                onUnitChange={handleUnitChange}
                            />
                        </Grid>
                        <Grid item md={3} xs={12} sx={{mt:2}}></Grid>

                        <Grid item md={3} xs={12}>
                            <NumberField
                                label="Vat Fat Mass"
                                name="vat_fat_mass"
                                value={formik.values.vat_fat_mass}
                                isEditing={props.isEditing}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                formik.touched.vat_fat_mass &&
                                formik.errors.vat_fat_mass
                                }
                                error={Boolean(
                                formik.touched.vat_fat_mass &&
                                    formik.errors.vat_fat_mass
                                )}
                                unit={formik.values.vat_fat_mass_unit}
                                onUnitChange={handleUnitChange}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <NumberField
                                label="Vat Volume"
                                name="vat_volume"
                                value={formik.values.vat_volume}
                                isEditing={props.isEditing}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                formik.touched.vat_volume &&
                                formik.errors.vat_volume
                                }
                                error={Boolean(
                                formik.touched.vat_volume &&
                                    formik.errors.vat_volume
                                )}
                                unit={formik.values.vat_volume_unit}
                                onUnitChange={handleUnitChange}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <NumberField
                                label="Bone Mineral Density"
                                name="bmd"
                                value={formik.values.bmd}
                                isEditing={props.isEditing}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                helperText={
                                formik.touched.bmd &&
                                formik.errors.bmd
                                }
                                error={Boolean(
                                formik.touched.bmd &&
                                    formik.errors.bmd
                                )}
                                unit="g/cm²"
                                onUnitChange={() => {}}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.z_score && formik.errors.z_score)}
                                fullWidth
                                helperText={formik.touched.z_score && formik.errors.z_score}
                                label="Z-Score"
                                name="z_score"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.z_score}
                                disabled={!props.isEditing}
                            />
                        </Grid>

                        <Grid item md={12} sx={{mt:2}}>
                            <TextField
                                error={Boolean(
                                formik.touched.original_scan_url &&
                                    formik.errors.original_scan_url
                                )}
                                fullWidth
                                label="Link to Original Scan URL"
                                name="original_scan_url"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.original_scan_url}
                                disabled={!props.isEditing}
                                helperText={
                                formik.touched.original_scan_url &&
                                formik.errors.original_scan_url
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} sx={{ mt: 3 }}>
                        {props.isEditing && (
                            <LoadingButton
                            variant="contained"
                            component="label"
                            loading={isProcessingImage}
                            >
                            Upload
                            <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={imageSelectHandler}
                                name="body_image_file"
                            />
                            </LoadingButton>
                        )}
                        {(bodyScanImage || formik.values.body_scan_image) && (
                            <p>
                            Body Image:
                            <br />
                            <Paper sx={{ maxWidth: "50%", p: 5, m: 5 }}>
                                <img
                                src={bodyScanImage || formik.values.body_scan_image}
                                alt="body image"
                                className={styles.BodyImagePreview}
                                />
                            </Paper>
                            </p>
                        )}
                    </Grid>
                    {props.isEditing && (
                        <Box sx={{ width: "100%" }}>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ mt: 3, justifyContent: "flex-end" }}
                        >
                            <Button
                            variant="text"
                            onClick={handleCancel}
                            disabled={isLoading}
                            >
                            Cancel
                            </Button>
                            <LoadingButton
                            variant="contained"
                            disabled={isLoading}
                            type="submit"
                            loading={isLoading}
                            >
                            Save
                            </LoadingButton>
                        </Stack>
                        <p>&nbsp;</p>
                        </Box>
                    )}
                    <ErrorPopup
                        show={errorPopup}
                        submits={submitAttempt}
                        onClose={onErrorPopupClose}
                        onOverride={onErrorOverride}
                    />
                </form>
            </Container>
        );
    } else {
        return <p>Loading ...</p>;
    }
}

export default DexaLimitedTest;