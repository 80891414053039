import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetCustomersResultType,
  GetCustomerResultType,
  CustomerType,
} from "./types";
import { store } from "../../store";
import { CreateCustomerResultType } from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const customersApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCustomers: builder.query<CustomerType[], {}>({
      query() {
        return {
          url: "users",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetCustomersResultType) => {
        return result.data;
      },
    }),
    getCustomer: builder.query<CustomerType, string>({
      query(customer_id) {
        return {
          url: `users/${customer_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetCustomerResultType) => {
        return result.data;
      },
    }),
    getUsersByType: builder.query<CustomerType[], string>({
      query(user_type) {
        return {
          url: `users/user-type/${user_type}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetCustomersResultType) => {
        return result.data;
      },
    }),
    updateCustomer: builder.mutation<
      CustomerType,
      Partial<CustomerType> & Pick<CustomerType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `users/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetCustomerResultType) => {
        return result.data;
      },
    }),
    createCustomer: builder.mutation<
      CreateCustomerResultType,
      Partial<CustomerType>
    >({
      query(data) {
        return {
          url: `users`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateCustomerResultType => {
        return result.data;
      },
    }),
    deleteCustomer: builder.mutation<boolean, string>({
      query(customer_id) {
        return {
          url: `users/${customer_id}`,
          method: "DELETE",
        };
      },
    }),
    resetCustomerPassword: builder.mutation<boolean, string>({
      query(customer_id) {
        return {
          url: `users/${customer_id}/reset-password`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetUsersByTypeQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useCreateCustomerMutation,
  useDeleteCustomerMutation,
  useResetCustomerPasswordMutation,
} = customersApi;
