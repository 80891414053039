import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store";
import {
  GetPartnerTestsResultType,
  GetPartnerTestResultType,
  PartnerTestType,
  CreatePartnerTestResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const partnerTestsApi = createApi({
  reducerPath: "partnerTestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPartnerTests: builder.query<PartnerTestType[], string>({
      query(partner_id) {
        return {
          url: `partners/${partner_id}/test_types`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetPartnerTestsResultType) => {
        return result.data;
      },
    }),
    getPartnerTestsByTestType: builder.query<PartnerTestType[], string>({
      query(test_type) {
        return {
          url: `partner-tests/${test_type}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetPartnerTestsResultType) => {
        return result.data;
      },
    }),
    getPartnerTest: builder.query<PartnerTestType, string>({
      query(partner_id) {
        return {
          url: `partners/${partner_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetPartnerTestResultType) => {
        return result.data;
      },
    }),
    updatePartnerTest: builder.mutation<
      PartnerTestType,
      Partial<PartnerTestType> & Pick<PartnerTestType, "id">
    >({
      query({ partner_id, ...data }) {
        return {
          url: `partners/${partner_id}/test_types`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetPartnerTestResultType) => {
        return result.data;
      },
    }),
    createPartnerTest: builder.mutation<
      CreatePartnerTestResultType,
      Partial<PartnerTestType>
    >({
      query({ partner_id, ...data }) {
        return {
          url: `partners/${partner_id}/test_types`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreatePartnerTestResultType => {
        return result.data;
      },
    }),
    deletePartnerTest: builder.mutation<boolean, Partial<PartnerTestType>>({
      query({ partner_id, id, ...data }) {
        return {
          url: `partners/${partner_id}/test_types/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetPartnerTestsQuery,
  useGetPartnerTestsByTestTypeQuery,
  useGetPartnerTestQuery,
  useUpdatePartnerTestMutation,
  useDeletePartnerTestMutation,
  useCreatePartnerTestMutation,
} = partnerTestsApi;
