export type test_type = {
  label: string;
  value: string;
};

export const test_types: test_type[] = [
  { label: "DEXA", value: "DEXA" },
  { label: "DEXA-BMD", value: "DEXABMD" },
  { label: "RMR", value: "RMR" },
  { label: "VO2", value: "VO2" },
  { label: "BOD POD", value: "BODPOD" },
  { label: "SECA", value: "SECA" },
  { label: "HYDROSTATIC", value: "HYDROSTATIC" },
];
