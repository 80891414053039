import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeleteProductGroupMutation } from "../../../../../../redux/features/commerce/products/productGroupsApi";
import { ProductGroupType } from "../../../../../../redux/features/commerce/products/types";
import { useNavigate } from "react-router-dom";
import { APP } from "../../../../../../_shared/utils/_urls";

type DeleteProductGroupProps = {
  show: boolean;
  productGroupToDelete: ProductGroupType | Record<string, any> | undefined;
  cancel: () => void;
};

const DeleteProductGroupModal = (props: DeleteProductGroupProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.cancel();
  };
  const [deleteProductGroup, { isLoading }] = useDeleteProductGroupMutation();

  const submitHandler = (e: any) => {
    e.preventDefault();
    deleteProductGroup(props.productGroupToDelete?.id)
      .unwrap()
      .then((result) => {
        if (result) {
          navigate(APP.PRODUCTS);
        }
      });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        {`Delete the product group ${props.productGroupToDelete?.name}?`}
      </DialogTitle>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the product Group{" "}
            {props.productGroupToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} //disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteProductGroupModal;
