import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetProductsByProductGroupQuery } from "../../../../../../redux/features/commerce/products/productsApi";
import { formatDateTime } from "../../../../../../_shared/utils/uiHelpers";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, Stack, Typography } from "@mui/material";
import {
  ProductType,
  ProductTestType,
} from "../../../../../../redux/features/commerce/products/types";
import React from "react";
import _ from "lodash";

type ProductGridProps = {
  productGroupId: string;
  refetch: boolean;
  doneRefetching: () => void;
  showProductDetail: (product_id: string) => void;
};

const ProductsGrid = (props: ProductGridProps) => {
  const [products, setProducts] = useState<ProductType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "display_order",
      headerName: "Display Order",
      flex: 1,
      valueGetter: (params) => {
        return params.row.display_order;
      }
    },
    {
      field: "name",
      headerName: "Product",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Stack>
            <Link
              onClick={() => {
                if (params.row.id) props.showProductDetail(params.row.id);
              }}
            >
              {params.value}
            </Link>
            <Typography variant="subtitle2">
              {params.row.description}
            </Typography>
          </Stack>
        </React.Fragment>
      ),
    },
    {
      field: "tests",
      headerName: "Tests",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          {_.map(params.row.tests, (t: ProductTestType) => {
            return (
              <Typography variant="overline" sx={{ m: 1 }}>
                {t.test_type} - {t.quantity}
              </Typography>
            );
          })}
        </React.Fragment>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.created_at)} by ${
          params.row.created_by_name
        }`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetProductsByProductGroupQuery(props.productGroupId);

  useEffect(() => {
    if (isSuccess) {
      setProducts(data);
    }
  });

  useEffect(() => {
    if (props.refetch) {
      refetch();
      props.doneRefetching();
    }
  }, [props.refetch]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={products}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default ProductsGrid;
