import styles from "./header.module.css";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";

interface HeaderProps {
  onToggleMobile: any;
}

const Header = ({ onToggleMobile }: HeaderProps) => {
  const drawerToggleHandler = () => {
    onToggleMobile();
  };
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={drawerToggleHandler}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <img
          className={styles.logo}
          src="../../../img/logo.svg"
          title="fitnescity logo"
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
