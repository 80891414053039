import { Box, Button } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";

export type OptionType = {
  label: string;
  value: string;
};

type ButtonGroupProps = {
  value: string | null;
  options: OptionType[];
  readonly: boolean;
  onChange: (value: string | null) => void;
};

type OptionButtonProps = {
  option: OptionType;
  readonly: boolean;
  selectedValue: string | null;
  onClick: (value: string | null) => void;
};

const OptionButton = (props: OptionButtonProps) => {
  const [value, setValue] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const clickHandler = () => {
    props.onClick(value);
  };

  useEffect(() => {
    setIsSelected(props.selectedValue === value);
  }, [props.selectedValue]);

  useEffect(() => {
    setValue(props.option.value);
  }, [props.option.value]);

  return (
    <Button
      variant={isSelected ? "contained" : "outlined"}
      sx={{ m: 1, width: "100px" }}
      onClick={clickHandler}
      disabled={props.readonly}
    >
      {props.option.label}
    </Button>
  );
};

const ButtonGroup = (props: ButtonGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  const optionClickHandler = (value: string | null) => {
    props.onChange(value);
  };

  return (
    <Box sx={{ height: "100%", overflow: "auto" }}>
      {_.map(props.options, (o) => {
        return (
          <OptionButton
            option={o}
            readonly={props.readonly}
            selectedValue={selectedValue}
            onClick={optionClickHandler}
          />
        );
      })}
    </Box>
  );
};

export default ButtonGroup;
