import VerifyLoginForm from "../login/components/verifyLoginForm";
import { useLocation, useParams } from "react-router-dom";

type LocationState = {
  email: string;
  password: string;
};

const VerifyLogin = () => {
  const params = useParams();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const hash = params.hash;
  const email = locationState.email;
  const password = locationState.password;

  return <VerifyLoginForm hash={hash} email={email} password={password} />;
};

export default VerifyLogin;
