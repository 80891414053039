import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Link,
  Box,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useGetProductGroupQuery } from "../../../../redux/features/commerce/products/productGroupsApi";
import { ProductGroupType } from "../../../../redux/features/commerce/products/types";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import CodeIcon from "@mui/icons-material/Code";
import { APP } from "../../../../_shared/utils/_urls";
import { formatAudit } from "../../../../_shared/utils/uiHelpers";
import ProductsGrid from "./components/ProductGrid";
import UpdateProductGroupModal from "./components/ProductGroupUpdate";
import ProductGroupWidget from "./components/ProductGroupWidget";
import DeleteProductGroupModal from "./components/ProductGroupDelete";
import FormInfo from "../../../../_shared/components/FormInfo";
import ProductDetailModal from "./components/product/ProductDetail";
import AddProductModal from "./components/product/ProductAdd";

const ProductGroupDetailPage = () => {
  const params = useParams();
  const [productGroup, setProductGroup] = useState<ProductGroupType>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWidgetModal, setShowWidgetModal] = useState(false);
  const [refetchProducts, setRefetchProducts] = useState(false);
  const [productId, setProductId] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isSuccess, refetch } = useGetProductGroupQuery(
    params.product_group_id ?? skipToken
  );

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditModal(true);
  };

  const onDoneEditing = (reload: boolean) => {
    setShowEditModal(false);
    if (reload) {
      refetch();
    }
  };

  const onShowWidgetModal = (e: any) => {
    e.preventDefault();
    setShowWidgetModal(true);
  };

  const onDoneShowingWidgetModal = () => {
    setShowWidgetModal(false);
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeleteModal(true);
  };

  const onCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const onShowProductDetail = (product_id: string) => {
    setProductId(product_id);
  };

  const onDoneShowingProductDetail = (reload: boolean) => {
    setRefetchProducts(true);
    setProductId(null);
  };

  const onDoneRefetchingProducts = () => {
    setRefetchProducts(false);
  };

  const onAddProduct = () => {
    setShowAddProductModal(true);
  };

  const onDoneAddingProduct = (reload: boolean) => {
    if (reload) {
      setRefetchProducts(true);
    }
    setShowAddProductModal(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setProductGroup(data);
    }
  }, [data]);

  useEffect(() => {
    console.log(location);
    if (location.state && location.state.productIdToSet) {
      setProductId(location.state.productIdToSet);
    }
  }, [location]);

  if (isSuccess)
    return (
      <React.Fragment>
        <Grid container columns={2}>
          <Grid item sm={1}>
            <Breadcrumbs sx={{ mt: 1 }}>
              <Typography color="inherit">Commerce</Typography>
              <Typography color="inherit">Products</Typography>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => {
                  navigate(`${APP.PRODUCTS}`);
                }}
              >
                Groups
              </Link>
              <Typography color="text.primary">{productGroup?.name}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Stack direction="row">
              <IconButton
                onClick={onShowWidgetModal}
                color="secondary"
                aria-label="Create Widget Code"
              >
                <CodeIcon />
              </IconButton>
              <Button variant="text" color="secondary" onClick={onEdit}>
                Edit
              </Button>
              <Button variant="text" color="secondary" onClick={onDelete}>
                Delete
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <hr />
        <Container>
          <Stack spacing={2} sx={{ my: 3 }}>
            <FormInfo label="Description" value={productGroup?.description} />
            <FormInfo
              label="Active"
              value={productGroup?.is_active ? "Yes" : "No"}
            />
            <FormInfo
              label="Created"
              value={formatAudit(
                productGroup?.created_by_name,
                productGroup?.created_at,
                ""
              )}
            />
            <FormInfo
              label="Updated"
              value={formatAudit(
                productGroup?.updated_by_name,
                productGroup?.updated_at,
                ""
              )}
            />
          </Stack>
        </Container>
        <Container>
          <Box sx={{ mb: 2, justifyContent: "flex-end", display: "flex" }}>
            <Button variant="text" color="secondary" onClick={onAddProduct}>
              Add +
            </Button>
          </Box>

          <ProductsGrid
            productGroupId={params.product_group_id!}
            showProductDetail={onShowProductDetail}
            refetch={refetchProducts}
            doneRefetching={onDoneRefetchingProducts}
          />
        </Container>
        <UpdateProductGroupModal
          productGroupToUpdate={productGroup}
          doneEditing={onDoneEditing}
          show={showEditModal}
        />
        <ProductGroupWidget
          product_group_id={productGroup?.id}
          show={showWidgetModal}
          doneShowing={onDoneShowingWidgetModal}
        />
        <DeleteProductGroupModal
          show={showDeleteModal}
          productGroupToDelete={productGroup}
          cancel={onCancelDelete}
        />
        <ProductDetailModal
          show={productId !== null}
          product_id={productId}
          doneShowing={onDoneShowingProductDetail}
        />
        <AddProductModal
          show={showAddProductModal}
          product_group_id={productGroup?.id}
          doneAdding={onDoneAddingProduct}
        />
      </React.Fragment>
    );
  else return <p>Loading...</p>;
};

export default ProductGroupDetailPage;
