import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import _, { merge } from "lodash";
import { APP } from "../../../../../_shared/utils/_urls";
import {
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../../../_shared/utils/apiHelpers";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  useCreatePromoCodeMutation,
  useGetPromoCodesQuery,
} from "../../../../../redux/features/commerce/promos/promosApi";
import {
  useGetAllProductsQuery,
  useGetProductsByProductTypeQuery,
} from "../../../../../redux/features/commerce/products/productsApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CheckoutPromoCodeType } from "../../../../../redux/features/commerce/promos/types";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { ProductType } from "../../../../../redux/features/commerce/products/types";
import { theme } from "../../../../../theme";
import ProductSearch from "../../../product-groups/detail/components/product/ProductSearch";

type AddPromoProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

export const enum SelectAllOptions {
  SelectAll = "select-all",
  SelectAllSingles = "select-all-singles",
  SelectAllPackages = "select-all-packages",
  None = "none",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  personName: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const AddPromoModal = (props: AddPromoProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const { refetch } = useGetPromoCodesQuery({});
  const { data = [], isSuccess } = useGetAllProductsQuery({});
  const { data: singles = [] } = useGetProductsByProductTypeQuery("Single");
  const { data: packages = [] } = useGetProductsByProductTypeQuery("Package");
  const [createPromo, { isLoading, isError, error }] =
    useCreatePromoCodeMutation();
  const [promoToAdd, setPromoToAdd] = useState<
    CheckoutPromoCodeType | Record<string, any>
  >();
  const [products, setProducts] = useState<ProductType[]>();
  const [filteredProducts, setFilteredProducts] = useState<ProductType[]>();
  const [radioValue, setRadioValue] = useState<string>("");
  const [categoryDisable, setCategoryDisable] = useState<boolean>(false);
  const [productDisable, setProductDisable] = useState<boolean>(false);
  const [alertShow, setAlertShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (isSuccess) {
      setProducts(data);
      setFilteredProducts(data);
    }
  }, [data]);

  const submitHandler = (values: any) => {
    if (radioValue !== "") {
      if (radioValue === SelectAllOptions.SelectAll) {
        values.product_ids = null;
      } else if (radioValue === SelectAllOptions.SelectAllSingles) {
        values.product_ids = singles?.map((p) => p.id);
        values.select_all_option = SelectAllOptions.SelectAllSingles;
      } else {
        values.product_ids = packages?.map((p) => p.id);
        values.select_all_option = SelectAllOptions.SelectAllPackages;
      }
    }

    const payload = merge({}, promoToAdd, values);
    createPromo(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          refetch();
          navigate(`${APP.PROMO_CODES}/${result.createdPromo.id}`);
        }
      });
  };

  const handleProductChange = (
    event: SelectChangeEvent<typeof formik.values.product_ids>
  ) => {
    const {
      target: { value },
    } = event;
    setAlertShow(false);

    if (value[value.length - 1] === SelectAllOptions.SelectAll) {
      if (selectAllChecked) {
        formik.setFieldValue("product_ids", []);
        setSelectAllChecked(false);
        setCategoryDisable(false);
      } else {
        formik.setFieldValue(
          "product_ids",
          filteredProducts?.map((p) => p.id)
        );
        setSelectAllChecked(true);
        setCategoryDisable(true);
      }
    } else {
      formik.setFieldValue(
        "product_ids",
        typeof value === "string" ? value.split(",") : value
      );

      if (value.length === 0) {
        setCategoryDisable(false);
      } else {
        setCategoryDisable(true);
      }
    }
    formik.setFieldValue("select_all_option", SelectAllOptions.None);
  };

  const handleRadioClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = (e.target as HTMLButtonElement).value;
    setAlertShow(false);
    if (value === radioValue) {
      setRadioValue("");
      setProductDisable(false);
    } else {
      setRadioValue(value);
      setProductDisable(true);
    }
  };

  const containsText = (text: string, searchText: string) => {
    return text.toLowerCase().includes(searchText.toLowerCase());
  };

  useMemo(() => {
    setFilteredProducts(
      products?.filter((p) => containsText(p.name, searchText))
    );
  }, [searchText]);

  const onUpdateSearchText = (updatedSearchText: string) => {
    setSearchText(updatedSearchText);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
      description: "",
      discount_type: "",
      discount_value: null,
      max_value: null,
      max_uses: null,
      expiration_date: null,
      product_ids: [],
      select_all_option: SelectAllOptions.None,
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Code is required").max(255),
      discount_type: Yup.string().required("Discount Type is required"),
      discount_value: Yup.number().required("Discount Amount is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        if (radioValue === "" && values.product_ids.length === 0)
          throw new Error("Please select by category or by product.");
        if (
          radioValue === SelectAllOptions.SelectAllSingles &&
          singles.length === 0
        )
          throw new Error(
            "There are no singles to be applied to the promo code."
          );
        if (
          radioValue === SelectAllOptions.SelectAllPackages &&
          packages.length === 0
        )
          throw new Error(
            "There are no packages to be applied to the promo code."
          );
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
        if (err instanceof Error) {
          setAlertShow(true);
          setAlertMessage(err.message);
        }
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new Promo Code</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              label="Code"
              name="code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.code}
            />
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              label="Description"
              multiline
              rows={3}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <FormControl fullWidth required>
              <InputLabel id="discount_type">Type</InputLabel>
              <Select
                labelId="discount_type"
                name="discount_type"
                label="Type"
                value={formik.values.discount_type}
                error={Boolean(
                  formik.touched?.discount_type && formik.errors?.discount_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={"Value"}>Value ($)</MenuItem>
                <MenuItem value={"Percentage"}>Percentage (%)</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.discount_value && formik.errors.discount_value
              )}
              fullWidth
              label="Discount Amount"
              name="discount_value"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.discount_value}
              InputProps={{
                startAdornment: formik.values.discount_type === "Value" && (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: formik.values.discount_type === "Percentage" && (
                  <InputAdornment position="end">%</InputAdornment>
                ),
              }}
            />
            <TextField
              error={Boolean(
                formik.touched.max_value && formik.errors.max_value
              )}
              fullWidth
              label="Max Value"
              name="max_value"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.max_value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              error={Boolean(formik.touched.max_uses && formik.errors.max_uses)}
              fullWidth
              label="Max Uses"
              name="max_uses"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.max_uses}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expiration Date"
                value={formik.values.expiration_date}
                onChange={(val: any) => {
                  formik.setFieldValue("expiration_date", val);
                }}
                slotProps={{
                  textField: {
                    error: Boolean(
                      formik.touched.expiration_date &&
                        formik.errors.expiration_date
                    ),
                    helperText: `${
                      formik.touched.expiration_date &&
                      formik.errors.expiration_date
                    }`,
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
            {!categoryDisable && (
              <FormControl>
                <FormLabel>Select by Category</FormLabel>
                <RadioGroup row value={radioValue}>
                  <FormControlLabel
                    control={<Radio onClick={handleRadioClick} />}
                    value={SelectAllOptions.SelectAll}
                    label="Select All"
                  />
                  <FormControlLabel
                    control={<Radio onClick={handleRadioClick} />}
                    value={SelectAllOptions.SelectAllSingles}
                    label="Select All: Singles"
                  />
                  <FormControlLabel
                    control={<Radio onClick={handleRadioClick} />}
                    value={SelectAllOptions.SelectAllPackages}
                    label="Select All: Packages"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {!productDisable && <FormLabel>Select by Product</FormLabel>}
            {!productDisable && (
              <FormControl>
                <InputLabel id="product_list_label">Products</InputLabel>
                <Select
                  labelId="product_list_label"
                  id="products"
                  multiple
                  value={formik.values.product_ids}
                  onChange={handleProductChange}
                  input={<OutlinedInput id="product_ids" label="Products" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {products?.map((product: any) => {
                        if (
                          selected.findIndex((s: any) => s === product.id) >= 0
                        ) {
                          return (
                            <Chip
                              key={product.id}
                              label={
                                product.name +
                                " - " +
                                product.product_group_name
                              }
                              sx={{ backgroundColor: product.color }}
                            />
                          );
                        }
                      })}
                    </Box>
                  )}
                  MenuProps={{ autoFocus: false }}
                  onClose={() => setSearchText("")}
                >
                  <ListSubheader>
                    <ProductSearch
                      onUpdateSearchText={onUpdateSearchText}
                      searchText={searchText}
                    />
                  </ListSubheader>
                  <MenuItem value={SelectAllOptions.SelectAll}>
                    <FormControlLabel
                      label="Select All"
                      control={<Checkbox checked={selectAllChecked} />}
                    />
                  </MenuItem>
                  {filteredProducts?.map((product) => (
                    <MenuItem
                      key={product.id}
                      value={product.id}
                      style={getStyles(
                        product.name,
                        formik.values.product_ids,
                        theme
                      )}
                    >
                      <FormControlLabel
                        label={
                          product.name + " - " + product.product_group_name
                        }
                        control={
                          <Checkbox
                            checked={
                              formik.values.product_ids.findIndex(
                                (p: any) => p === product.id
                              ) >= 0
                            }
                          />
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        {alertShow && <Alert severity="error">{alertMessage}</Alert>}
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPromoModal;
