import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";

type ErrorPopupProps = {
    show: boolean;
    submits: number;
    onClose: () => void;
    onOverride: () => void;
}

const ErrorPopup = (props: ErrorPopupProps) => {
    const closePopup = () => {
        props.onClose();
    }

    const overrideErrors = () => {
        props.onOverride();
    }

    return (
        <Dialog 
            open={props.show}
            maxWidth='xs'
            fullWidth={true}
            PaperProps={{sx:{border: 3, borderColor:'error.main'}}}
        >
            <DialogTitle sx={{fontWeight:'bold', color:'error.main'}}>Error</DialogTitle>

            <DialogContent>
                <Typography sx={{fontWeight:'bold'}}>
                    Some values fall outside accepted range.
                </Typography>
                {props.submits === 1 && (
                    <Typography>
                        Please review highlighted cells.
                    </Typography>
                )}
                <br />
                {props.submits === 1 && (
                    <Typography>
                        Saving as "Complete" or "Complete and Send" will override errors and report will be sent.
                    </Typography>
                )}
                {props.submits > 1 && (
                    <Typography>
                        Some values are still outside normal range. Do you want to save anyway?
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                {props.submits > 1 && (
                    <Button sx={{width:'10rem'}} variant="text" onClick={closePopup}>
                        Cancel
                    </Button>
                )}
                {props.submits > 1 && (
                    <Button sx={{width:'10rem'}} variant="contained" onClick={overrideErrors}>
                        Save
                    </Button>
                )}
                {props.submits === 1 && (
                    <Button sx={{width:'10rem'}} variant="contained" onClick={closePopup}>
                        Review Errors
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ErrorPopup;