import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { isNull } from "lodash";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormTemplateType } from "../../../../redux/features/forms/types";
import { useGetFormTemplateQuery } from "../../../../redux/features/forms/formTemplatesApi";
import { APP } from "../../../../_shared/utils/_urls";
import FormInfo from "../../../../_shared/components/FormInfo";

const FormTemplateDetailPage = () => {
  const [showEditFormTemplateModal, setShowEditFormTemplateModal] =
    useState(false);
  const [showDeleteFormTemplateModal, setShowDeleteFormTemplateModal] =
    useState(false);
  const [formTemplate, setFormTemplate] = useState<FormTemplateType>();
  const params = useParams();
  const navigate = useNavigate();
  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetFormTemplateQuery(params.form_template_id ?? skipToken);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditFormTemplateModal(true);
  };

  const onDoneEditing = (reload: boolean) => {
    setShowEditFormTemplateModal(false);
    if (reload) {
      refetch();
    }
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeleteFormTemplateModal(true);
  };

  const onDoneDeleting = (show: boolean) => {
    setShowDeleteFormTemplateModal(show);
  };

  useEffect(() => {
    if (isSuccess) {
      setFormTemplate(data);
    }
  }, [data]);

  if (formTemplate !== undefined) {
    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Breadcrumbs>
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    navigate(`${APP.FORMTEMPLATES}`);
                  }}
                >
                  Templates
                </Link>
                <Typography color="text.primary">
                  {formTemplate?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Stack direction="row">
                <Button variant="text" color="secondary" onClick={onEdit}>
                  Edit
                </Button>
                <Button variant="text" color="secondary" onClick={onDelete}>
                  Delete
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4">{formTemplate?.name}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            ></Grid>
          </Grid>
          <hr />
          <Stack spacing={2} sx={{ my: 3 }}>
            <FormInfo label="Form Type" value={formTemplate.form_type} />
            <FormInfo label="Template Id" value={formTemplate.template_id} />
            <FormInfo
              label="Replace"
              value={formTemplate.replace ? "Yes" : "No"}
            />
          </Stack>
        </Container>
        {/* <UpdatePartnerModal
          partnerToUpdate={partner as PartnerType}
          doneEditing={onDoneEditing}
          show={showEditProfileModal}
        />
        <DeletePartnerModal
          partnerToDelete={partner as PartnerType}
          doneDeleting={onDoneDeleting}
          show={showDeletePartnerModal}
        /> */}
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default FormTemplateDetailPage;
