export const APP = {
  INDEX: "/",
  LOGIN: "/auth/login",
  VERIFY_LOGIN: "/auth/verify-login",
  FORGOT_PASSWORD: "/auth/reset-password",
  DASHBOARD: "/dashboard",
  CUSTOMERS: "/customers",
  PARTNERS: "/partners",
  APPOINTMENTS: "/appointments",
  PROFILE: "/profile",
  PRODUCTS: "/commerce/products",
  PRODUCT_GROUPS: "/commerce/products/groups",
  PROMO_CODES: "/commerce/promo-codes",
  GIFT_CERTIFICATES: "/commerce/gift-certificates",
  ORDERS: "/commerce/orders",
  FORMTEMPLATES: "/forms/templates",
  SETTINGS: "/settings",
  UNAUTHORIZED: "/errors/401",
};

export const API = {
  LOGIN: "/auth/login",
  UPDATE_PASSWORD: "/auth/update-password",
  CHANGE_PASSWORD: "/auth/change-password",
  USERS: "/users",
  PARTNERS: "/partners",
  PRODUCT_GROUPS: "/products/groups",
  SCHEDULING: "/scheduling",
};
