import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  GetGiftCertificateResultType,
  GetGiftCertificatesResultType,
  GiftCertificateType,
  UpdateGiftCertificateDataResultType,
  UpdateGiftCertificateResultType,
  CreateGiftCertificateResultType,
  CreateGiftCertificateDataResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const GiftCertificatesApi = createApi({
  reducerPath: "GiftCertificatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getGiftCertificates: builder.query<GiftCertificateType[], {}>({
      query() {
        return {
          url: "gift-certificates",
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result: GetGiftCertificatesResultType) => {
        return result.data;
      },
    }),
    getGiftCertificate: builder.query<GiftCertificateType, string>({
      query(gift_certificate_id) {
        return {
          url: `gift-certificates/${gift_certificate_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetGiftCertificateResultType) => {
        return result.data;
      },
    }),
    updateGiftCertificate: builder.mutation<
      UpdateGiftCertificateDataResultType,
      Partial<GiftCertificateType> & Pick<GiftCertificateType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `gift-certificates/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: UpdateGiftCertificateResultType) => {
        return result.data;
      },
    }),
    createGiftCertificate: builder.mutation<
      CreateGiftCertificateDataResultType,
      Partial<GiftCertificateType>
    >({
      query(data) {
        return {
          url: `gift-certificates`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: CreateGiftCertificateResultType) => {
        return result.data;
      },
    }),
    deleteGiftCertificate: builder.mutation<boolean, string>({
      query(gift_certificate_id) {
        return {
          url: `gift-certificates/${gift_certificate_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetGiftCertificatesQuery,
  useGetGiftCertificateQuery,
  useCreateGiftCertificateMutation,
  useDeleteGiftCertificateMutation,
  useUpdateGiftCertificateMutation,
} = GiftCertificatesApi;
