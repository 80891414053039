import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreateCustomerMutation,
  useGetCustomersQuery,
} from "../../../../redux/features/customers/customersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { CustomerType } from "../../../../redux/features/customers/types";
import { APP } from "../../../../_shared/utils/_urls";
import {
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../../_shared/utils/apiHelpers";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type AddPartnerProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

const AddPartnerModal = (props: AddPartnerProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const { refetch } = useGetCustomersQuery({});
  const [createCustomer, { isLoading, isError, error }] =
    useCreateCustomerMutation();
  const [customerToAdd, setcustomerToAdd] = useState<
    CustomerType | Record<string, any>
  >();

  const submitHandler = (values: any) => {
    const payload = merge({}, customerToAdd, values);
    createCustomer(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          refetch();
          navigate(`${APP.CUSTOMERS}/${result.createdUser.id}`);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_type: "Customer",
      role: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new customer</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(
                formik.touched.first_name && formik.errors.first_name
              )}
              fullWidth
              label="First Name"
              name="first_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.first_name}
            />
            <TextField
              error={Boolean(
                formik.touched.last_name && formik.errors.last_name
              )}
              fullWidth
              label="Last Name"
              name="last_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.last_name}
            />
            <TextField
              error={Boolean(
                formik.touched.phone_number && formik.errors.phone_number
              )}
              fullWidth
              label="Phone"
              name="phone_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.phone_number}
            />
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              label="Email"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.email}
            />
            <FormControl fullWidth required>
              <InputLabel id="user_type">Type</InputLabel>
              <Select
                labelId="user_type"
                name="user_type"
                label="Type"
                value={formik.values.user_type}
                error={Boolean(
                  formik.touched?.user_type && formik.errors?.user_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={"Customer"}>Customer</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
                <MenuItem value={"Partner"}>Partner</MenuItem>
              </Select>
            </FormControl>
            {formik.values.user_type === "Admin" && (
              <FormControl fullWidth required>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  labelId="role"
                  name="role"
                  label="Role"
                  value={formik.values.role}
                  error={Boolean(formik.touched?.role && formik.errors?.role)}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"USER"}>User</MenuItem>
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                  <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPartnerModal;
