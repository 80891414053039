import {
  CustomerProfileType,
  CustomerType,
} from "../../../../../redux/features/customers/types";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "../../../../../redux/features/customers/customersApi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { isNull, merge } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";

type UpdateCustomerProps = {
  show: boolean;
  customerToUpdate: CustomerType | Record<string, any>;
  doneEditing: (reload: boolean) => void;
};

const UpdateCustomerModal = (props: UpdateCustomerProps) => {
  const handleClose = () => {
    props.doneEditing(false);
  };
  const [customerToUpdate, setCustomerToUpdate] = useState<
    CustomerType | Record<string, any>
  >();
  const [updateCustomer, { isLoading }] = useUpdateCustomerMutation();

  const submitHandler = (values: any) => {
    const payload = merge({}, customerToUpdate, values);
    updateCustomer(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: customerToUpdate?.first_name || "",
      last_name: customerToUpdate?.last_name || "",
      email: customerToUpdate?.email || "",
      phone_number: customerToUpdate?.phone_number || "",
      user_type: isNull(customerToUpdate?.user_type)
        ? "Customer"
        : customerToUpdate?.user_type,
      role:
        customerToUpdate?.user_type !== "Admin" ? null : customerToUpdate?.role,
      customer_profile: {
        gender: customerToUpdate?.customer_profile?.gender || "",
        address_line_1:
          customerToUpdate?.customer_profile?.address_line_1 || "",
        address_line_2:
          customerToUpdate?.customer_profile?.address_line_2 || "",
        city: customerToUpdate?.customer_profile?.city || "",
        state: customerToUpdate?.customer_profile?.state || "",
        postal_code: customerToUpdate?.customer_profile?.postal_code || "",
        dob: customerToUpdate?.customer_profile?.dob
          ? new Date(formatDateTime(customerToUpdate?.customer_profile?.dob))
          : "",
      },
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setCustomerToUpdate(props.customerToUpdate);
  }, [props.customerToUpdate]);

  if (formik !== undefined) {
    return (
      <Dialog open={props.show} onClose={handleClose}>
        <DialogTitle>Update Customer Information</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2} sx={{ p: 3 }}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  name="first_name"
                  required
                  error={Boolean(
                    formik.touched.first_name && formik.errors.first_name
                  )}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.last_name && formik.errors.last_name
                  )}
                  fullWidth
                  label="Last name"
                  name="last_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.last_name}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  required
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.phone_number && formik.errors.phone_number
                  )}
                  fullWidth
                  label="Phone number"
                  name="phone_number"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="genderAtBirthLabel">
                    Gender at birth
                  </InputLabel>
                  <Select
                    labelId="genderAtBirthLabel"
                    name="customer_profile.gender"
                    label="Gender at birth"
                    value={formik.values.customer_profile?.gender}
                    error={Boolean(
                      formik.touched?.customer_profile?.gender &&
                        formik.errors?.customer_profile?.gender
                    )}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="editCustomerType">Customer Type</InputLabel>
                  <Select
                    labelId="editCustomerType"
                    name="user_type"
                    label="Customer Type"
                    value={formik.values.user_type}
                    error={Boolean(
                      formik.touched.user_type && formik.errors.user_type
                    )}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"Customer"}>Customer</MenuItem>
                    <MenuItem value={"Admin"}>Admin</MenuItem>
                    <MenuItem value={"Partner"}>Partner</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                {formik.values.user_type === "Admin" && (
                  <FormControl fullWidth required>
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      labelId="role"
                      name="role"
                      label="Role"
                      value={formik.values.role}
                      error={Boolean(
                        formik.touched?.role && formik.errors?.role
                      )}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={"USER"}>Customer</MenuItem>
                      <MenuItem value={"ADMIN"}>Admin</MenuItem>
                      <MenuItem value={"SUPERADMIN"}>Super Admin</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched?.customer_profile?.address_line_1 &&
                      formik.errors?.customer_profile?.address_line_1
                  )}
                  fullWidth
                  // helperText={
                  //   formik.touched.address_line_1 && formik.errors.address_line_1
                  // }
                  label="Address 1"
                  name="customer_profile.address_line_1"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customer_profile?.address_line_1}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  error={Boolean(
                    formik.touched.customer_profile?.address_line_2 &&
                      formik.errors.customer_profile?.address_line_2
                  )}
                  fullWidth
                  //helperText={
                  //   formik.touched.address_line_2 && formik.errors.address_line_2
                  // }
                  label="Address 2"
                  name="customer_profile.address_line_2"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customer_profile?.address_line_2}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.customer_profile?.city &&
                      formik.errors.customer_profile?.city
                  )}
                  fullWidth
                  //helperText={formik.touched.city && formik.errors.city}
                  label="City"
                  name="customer_profile.city"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customer_profile?.city}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.customer_profile?.state &&
                      formik.errors.customer_profile?.state
                  )}
                  fullWidth
                  //helperText={formik.touched.state && formik.errors.state}
                  label="State"
                  name="customer_profile.state"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customer_profile?.state}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(
                    formik.touched.customer_profile?.postal_code &&
                      formik.errors.customer_profile?.postal_code
                  )}
                  fullWidth
                  // helperText={
                  //   formik.touched.customer_profile?.postal_code &&
                  //   formik.errors.customer_profile?.postal_code
                  // }
                  label="Postal Code"
                  name="customer_profile.postal_code"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.customer_profile?.postal_code}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of birth"
                    value={formik.values.customer_profile.dob}
                    onChange={(val: any) => {
                      formik.setFieldValue("customer_profile.dob", val);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(
                          formik.touched.customer_profile?.dob &&
                            formik.errors.customer_profile?.dob
                        ),
                        helperText: `${
                          formik.touched.customer_profile?.dob &&
                          formik.errors.customer_profile?.dob
                        }`,
                        required: true,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              disabled={isLoading}
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  } else {
    return <p>There was an issue loading the record.</p>;
  }
};

export default UpdateCustomerModal;
