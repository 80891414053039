import { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
  getErrorMessage,
} from "../../../../../_shared/utils/apiHelpers";
import {
  Alert,
  Box,
  Paper,
  Divider,
  TextField,
  FormControl,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { APP } from "../../../../../_shared/utils/_urls";
import { useVerifyLoginUserMutation } from "../../../../../redux/features/auth/authApi";
import {
  LoginType,
  VerifyLoginType,
} from "../../../../../redux/features/auth/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

type VerifyLoginFormProps = {
  email: string;
  password: string;
  hash: string | null | undefined;
};

const LoginSchema = Yup.object().shape({
  verification_code: Yup.string().required("Verification Code is required"),
});

const VerifyLoginForm = (props: VerifyLoginFormProps) => {
  const [verifyLogin, { isLoading, isError, error, isSuccess }] =
    useVerifyLoginUserMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      verification_code: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) =>
      verifyLogin({
        email: props.email,
        password: props.password,
        hash: props.hash,
        verification_code: values.verification_code,
      } as VerifyLoginType),
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(APP.APPOINTMENTS);
    }
    if (isError) {
      console.log(isErrorWithMessage(error));
      console.log(error);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Paper sx={{ width: 300, backgroundColor: "#ffffff" }}>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Box sx={{ p: 2 }}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Typography variant="body1">
                  Type in the verification code you received in your email.
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="verification_code"
                  label="Verification Code"
                  type="text"
                  autoFocus
                  error={Boolean(
                    touched.verification_code && errors.verification_code
                  )}
                  helperText={
                    touched.verification_code && errors.verification_code
                  }
                  {...getFieldProps("verification_code")}
                />
                <LoadingButton
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  fullWidth={true}
                  sx={{ mt: 2 }}
                  loading={isLoading}
                >
                  {isLoading ? "Processing in..." : "Log in"}
                </LoadingButton>
              </Stack>
            </Form>
          </Box>
        </Paper>
      </Box>
    </FormikProvider>
  );
};

export default VerifyLoginForm;
