import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetPreviewAvailabilityResultType,
  PreviewAvailabilityResultType,
} from "./types";

import { API } from "../../../_shared/utils/_urls";
import { store } from "../../store";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const schedulingApi = createApi({
  reducerPath: "schedulingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getPreview: builder.query<PreviewAvailabilityResultType, string>({
      query(partner_test_id) {
        let url = `${API.SCHEDULING}/partner-test/${partner_test_id}/preview`;
        return {
          url: url,
          method: "GET",
        };
      },
      transformResponse: (result: GetPreviewAvailabilityResultType) => {
        return result.data;
      },
    }),
  }),
});

export const { useGetPreviewQuery } = schedulingApi;
