import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { isConstructorDeclaration } from "typescript";
import { object } from "yup";
import { store } from "../../store";
import {
  GetPartnersResultType,
  GetPartnerResultType,
  PartnerType,
  CreatePartnerResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const partnersApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPartners: builder.query<PartnerType[], {}>({
      query() {
        return {
          url: "partners",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetPartnersResultType) => {
        return result.data;
      },
    }),
    getPartner: builder.query<PartnerType, string>({
      query(partner_id) {
        return {
          url: `partners/${partner_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetPartnerResultType) => {
        return result.data;
      },
    }),
    updatePartner: builder.mutation<
      PartnerType,
      Partial<PartnerType> & Pick<PartnerType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `partners/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetPartnerResultType) => {
        return result.data;
      },
    }),
    createPartner: builder.mutation<
      CreatePartnerResultType,
      Partial<PartnerType>
    >({
      query(data) {
        return {
          url: `partners`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreatePartnerResultType => {
        return result.data;
      },
    }),
    deletePartner: builder.mutation<boolean, string>({
      query(partner_id) {
        return {
          url: `partners/${partner_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetPartnersQuery,
  useGetPartnerQuery,
  useUpdatePartnerMutation,
  useDeletePartnerMutation,
  useCreatePartnerMutation,
} = partnersApi;
