import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetAllProductTestsByProductQuery } from "../../../../../../../../../redux/features/commerce/products/productTestsApi";
import { formatDateTime } from "../../../../../../../../../_shared/utils/uiHelpers";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridActionsCellItem,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { ProductTestType } from "../../../../../../../../../redux/features/commerce/products/types";
import React from "react";
import _ from "lodash";
import { PartnerType } from "../../../../../../../../../redux/features/partners/types";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { Box, Button, Typography } from "@mui/material";
import UpdateProductTestModal from "../ProductTestUpdate";
import DeleteProductTestModal from "../ProductTestDelete";

type ProductTestGridProps = {
  product_id: string | null;
  refetch: boolean;
  doneRefetching: () => void;
};

const ProductTestsGrid = (props: ProductTestGridProps) => {
  const [productTests, setProductTests] = useState<ProductTestType[]>([]);
  const [showEditProductTestModal, setShowEditProductTestModal] =
    useState(false);
  const [showDeleteProductTestModal, setShowDeleteProductTestModal] =
    useState(false);
  const [productTestToEdit, setProductTestToEdit] = useState<
    ProductTestType | null | undefined | any
  >();

  const testTypes = [
    { value: "DEXA", label: "DEXA" },
    { value: "HYDROSTATIC", label: "Hydrostatic" },
    { value: "RMR", label: "RMR" },
    { value: "SECA", label: "Seca" },
    { value: "VO2", label: "VO2 Max" },
    { value: "BODPOD", label: "Bod Pod" },
  ];

  const onDoneEditProductTest = (reload: boolean) => {
    setShowEditProductTestModal(false);
    setProductTestToEdit(null);
    if (reload) refetch();
  };

  const handleEditClick = (product_test: any) => () => {
    setProductTestToEdit(product_test);
    setShowEditProductTestModal(true);
  };
  const onDoneDeletingProductTest = (reload: boolean) => {
    setShowDeleteProductTestModal(false);
    setProductTestToEdit(null);
    if (reload) refetch();
  };

  const handleDeleteClick = (product_test: any) => () => {
    setProductTestToEdit(product_test);
    setShowDeleteProductTestModal(true);
  };

  const columns: GridColDef[] = [
    {
      field: "test_type",
      headerName: "Test",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      align: "left",
      headerAlign: "left",
      minWidth: 200,
    },
    {
      field: "partners",
      headerName: "Partners",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Box sx={{ width: "100%", overflow: "auto" }}>
            {_.map(params.row.partners, (p: PartnerType) => {
              return (
                <Typography variant="overline" sx={{ m: 1 }}>
                  {p.name}
                </Typography>
              );
            })}
          </Box>
        </React.Fragment>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetAllProductTestsByProductQuery(props.product_id || skipToken);

  useEffect(() => {
    if (isSuccess) {
      setProductTests(data);
    }
  });

  useEffect(() => {
    if (props.refetch) {
      refetch();
      props.doneRefetching();
    }
  }, [props.refetch]);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={productTests}
          columns={columns}
          loading={isFetching}
        />
      </div>
      <UpdateProductTestModal
        show={showEditProductTestModal}
        doneEditing={onDoneEditProductTest}
        productTestToUpdate={productTestToEdit}
      />
      <DeleteProductTestModal
        show={showDeleteProductTestModal}
        doneDeleting={onDoneDeletingProductTest}
        productTestToDelete={productTestToEdit}
      />
    </div>
  );
};

export default ProductTestsGrid;
