import { Stack, Typography, Link } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface FormLinkProps {
  label: string | undefined | null | "";
  value: string | undefined | null | ReactNode | "";
  route: string;
  id: string | undefined | null;
  state?: object | null
}

const FormLink = (props: FormLinkProps) => {
    const navigate = useNavigate();
    return (
        <Stack direction="row" spacing={2} sx={{ my: 1 }}>
            <Typography fontWeight="bold">{props.label}</Typography>
            {props.id && 
            <Typography>
                <Link
                    underline="hover"
                    color="secondary"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        props.route.startsWith('https://') ? window.open(`${props.route}/${props.id}`, "_blank") :
                            props.state ? navigate(`${props.route}/${props.id}`, {state: props.state}) : navigate(`${props.route}/${props.id}`);
                    }}
                >
                    {props.value}
                </Link>
            </Typography>
            }
        </Stack>
    );
};

export default FormLink;