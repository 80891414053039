import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CustomerType } from "../../../../../redux/features/customers/types";
import { useResetCustomerPasswordMutation } from "../../../../../redux/features/customers/customersApi";
import { APP } from "../../../../../_shared/utils/_urls";
import { useNavigate } from "react-router-dom";

type ResetPasswordProps = {
  show: boolean;
  customerToResetPassword: CustomerType | Record<string, any>;
  doneResettingPassword: (reload: boolean) => void;
};

const ResetPasswordModal = (props: ResetPasswordProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneResettingPassword(false);
  };
  const [resetPassword, { isLoading }] = useResetCustomerPasswordMutation();

  const resetHandler = () => {
    resetPassword(props.customerToResetPassword.id)
      .unwrap()
      .then((result) => {
        alert("Reset Password link sent to customer.");
        props.doneResettingPassword(false);
      });
  };

  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to reset the customer{" "}
        {props.customerToResetPassword?.first_name}{" "}
        {props.customerToResetPassword?.last_name} password?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={resetHandler}
          loading={isLoading}
        >
          Send Reset Password Link
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordModal;
