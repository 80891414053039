import { Container } from "@mui/material";
import Box from "@mui/material/Box";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          borderTop: 1,
          width: "100%",
          borderColor: "grey.500",
          px: 3,
          py: 1,
        }}
      >
        <p>&copy; 2024 Fitnescity</p>
      </Box>
    </Box>
  );
};

export default Footer;
