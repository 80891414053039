import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import _, { merge } from "lodash";
import { APP } from "../../../../../_shared/utils/_urls";
import {
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../../../_shared/utils/apiHelpers";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  useCreateGiftCertificateMutation,
  useGetGiftCertificatesQuery,
} from "../../../../../redux/features/commerce/gift-certificates/giftCertificatesApi";
import { useGetAllProductsQuery } from "../../../../../redux/features/commerce/products/productsApi";
import { useGetTermsQuery } from "../../../../../redux/features/commerce/terms/termsApi";
import { GiftCertificateType } from "../../../../../redux/features/commerce/gift-certificates/types";
import { ProductType } from "../../../../../redux/features/commerce/products/types";
import { theme } from "../../../../../theme";
import { TermType } from "../../../../../redux/features/commerce/terms/types";

type AddGiftCertificateProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (
  name: string,
  personName: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const AddGiftCertificateModal = (props: AddGiftCertificateProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const { refetch } = useGetGiftCertificatesQuery({});
  const { data = [], isSuccess } = useGetAllProductsQuery({});
  const [terms, setTerms] = useState<{ id: string; label: string }[]>([]);
  const [createGiftCertificate, { isLoading, isError, error }] =
    useCreateGiftCertificateMutation();
  const [giftCertificateToAdd, setGiftCertificateToAdd] = useState<
    GiftCertificateType | Record<string, any>
  >();
  const [products, setProducts] = useState<ProductType[]>();

  useEffect(() => {
    if (isSuccess) {
      setProducts(data);
    }
  }, [data]);

  const submitHandler = (values: any) => {
    const payload = merge({}, giftCertificateToAdd, values);
    createGiftCertificate(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          refetch();
          navigate(
            `${APP.GIFT_CERTIFICATES}/${result.createdGiftCertificate.id}`
          );
        }
      });
  };

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const { data: termsData, isSuccess: isTermsSuccess } = useGetTermsQuery({});
  useEffect(() => {
    if (isTermsSuccess) {
      setTerms(
        termsData.map((t: TermType) => {
          return { label: t.name, id: t.id };
        })
      );
    }
  }, [termsData]);

  const handleProductChange = (
    event: SelectChangeEvent<typeof formik.values.products>
  ) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "products",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      price: null,
      terms_id: "",
      is_active: true,
      products: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
      price: Yup.number().required("Price is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new Promo Code</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.name}
            />
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              label="Description"
              multiline
              rows={3}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <TextField
              error={Boolean(formik.touched.price && formik.errors.price)}
              fullWidth
              label="Price"
              name="price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <FormControl>
              <InputLabel id="product_list_label">Products</InputLabel>
              <Select
                labelId="product_list_label"
                id="products"
                multiple
                value={formik.values.products}
                onChange={handleProductChange}
                input={<OutlinedInput id="products" label="Products" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {products?.map((product: any) => {
                      if (
                        selected.findIndex((s: any) => s === product.id) >= 0
                      ) {
                        return (
                          <Chip
                            key={product.id}
                            label={product.name}
                            sx={{ backgroundColor: product.color }}
                          />
                        );
                      }
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {products?.map((product) => (
                  <MenuItem
                    key={product.id}
                    value={product.id}
                    style={getStyles(
                      product.name,
                      formik.values.products,
                      theme
                    )}
                  >
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>For All Products Leave Blank</FormHelperText>
            </FormControl>
            <Autocomplete
              disablePortal
              id="term-combo"
              options={terms}
              onChange={(
                event: any,
                newValue: { id: string; label: string } | null
              ) => {
                formik.setFieldValue("terms_id", newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} required label="Term" />
              )}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_active}
                  onChange={handleIsActive}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddGiftCertificateModal;
