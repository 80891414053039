import { createTheme } from '@mui/material/styles';

export const theme = createTheme({  
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 940,
      lg: 1200,
      xl: 1536,
    }    
  },
  palette: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF'
    },
    primary: {
      main: '#262E3F'
    },
    secondary: {
      main: '#B8A8B6'
    },
    grey: {
      50: '#E4ECF3'
    }
  },
  typography: {
    fontFamily: 'Avenir, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: 18
    },
    body2: {
      fontSize: 18
    }, 
    h1: {
      fontSize: 34,
      fontWeight: 'bold'
    },
    h2: {
      fontSize: 30,
      fontWeight: 'bold',
      color: '#9D7E93'
    },
    h3: {
      fontSize: 30
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 'bold',
      color: '#9D7E93'
    }  
  },
  
});