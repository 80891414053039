import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeleteProductMutation } from "../../../../../../../../../redux/features/commerce/products/productsApi";
import { ProductType } from "../../../../../../../../../redux/features/commerce/products/types";

type DeleteProductGroupProps = {
  show: boolean;
  productToDelete: ProductType | Record<string, any> | undefined;

  doneDeleting: (reload: boolean) => void;
};

const DeleteProductModal = (props: DeleteProductGroupProps) => {
  const handleClose = () => {
    props.doneDeleting(false);
  };
  const [deleteProduct, { isLoading }] = useDeleteProductMutation();

  const submitHandler = (e: any) => {
    e.preventDefault();
    deleteProduct(props.productToDelete?.id)
      .unwrap()
      .then((result) => {
        if (result) {
          props.doneDeleting(true);
        }
      });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>
        {`Delete the product ${props.productToDelete?.name}?`}
      </DialogTitle>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the product{" "}
            {props.productToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Delete
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteProductModal;
