import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import ProductGroupsGrid from "./components/ProductGroupGrid";
import AddProductGroupModal from "./components/ProductGroupAdd";

const ProductsPage = () => {
  const [showAddProductGroupModal, setShowAddProductGroupModal] =
    useState(false);
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddProductGroupModal(true);
  };

  const onDoneAdding = () => {
    setShowAddProductGroupModal(false);
  };

  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs sx={{ mt: 1 }}>
            <Typography color="text.primary">Commerce</Typography>
            <Typography color="text.primary">Products</Typography>
            <Typography color="text.primary">Groups</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="text" color="secondary" onClick={onAdd}>
            + Add
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <ProductGroupsGrid showInActive={true} />
      </Stack>
      <AddProductGroupModal
        doneAdding={onDoneAdding}
        show={showAddProductGroupModal}
      />
    </React.Fragment>
  );
};

export default ProductsPage;
