import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import {
  isFetchBaseQueryError,
  getErrorMessage,
} from "../../../../../_shared/utils/apiHelpers";
import { Alert, Box, Paper, TextField, FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { APP } from "../../../../../_shared/utils/_urls";
import { useLoginUserMutation } from "../../../../../redux/features/auth/authApi";
import { LoginType } from "../../../../../redux/features/auth/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const [loginUser, { isLoading, isError, error }] = useLoginUserMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) =>
      loginUser({
        email: values.email,
        password: values.password,
      } as LoginType)
        .unwrap()
        .then((result) => {
          if (result.success) {
            navigate(`${APP.VERIFY_LOGIN}/${result.hash}`, {
              state: {
                email: values.email,
                password: values.password,
              },
            });
          }
        }),
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Paper sx={{ width: 300, backgroundColor: "#ffffff" }}>
          {isError && isFetchBaseQueryError(error) && (
            <Alert severity="error">
              {getErrorMessage(error as FetchBaseQueryError)}
            </Alert>
          )}
          <Box sx={{ p: 2 }}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                type="email"
                autoFocus
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps("email")}
              />
              <FormControl variant="outlined" fullWidth required>
                <TextField
                  id="password"
                  type="password"
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  {...getFieldProps("password")}
                  label="Password"
                />
              </FormControl>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={isLoading}
                fullWidth={true}
                sx={{ mt: 2 }}
                loading={isLoading}
              >
                {isLoading ? "Processing in..." : "Log in"}
              </LoadingButton>
            </Form>
          </Box>
        </Paper>
      </Box>
    </FormikProvider>
  );
};

export default LoginForm;
