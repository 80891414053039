import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetIssuesQuery } from "../../../../redux/features/tests/testsApi";
import { IssueType } from "../../../../redux/features/tests/types";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../../_shared/utils/uiHelpers";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link } from "@mui/material";
import { APP } from "../../../../_shared/utils/_urls";

const IssuesGrid = () => {
  const navigate = useNavigate();
  const [issues, setIssues] = useState<IssueType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "appointment_date",
      headerName: "Appointment",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          onClick={() => {
            navigate(`${APP.APPOINTMENTS}/${params.row.id}`);
          }}
        >
          {formatDateTime(params.row.appointment_date)}
        </Link>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "test_type",
      headerName: "Test",
      flex: 1,
      valueFormatter: (params) => {
        return params.value === null ? "Customer" : params.value;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return params.value === "CompleteAndSend"
          ? "Complete and Sent"
          : params.value;
      },
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
    },
  ];
  const { data = [], isFetching, isSuccess, refetch } = useGetIssuesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setIssues(data);
    }
  });

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={issues}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default IssuesGrid;
