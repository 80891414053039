import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDeleteGiftCertificateMutation } from "../../../../../../redux/features/commerce/gift-certificates/giftCertificatesApi";
import { APP } from "../../../../../../_shared/utils/_urls";
import { useNavigate } from "react-router-dom";
import { GiftCertificateType } from "../../../../../../redux/features/commerce/gift-certificates/types";

type DeleteGiftCertificateProps = {
  show: boolean;
  giftCertificateToDelete: GiftCertificateType | Record<string, any>;
  doneDeleting: (reload: boolean) => void;
};

const DeleteGiftCertificateModal = (props: DeleteGiftCertificateProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneDeleting(false);
  };
  const [deleteUser, { isLoading }] = useDeleteGiftCertificateMutation();

  const deleteHandler = () => {
    deleteUser(props.giftCertificateToDelete.id)
      .unwrap()
      .then(() => {
        navigate(APP.GIFT_CERTIFICATES);
      });
  };

  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to delete the gift certificate{" "}
        {props.giftCertificateToDelete?.name}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={deleteHandler}
          loading={isLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGiftCertificateModal;
