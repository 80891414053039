import { Box, Container, CssBaseline } from "@mui/material";
import { ReactNode } from "react";
import styles from "./authLayout.module.css";
import Footer from "../../components/footer";

interface AuthLayoutProp {
  children: ReactNode | ReactNode[];
}

const AuthLayout = ({ children }: AuthLayoutProp) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#262e3f",
      }}
    >
      <CssBaseline />
      <Container className={styles.auth_wrapper}>
        <img
          className={styles.logo}
          src="../../img/logo.png"
          title="fitnescity logo"
        />
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default AuthLayout;
