import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { CustomerType } from "../../../../../redux/features/customers/types";
import { useDeleteCustomerMutation } from "../../../../../redux/features/customers/customersApi";
import { APP } from "../../../../../_shared/utils/_urls";
import { useNavigate } from "react-router-dom";

type DeleteCustomerProps = {
  show: boolean;
  customerToDelete: CustomerType | Record<string, any>;
  doneDeleting: (reload: boolean) => void;
};

const DeleteCustomerModal = (props: DeleteCustomerProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneDeleting(false);
  };
  const [deleteCustomer, { isLoading }] = useDeleteCustomerMutation();

  const deleteHandler = () => {
    deleteCustomer(props.customerToDelete.id)
      .unwrap()
      .then((result) => {
        navigate(APP.CUSTOMERS);
      });
  };

  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to delete the customer{" "}
        {props.customerToDelete?.first_name} {props.customerToDelete?.last_name}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={deleteHandler}
          loading={isLoading}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomerModal;
