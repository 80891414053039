import styles from "./sidebar.module.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { logout } from "../../../redux/features/auth/currentUser.slice";
import { APP } from "../../../_shared/utils/_urls";
import { useDispatch } from "react-redux";
import { findIndex } from "lodash";
import { ExpandLess, ExpandMore, MenuTwoTone } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";

interface SidebarProps {
  drawerWidth: number;
  mobileOpen: boolean;
  onToggleMobile: any;
}

const Sidebar = ({ drawerWidth, mobileOpen, onToggleMobile }: SidebarProps) => {
  const [menuToOpen, setMenuToOpen] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );

  const createMenuItems = (menuItems: any[]) => {
    return menuItems.map((mi) => {
      if (
        mi.roles.length == 0 ||
        findIndex(mi.roles, (r) => {
          return r === currentUser?.role;
        }) !== -1
      ) {
        if (mi.children) {
          return (
            <React.Fragment>
              <ListItem className={styles.listItem} key={mi.path}>
                <ListItemButton
                  className={styles.listItemButton}
                  onClick={() => {
                    if (menuToOpen === mi.path) {
                      setMenuToOpen("");
                    } else {
                      setMenuToOpen(mi.path);
                    }
                  }}
                >
                  <ListItemText>{mi.label}</ListItemText>
                  {menuToOpen === mi.path ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={menuToOpen === mi.path}
                timeout="auto"
                unmountOnExit
                className={styles.childrenWrapper}
              >
                <List component="div" disablePadding className="childrenList">
                  {createMenuItems(mi.children)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        } else {
          return (
            <ListItem className={styles.listItem} key={mi.path}>
              <ListItemButton
                className={styles.listItemButton}
                onClick={() => navigate(mi.path)}
              >
                <ListItemText>{mi.label}</ListItemText>
              </ListItemButton>
            </ListItem>
          );
        }
      }
    });
  };
  const pages = [
    {
      label: "Appointments",
      path: APP.APPOINTMENTS,
      roles: [],
    },
    {
      label: "Customers",
      path: APP.CUSTOMERS,
      roles: ["SUPERADMIN"],
    },
    {
      label: "Partners",
      path: APP.PARTNERS,
      roles: ["SUPERADMIN"],
    },
    {
      label: "Commerce",
      path: "commerce",
      roles: ["SUPERADMIN"],
      children: [
        {
          label: "Orders",
          path: APP.ORDERS,
          roles: ["SUPERADMIN"],
        },
        {
          label: "Products",
          path: APP.PRODUCTS,
          roles: ["SUPERADMIN"],
        },
        {
          label: "Promo Codes",
          path: APP.PROMO_CODES,
          roles: ["SUPERADMIN"],
        },
        {
          label: "Gift Certificates",
          path: APP.GIFT_CERTIFICATES,
          roles: ["SUPERADMIN"],
        },
      ],
    },
    {
      label: "Forms",
      path: "forms",
      roles: ["SUPERADMIN"],
      children: [
        {
          label: "Templates",
          path: APP.FORMTEMPLATES,
          roles: ["SUPERADMIN"],
        },
      ],
    },
    {
      label: "Settings",
      path: APP.SETTINGS,
      roles: ["SUPERADMIN"],
    },
    {
      label: "Profile",
      path: APP.PROFILE,
      roles: [],
    },
  ];

  const drawerToggleHandler = () => {
    onToggleMobile();
  };

  const onLogout = (e: any) => {
    e.preventDefault();
    dispatch(logout());
    navigate(APP.LOGIN);
  };

  const drawer = (
    <div>
      <Box
        className={styles.userInfoWrapper}
        sx={{ textAlign: "center", pb: 4 }}
      >
        <h2>
          {currentUser
            ? `${currentUser.first_name} ${currentUser.last_name}`
            : ""}
        </h2>
      </Box>
      <Divider />
      <List>{createMenuItems(pages)}</List>
      <List>
        <ListItem className={styles.listItem}>
          <ListItemButton className={styles.listItemButton} onClick={onLogout}>
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer
        container={document.getElementById("sidebarContainer")}
        variant="temporary"
        open={mobileOpen}
        onClose={drawerToggleHandler}
        ModalProps={{
          keepMounted: true,
        }}
        className={styles.drawerWrapper}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#DFD8DE",
            border: "none",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
