import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  GetProductGroupsResult,
  GetProductGroupResult,
  ProductGroupType,
  CreateProductGroupResult,
  GetWidgetResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const ProductGroupsApi = createApi({
  reducerPath: "ProductGroupApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/products/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProductGroups: builder.query<ProductGroupType[], {}>({
      query() {
        return {
          url: "groups",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetProductGroupsResult) => {
        return result.data;
      },
    }),
    getProductGroupsByPartnerTest: builder.query<ProductGroupType[], string>({
      query(partner_test_id) {
        return {
          url: `partner/groups/${partner_test_id}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result: GetProductGroupsResult) => {
        return result.data;
      },
    }),
    getProductGroup: builder.query<ProductGroupType, string>({
      query(ProductGroup_id) {
        return {
          url: `groups/${ProductGroup_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetProductGroupResult) => {
        return result.data;
      },
    }),
    updateProductGroup: builder.mutation<
      ProductGroupType,
      Partial<ProductGroupType> & Pick<ProductGroupType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `groups/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetProductGroupResult) => {
        return result.data;
      },
    }),
    createProductGroup: builder.mutation<
      CreateProductGroupResult,
      Partial<ProductGroupType>
    >({
      query(data) {
        return {
          url: `groups`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateProductGroupResult => {
        return result.data;
      },
    }),
    deleteProductGroup: builder.mutation<boolean, string>({
      query(product_group_id) {
        return {
          url: `groups/${product_group_id}`,
          method: "DELETE",
        };
      },
    }),
    getWidget: builder.query<GetWidgetResultType, string>({
      query(product_group_id) {
        return {
          url: `groups/${product_group_id}/widget`,
          method: "GET",
        };
      },
      transformResponse: (result: any): GetWidgetResultType => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetProductGroupsQuery,
  useGetProductGroupQuery,
  useGetProductGroupsByPartnerTestQuery,
  useUpdateProductGroupMutation,
  useDeleteProductGroupMutation,
  useCreateProductGroupMutation,
  useGetWidgetQuery,
} = ProductGroupsApi;
