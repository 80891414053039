import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { ProductTestType } from "../../../../../../../../../redux/features/commerce/products/types";
import FormInfo from "../../../../../../../../../_shared/components/FormInfo";
import { useUpdateProductTestMutation } from "../../../../../../../../../redux/features/commerce/products/productTestsApi";
import { PartnerTestType } from "../../../../../../../../../redux/features/partnerTests/types";
import { useGetPartnerTestsByTestTypeQuery } from "../../../../../../../../../redux/features/partnerTests/partnerTestsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";

type EditProductTestProps = {
  show: boolean;
  productTestToUpdate: ProductTestType | null | undefined;
  doneEditing: (reload: boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateProductTestModal = (props: EditProductTestProps) => {
  const [productTestToUpdate, setProductTestToUpdate] = useState<
    ProductTestType | null | undefined
  >(props.productTestToUpdate);
  const [partners, setPartners] = useState<
    PartnerTestType[] | undefined | null
  >([]);
  const handleClose = () => {
    props.doneEditing(false);
  };

  const [updateProductTest, { isLoading }] = useUpdateProductTestMutation();
  const { data: partnerTestData } = useGetPartnerTestsByTestTypeQuery(
    productTestToUpdate?.test_type || skipToken
  );

  useEffect(() => {
    if (partnerTestData) {
      setPartners(partnerTestData);
    }
  }, partnerTestData);

  const submitHandler = (values: any) => {
    const payload = merge({}, productTestToUpdate, values);
    payload.partner_ids = _.map(payload.partners, (p) => {
      return p.partner_id;
    });
    updateProductTest(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: productTestToUpdate?.quantity,
      partners: _.filter(partners, (pt) => {
        return (
          _.findIndex(productTestToUpdate?.partners, (p) => {
            return p.id === pt.partner_id;
          }) !== -1
        );
      }),
    },
    validationSchema: Yup.object({
      quantity: Yup.number().required("Name is required").min(1),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setProductTestToUpdate(props.productTestToUpdate);
  }, [props.productTestToUpdate]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Edit Test</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormInfo
              label="Test Type"
              value={props.productTestToUpdate?.test_type}
            />
            <TextField
              error={Boolean(formik.touched.quantity && formik.errors.quantity)}
              label="Quantity"
              name="quantity"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.quantity}
            />
            <Autocomplete
              multiple
              id="partners"
              options={partners!}
              getOptionLabel={(option) => option!.partner_name}
              getOptionKey={(option) => option!.id}
              noOptionsText="There are no partners for this test type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Partners"
                  placeholder="Partners"
                  required={
                    formik.values.partners !== undefined &&
                    formik.values.partners.length < 1
                  }
                />
              )}
              onChange={(
                event: any,
                newValue: PartnerTestType[] | undefined
              ) => {
                formik.setFieldValue("partners", newValue);
              }}
              value={formik.values.partners}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateProductTestModal;
