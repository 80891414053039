import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../../redux/features/profile/profileApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { ProfileType } from "../../../../redux/features/profile/types";
import { APP } from "../../../../_shared/utils/_urls";

type EditProfileProps = {
  show: boolean;
  profile: ProfileType | Record<string, any> | undefined;
  doneEditing: (reload: boolean) => void;
};

const EditProfileModal = (props: EditProfileProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneEditing(true);
  };
  const { refetch } = useGetProfileQuery({});
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [profileToUpdate, setprofileToUpdate] = useState<
    ProfileType | Record<string, any> | undefined
  >();

  const submitHandler = (values: any) => {
    const payload = merge({}, profileToUpdate, values);
    updateProfile(payload)
      .unwrap()
      .then((result) => {
        if (result.success) {
          props.doneEditing(true);
        } else {
          alert(
            "There was an error updating your profile. Please review your inputs and try again."
          );
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: profileToUpdate?.first_name,
      last_name: profileToUpdate?.last_name,
      email: profileToUpdate?.email,
      phone_number: profileToUpdate?.phone_number,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required").max(255),
      email: Yup.string().required("Email is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setprofileToUpdate(props.profile);
  }, [props.profile]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Edit profile</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(
                formik.touched.first_name && formik.errors.first_name
              )}
              fullWidth
              label="First Name"
              name="first_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.first_name}
            />
            <TextField
              error={Boolean(
                formik.touched.last_name && formik.errors.last_name
              )}
              fullWidth
              label="Last Name"
              name="last_name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.last_name}
            />
            <TextField
              error={Boolean(
                formik.touched.phone_number && formik.errors.phone_number
              )}
              fullWidth
              label="Phone"
              name="phone_number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.phone_number}
            />
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              label="Email"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.email}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProfileModal;
