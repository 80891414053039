import {
    Button,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";

type ProductSearchProps = {
    searchText: string | undefined;
    onUpdateSearchText: (updatedSearchText: string) => void;
};

const ProductSearch = (props: ProductSearchProps) => {
    const clearSearch = () => {
        props.onUpdateSearchText("");
    }

    const updateSearchText = (updatedString: string) => {
        props.onUpdateSearchText(updatedString);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchText(e.target.value);
    };

    return (
        <Stack direction="row" spacing={2}>
            <TextField
                size="small"
                fullWidth
                label="Search"
                autoFocus
                value={props.searchText}
                onChange={handleChange}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
                InputProps={{
                    endAdornment: props.searchText !== undefined && 
                        props.searchText.length > 0 && (
                            <InputAdornment position="end">
                                <Button
                                    variant="text"
                                    sx={{minWidth: "15px", padding: 0, textTransform:"none"}}
                                    onClick={clearSearch}
                                >
                                    X
                                </Button>
                            </InputAdornment>
                        )
                }}
            >
            </TextField>
        </Stack>
    );
};

export default ProductSearch;