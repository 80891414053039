import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PartnersFilter from "./components/PartnersFilter";
import PartnersGrid from "./components/PartnersGrid";
import React, { useState } from "react";
import AddPartnerModal from "./components/PartnersAdd";

const CustomersPage = () => {
  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);
  const [searchText, setSearchtext] = useState<string>();

  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddPartnerModal(true);
  };

  const onDoneAdding = () => {
    setShowAddPartnerModal(false);
  };

  const onUpdateSearchText = (updatedSearchText: string | undefined) => {
    setSearchtext(updatedSearchText);
  };

  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs sx={{ mt: 1 }}>
            <Typography color="text.primary">Partners</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="text" color="secondary" onClick={onAdd}>
            + Add
          </Button>
        </Grid>
      </Grid>

      <Stack spacing={2} sx={{ mt: 2 }}>
        <PartnersFilter
          onUpdateSearchText={onUpdateSearchText}
          searchText={searchText}
        />
        <PartnersGrid searchText={searchText} />
      </Stack>
      <AddPartnerModal doneAdding={onDoneAdding} show={showAddPartnerModal} />
    </React.Fragment>
  );
};

export default CustomersPage;
