import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateAppointmentMutation } from "../../../../../redux/features/tests/testsApi";
import { useGetPartnersQuery } from "../../../../../redux/features/partners/partnersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { PartnerType } from "../../../../../redux/features/partners/types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { formatISO9075 } from "date-fns";
import { TestType } from "../../../../../redux/features/tests/types";
import {
  dateWithoutTimezone,
  formatDateTime,
} from "../../../../../_shared/utils/uiHelpers";
import _ from "lodash";

type UpdateAppointmentProps = {
  show: boolean;
  appointmentToUpdate: TestType;
  doneUpdating: (updatedTest?: TestType) => void;
};

const UpdateAppointmentModal = (props: UpdateAppointmentProps) => {
  const handleClose = () => {
    props.doneUpdating();
  };
  const [updateAppointment, { isLoading }] = useUpdateAppointmentMutation();
  const [partners, setPartners] = useState<{ id: string; label: string }[]>([]);
  const [appointmentToUpdate, setAppointmentToUpdate] = useState<
    TestType | Record<string, any>
  >();

  const submitHandler = (values: any) => {
    console.log(values.appointment_date);
    const payload = {
      appointment_id: props.appointmentToUpdate.id,
      data: merge({}, appointmentToUpdate, values, {
        appointment_date:
          values.appointment_date !== "" && values.appointment_date !== null
            ? formatISO9075(values.appointment_date)
            : null,
      }),
    };

    updateAppointment(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          props.doneUpdating(result.updatedAppointment);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner_id: appointmentToUpdate?.partner_id,
      locked: appointmentToUpdate?.locked,
      appointment_date: appointmentToUpdate?.appointment_date
        ? new Date(formatDateTime(props.appointmentToUpdate.appointment_date))
        : "",
    },
    validationSchema: Yup.object({
      partner_id: Yup.string().required("Partner is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const { data: partnerData, isSuccess: isPartnersSuccess } =
    useGetPartnersQuery({});
  useEffect(() => {
    if (isPartnersSuccess) {
      setPartners(
        partnerData.map((p: PartnerType) => {
          return { label: p.name, id: p.id };
        })
      );
    }
  }, [isPartnersSuccess]);

  useEffect(() => {
    setAppointmentToUpdate(props.appointmentToUpdate);
  }, [props.appointmentToUpdate]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Update Appointment</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <Autocomplete
              disablePortal
              id="partner-combo"
              options={partners}
              onChange={(
                event: any,
                newValue: { id: string; label: string } | null
              ) => {
                formik.setFieldValue("partner_id", newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Partner" />
              )}
              value={_.find(partners, (p: any) => {
                return p.id === formik.values.partner_id;
              })}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Appointment Date"
                value={formik.values.appointment_date}
                onChange={(val: any) => {
                  formik.setFieldValue("appointment_date", val);
                }}
                slotProps={{
                  textField: {
                    error: Boolean(
                      formik.touched.appointment_date &&
                        formik.errors.appointment_date
                    ),
                    helperText:
                      formik.touched.appointment_date &&
                      formik.errors.appointment_date,
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
            {props.appointmentToUpdate.test_type === "VO2" && (
              <FormControl fullWidth required>
                <InputLabel id="locked_label">Locked</InputLabel>
                <Select
                  labelId="locked_label"
                  name="locked"
                  label="Locked"
                  value={formik.values.locked}
                  error={Boolean(
                    formik.touched?.locked && formik.errors?.locked
                  )}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateAppointmentModal;
