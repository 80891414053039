import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  GetCheckoutOrderResult,
  GetCheckoutOrdersResult,
  CheckoutOrderType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const CheckoutOrdersApi = createApi({
  reducerPath: "CheckoutOrdersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrders: builder.query<
      CheckoutOrderType[],
      { statuses: string[] | undefined; startDate: string; endDate: string }
    >({
      query({ statuses, startDate, endDate }) {
        return {
          url: `orders?startDate=${startDate}&endDate=${endDate}&statuses=${statuses}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result: GetCheckoutOrdersResult) => {
        return result.data;
      },
    }),
    getOrder: builder.query<CheckoutOrderType, string>({
      query(order_id) {
        return {
          url: `orders/${order_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetCheckoutOrderResult) => {
        return result.data;
      },
    }),
    resendOrderVerificationEmail: builder.mutation<boolean, string>({
      query(order_id) {
        return {
          url: `orders/${order_id}/resend-verification-email`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useResendOrderVerificationEmailMutation,
} = CheckoutOrdersApi;
