import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HelloSign from "hellosign-embedded";
import { useSearchParams } from "react-router-dom";
import {
  useGetFormToSignQuery,
  useUpdateFormAsSignedMutation,
} from "../../../redux/features/forms/formsApi";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/dist/query";
import {
  getErrorMessage,
  isFetchBaseQueryError,
} from "../../../_shared/utils/apiHelpers";

const FormSignPage = () => {
  let form_id = "";
  const [searchParams, setSearchParams] = useSearchParams();
  const client = new HelloSign();
  const signatureRef = React.createRef<HTMLDivElement>();
  const {
    data = undefined,
    isFetching,
    isError,
    isSuccess,
    error,
  } = useGetFormToSignQuery(
    searchParams.get("signature_request_id") ?? skipToken
  );

  const [updateFormAsSigned, { isLoading: isUpdatingFormAsSigned }] =
    useUpdateFormAsSignedMutation();

  useEffect(() => {
    if (isSuccess) {
      form_id = data?.form.id!;
      console.log(data);
      client.open(data?.form.sign_url!, {
        clientId: "b6bafc774e59314e2b131f38d3745cdd",
        //skipDomainVerification: true,
        allowCancel: true,
        requestingEmail: searchParams.get("email")!,
        container: signatureRef.current!,
      });
    }
  }, [data]);

  client.on("finish", () => {
    if (form_id !== undefined) {
      updateFormAsSigned({ id: form_id });
    }
  });

  return (
    <React.Fragment>
      <Container>
        <Paper sx={{ backgroundColor: "#ffffff", p: 3, m: 3 }}>
          <Box sx={{ height: "calc(100vh - 415px)" }} ref={signatureRef}>
            {isError && isFetchBaseQueryError(error) && (
              <Alert severity="error">
                {getErrorMessage(error as FetchBaseQueryError)}
              </Alert>
            )}
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

export default FormSignPage;
