import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Link,
  Box,
  Chip,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { CheckoutPromoCodeType } from "../../../../redux/features/commerce/promos/types";
import { useGetPromoCodeQuery } from "../../../../redux/features/commerce/promos/promosApi";
import { APP } from "../../../../_shared/utils/_urls";
import DeletePromoModal from "./components/DeletePromoModal";
import FormInfo from "../../../../_shared/components/FormInfo";
import { formatAudit, getShortDate } from "../../../../_shared/utils/uiHelpers";
import UpdatePromoModal from "./components/UpdatePromoModal";
import ProductsList from "./components/ProductsList";

const PromoDetailPage = () => {
  const params = useParams();
  const [promo, setPromo] = useState<CheckoutPromoCodeType>();
  const [showDeletePromoModal, setShowDeletePromoModal] = useState(false);
  const [showEditPromoModal, setShowEditPromoModal] = useState(false);
  const navigate = useNavigate();

  const { data, isSuccess, refetch } = useGetPromoCodeQuery(
    params.promo_id ?? skipToken
  );

  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditPromoModal(true);
  };
  const onDoneEditing = () => {
    refetch();
    setShowEditPromoModal(false);
  };

  const onDelete = (e: any) => {
    e.preventDefault();
    setShowDeletePromoModal(true);
  };
  const onDoneDeleting = () => {
    setShowDeletePromoModal(false);
  };

  useEffect(() => {
    refetch();
    if (isSuccess) {
      setPromo(data);
    }
  }, [data]);

  if (isSuccess)
    return (
      <React.Fragment>
        <Grid container columns={2}>
          <Grid item sm={1}>
            <Breadcrumbs sx={{ mt: 1 }}>
              <Typography color="inherit">Commerce</Typography>
              <Typography color="inherit">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={() => {
                    navigate(`${APP.PROMO_CODES}`);
                  }}
                >
                  Promo Codes
                </Link>
              </Typography>

              <Typography color="text.primary">{promo?.code}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Stack direction="row">
              <Button variant="text" color="secondary" onClick={onEdit}>
                Edit
              </Button>
              <Button variant="text" color="secondary" onClick={onDelete}>
                Delete
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Container maxWidth={false}>
          <Grid container columns={2} spacing={2}>
            <Grid item sm={1}>
              <FormInfo label="Code" value={promo?.code} />
              <FormInfo label="Description" value={promo?.description} />
              <FormInfo label="Discount Type" value={promo?.discount_type} />
              <FormInfo label="Discount Value" value={promo?.discount_value} />
              <FormInfo label="Max Uses" value={promo?.max_uses} />
              <FormInfo label="Use Count" value={promo?.order_count} />
              <FormInfo label="Max Value" value={promo?.max_value} />
              <FormInfo
                label="Expiration Date"
                value={getShortDate(promo?.expiration_date)}
              />
              <FormInfo
                label="Created"
                value={formatAudit(
                  promo?.created_by_name,
                  promo?.created_at,
                  ""
                )}
              />
              <FormInfo
                label="Updated"
                value={formatAudit(
                  promo?.updated_by_name,
                  promo?.updated_at,
                  ""
                )}
              />
            </Grid>
            <Grid item sm={1}>
              {promo?.products == null ? <Chip label="This promo code applies to all product."></Chip> : 
              <ProductsList products={promo?.products} />}
            </Grid>
          </Grid>
        </Container>
        <DeletePromoModal
          promoToDelete={promo as CheckoutPromoCodeType}
          doneDeleting={onDoneDeleting}
          show={showDeletePromoModal}
        />
        <UpdatePromoModal
          promoToUpdate={promo as CheckoutPromoCodeType}
          doneEditing={onDoneEditing}
          show={showEditPromoModal}
        />
      </React.Fragment>
    );
  else return <p>Loading...</p>;
};

export default PromoDetailPage;
