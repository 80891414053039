import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetPartnerTestsQuery } from "../../../../../redux/features/partnerTests/partnerTestsApi";
import { PartnerTestType } from "../../../../../redux/features/partnerTests/types";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import { Box, Button, Stack } from "@mui/material";
import React from "react";
import AddPartnerTestModal from "../TestAdd";
import UpdatePartnerTestModal from "../TestUpdate";
import DeletePartnerTestModal from "../TestDelete";
import ProductGroupsForTest from "./ProductGroups";

type PartnerTestsProps = {
  partner_id: string;
};

const TestsForPartner = (props: PartnerTestsProps) => {
  const [showAddPartnerTestModal, setShowAddPartnerTestModal] = useState(false);
  const [showEditPartnerTestModal, setShowEditPartnerTestModal] =
    useState(false);
  const [partnerTestToUpdate, setPartnerTestToUpdate] =
    useState<PartnerTestType>();

  const [showDeletePartnerTestModal, setShowDeletePartnerTestModal] =
    useState(false);
  const [partnerTestToDelete, setPartnerTestToDelete] =
    useState<PartnerTestType>();
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddPartnerTestModal(true);
  };

  const onDoneAdding = (reload: boolean) => {
    if (reload) {
      refetch();
    }
    setShowAddPartnerTestModal(false);
  };

  const onEdit = (partnerTest: any) => {
    setPartnerTestToUpdate(partnerTest);
    setShowEditPartnerTestModal(true);
  };

  const onDoneEditing = (reload: boolean) => {
    if (reload) {
      refetch();
    }
    setShowEditPartnerTestModal(false);
  };

  const onDelete = (partnerTest: any) => {
    setPartnerTestToDelete(partnerTest);
    setShowDeletePartnerTestModal(true);
  };

  const onDoneDeleting = (reload: boolean) => {
    if (reload) {
      refetch();
    }
    setShowDeletePartnerTestModal(false);
  };

  const [partnerTests, setPartnerTests] = useState<PartnerTestType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "test_type",
      headerName: "Test",
      flex: 1,
    },
    {
      field: "other_identifier",
      headerName: "Other Identifier",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "physician_name",
      headerName: "Physician",
      minWidth: 200,
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              onEdit(params.row);
            }}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              onDelete(params.row);
            }}
          >
            Delete
          </Button>
        </Stack>
      ),
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetPartnerTestsQuery(props.partner_id);

  useEffect(() => {
    if (isSuccess) {
      setPartnerTests(data);
    }
  });

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button variant="text" color="secondary" onClick={onAdd}>
          + Add
        </Button>
      </Box>
      <Box>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={partnerTests}
          columns={columns}
          loading={isFetching}
          getDetailPanelContent={({ row }) => {
            return <ProductGroupsForTest test_id={row.id} />;
          }}
        />
      </Box>
      <AddPartnerTestModal
        doneAdding={onDoneAdding}
        show={showAddPartnerTestModal}
        partner_id={props.partner_id}
      />
      <UpdatePartnerTestModal
        doneEditing={onDoneEditing}
        show={showEditPartnerTestModal}
        partnerTest={partnerTestToUpdate}
      />
      <DeletePartnerTestModal
        doneDeleting={onDoneDeleting}
        show={showDeletePartnerTestModal}
        partnerTest={partnerTestToDelete}
      />
    </React.Fragment>
  );
};

export default TestsForPartner;
