import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetProductGroupsByPartnerTestQuery } from "../../../../../../redux/features/commerce/products/productGroupsApi";
import { formatDateTime } from "../../../../../../_shared/utils/uiHelpers";
import React from "react";
import { Box, Button, Chip, Container } from "@mui/material";
import {
  ProductType,
  ProductGroupType,
} from "../../../../../../redux/features/commerce/products/types";
import _ from "lodash";

type ProductGroupsForTestProps = {
  test_id: string;
};

const ProductGroupsForTest = (props: ProductGroupsForTestProps) => {
  const [productGroups, setProductGroups] = useState<ProductGroupType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Product Group",
      flex: 1,
    },
    {
      field: "products",
      headerName: "Products",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return _.map(params.value, (p: ProductType) => {
          return <Chip label={p.name} sx={{ backgroundColor: p.color }} />;
        });
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetProductGroupsByPartnerTestQuery(props.test_id);

  useEffect(() => {
    if (isSuccess) {
      setProductGroups(data);
    }
  });

  return (
    <React.Fragment>
      <Container>
        <Box>
          <DataGridPro
            autoHeight
            disableRowSelectionOnClick={true}
            rows={productGroups}
            columns={columns}
            loading={isFetching}
          />
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ProductGroupsForTest;
