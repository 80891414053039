import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetProductQuery } from "../../../../../../../redux/features/commerce/products/productsApi";
import { useGetTermsQuery } from "../../../../../../../redux/features/commerce/terms/termsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { ProductType } from "../../../../../../../redux/features/commerce/products/types";
import FormInfo from "../../../../../../../_shared/components/FormInfo";
import { formatAudit } from "../../../../../../../_shared/utils/uiHelpers";
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import ProductTestsGrid from "./components/ProductTestGrid";
import { useUpdateProductMutation } from "../../../../../../../redux/features/commerce/products/productsApi";
import _, { merge } from "lodash";
import AddProductTestModal from "./components/ProductTestAdd";
import { TermType } from "../../../../../../../redux/features/commerce/terms/types";
import DeleteProductModal from "./components/ProductDelete";
import { useGetPromoCodesQuery, useUpdatePromoCodeMutation } from "../../../../../../../redux/features/commerce/promos/promosApi";
import { SelectAllOptions } from "../../../../../promo-codes/components/PromosAdd";
import { ProductTypeOptions } from "../ProductAdd";

type ProductDetailModalProps = {
  show: boolean;
  product_id: string | null;
  doneShowing: (refetch: boolean) => void;
};

const ProductDetailModal = (props: ProductDetailModalProps) => {
  const [terms, setTerms] = useState<{ id: string; label: string }[]>([]);
  const { data: termsData } = useGetTermsQuery({});
  const [product, setProduct] = useState<ProductType>();

  const [refetchProductTests, setRefetchProductTests] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showAddProductTestModal, setShowAddProductTestModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [updateProduct, { isLoading }] = useUpdateProductMutation();
  const [prevProductType, setPrevProductType] = useState<string>("");
  const { data: promo_codes = [] } = useGetPromoCodesQuery({});
  const [ updatePromo ] = useUpdatePromoCodeMutation(); 

  const handleClose = () => {
    props.doneShowing(true);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancelEdit = () => {
    setIsEditing(false);
  };

  const onDelete = () => {
    setShowDeleteProductModal(true);
  };

  const onDoneDeleting = (reload: boolean) => {
    if (reload) {
      props.doneShowing(true);
    }
    setShowDeleteProductModal(false);
  };

  const onDoneRefetchingProductTests = () => {
    setRefetchProductTests(false);
  };

  const onAddProductTest = () => {
    setShowAddProductTestModal(true);
  };

  const onDoneAddingProductTest = (reload: boolean) => {
    setRefetchProductTests(true);
    setShowAddProductTestModal(false);
  };

  useEffect(() => {
    if (termsData)
      setTerms(
        termsData?.map((t: TermType) => {
          return { label: t.name, id: t.id };
        })
      );
  }, [termsData]);

  const { data, isSuccess, refetch } = useGetProductQuery(
    props.product_id || skipToken
  );

  useEffect(() => {
    setProduct(data);
    if (data?.product_type != null) setPrevProductType(data?.product_type);
  }, [isSuccess, data]);

  useEffect(() => {
    if (props.product_id === null) setProduct(undefined);
  }, [props.product_id]);

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const updatePromoCodes = (result: any) => {
    let newProductType = result.updatedProduct.product_type;
    if (newProductType === prevProductType) return;
    if (prevProductType === "") setPrevProductType(newProductType);

    // remove pre-updated product from applicable promo codes
    let deleteFromCodes = [] = promo_codes.filter((pc) => {
      if ((prevProductType === ProductTypeOptions.Single && pc.select_all_option === SelectAllOptions.SelectAllSingles) ||
      (prevProductType === ProductTypeOptions.Package && pc.select_all_option === SelectAllOptions.SelectAllPackages)
      ) {
        return pc;
      }
    });
    for (let i = 0; i < deleteFromCodes.length; i++) {
      let updatedProductIds = [] = deleteFromCodes[i].product_ids.filter((id) => id != result.updatedProduct.id);
      const payload = merge({}, deleteFromCodes[i], {});
      payload.product_ids = updatedProductIds;
      updatePromo(payload).unwrap();
    }

    // add updated product to applicable promo codes
    let addToCodes = [] = promo_codes.filter((pc) => {
      if ((newProductType === ProductTypeOptions.Single && pc.select_all_option === SelectAllOptions.SelectAllSingles) ||
      (newProductType === ProductTypeOptions.Package && pc.select_all_option === SelectAllOptions.SelectAllPackages)
      ) {
        return pc;
      }
    });
    for (let i = 0; i < addToCodes.length; i++) {
      const payload = merge({}, addToCodes[i], {product_ids: [...addToCodes[i].product_ids, result.updatedProduct.id]});
      updatePromo(payload).unwrap();
    }
  }

  const submitHandler = (values: any) => {
    const payload = merge({}, product, values);
    updateProduct(payload)
      .unwrap()
      .then((result) => {
        formik.setSubmitting(false);
        refetch();
        setIsEditing(false);

        updatePromoCodes(result);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: product?.name,
      product_type: product?.product_type,
      description: product?.description,
      price: product?.price,
      is_active: product?.is_active,
      terms_id: product?.terms_id,
      display_order: product?.display_order,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
      price: Yup.number().required("Price is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog open={props.show} onClose={handleClose} maxWidth="lg" fullWidth>
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle>Product Detail</DialogTitle>
        <Stack direction="row" sx={{ mr: 2 }}>
          {!isEditing && (
            <React.Fragment>
              <Button variant="text" color="secondary" onClick={onEdit}>
                Edit
              </Button>
              <Button variant="text" color="secondary" onClick={onDelete}>
                Delete
              </Button>
            </React.Fragment>
          )}
        </Stack>
      </Stack>

      {isEditing && (
        <React.Fragment>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  fullWidth
                  label="Name"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.name}
                />
                <FormControl fullWidth required>
                  <InputLabel id="product_type">Product Type</InputLabel>
                  <Select
                    labelId="product_type"
                    name="product_type"
                    label="Product Type"
                    value={formik.values.product_type}
                    error={Boolean(
                      formik.touched?.product_type && formik.errors?.product_type
                    )}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={ProductTypeOptions.Single}>{ProductTypeOptions.Single}</MenuItem>
                    <MenuItem value={ProductTypeOptions.Package}>{ProductTypeOptions.Package}</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  fullWidth
                  label="Description"
                  name="description"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  rows={3}
                  value={formik.values.description}
                />
                <TextField
                  error={Boolean(formik.touched.price && formik.errors.price)}
                  label="Price"
                  name="price"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.price}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <Autocomplete
                  disablePortal
                  id="terms_combo"
                  options={terms}
                  onChange={(
                    event: any,
                    newValue: { id: string; label: string } | null
                  ) => {
                    formik.setFieldValue("terms_id", newValue?.id);
                  }}
                  value={_.find(terms, (t: any) => {
                    return t.id === formik.values.terms_id;
                  })}
                  renderInput={(params) => (
                    <TextField {...params} label="Terms" />
                  )}
                />
                <TextField
                  error={Boolean(formik.touched.display_order && formik.errors.display_order)}
                  fullWidth
                  label="Display Order"
                  name="display_order"
                  type="number"
                  InputProps={{
                    inputProps: {min: 0, max: Number.MAX_SAFE_INTEGER}
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.display_order}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.is_active}
                      onChange={handleIsActive}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Active"
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancelEdit}>Cancel</Button>
              <LoadingButton
                disabled={isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </React.Fragment>
      )}

      {!isEditing && (
        <React.Fragment>
          <DialogContent>
            <Stack>
              <FormInfo label="Name" value={product?.name} />
              <FormInfo label="Product Type" value={product?.product_type} />
              <FormInfo label="Description" value={product?.description} />
              <FormInfo label="Price" value={product?.price} />
              <FormInfo
                label="Active"
                value={product?.is_active ? "Yes" : "No"}
              />
              <FormInfo label="Terms" value={product?.terms_name} />
              <FormInfo
                label="Created"
                value={formatAudit(
                  product?.created_by_name,
                  product?.created_at,
                  ""
                )}
              />
              <FormInfo
                label="Updated"
                value={formatAudit(
                  product?.updated_by_name,
                  product?.updated_at,
                  ""
                )}
              />
              <FormInfo
                label="Display Order"
                value={formik.values.display_order}
              />
              <Typography fontWeight="bold" sx={{ mb: 2 }}>
                Product Tests
              </Typography>
              <Box sx={{ mb: 2, justifyContent: "flex-end", display: "flex" }}>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={onAddProductTest}
                >
                  Add +
                </Button>
              </Box>
              <ProductTestsGrid
                product_id={props.product_id}
                refetch={refetchProductTests}
                doneRefetching={onDoneRefetchingProductTests}
              />
            </Stack>
            <AddProductTestModal
              show={showAddProductTestModal}
              doneAdding={onDoneAddingProductTest}
              product_id={product?.id}
            />
            <DeleteProductModal
              show={showDeleteProductModal}
              doneDeleting={onDoneDeleting}
              productToDelete={product}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default ProductDetailModal;
