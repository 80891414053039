import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import FormInfo from "../../_shared/components/FormInfo";
import { DashboardAlertSettingsType } from "../../redux/features/settings/types";
import { useGetDashboardSettingsQuery } from "../../redux/features/settings/settingsApi";
import EditDashboardAlertModal from "./components/DashboardAlertUpdate";

const SettingsPage = () => {
  const [dashboardAlertSettings, setDashboardAlertSettings] =
    useState<DashboardAlertSettingsType>();
  const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
  const onEdit = (e: any) => {
    e.preventDefault();
    setShowEditSettingsModal(true);
  };

  const onDoneEditing = () => {
    refetch();
    setShowEditSettingsModal(false);
  };

  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetDashboardSettingsQuery({});

  useEffect(() => {
    if (isSuccess) {
      setDashboardAlertSettings(data);
    }
  }, [data]);

  if (dashboardAlertSettings) {
    return (
      <React.Fragment>
        <Grid container columns={2}>
          <Grid item sm={1}>
            <Breadcrumbs sx={{ mt: 1 }}>
              <Typography color="text.primary">App Settings</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          ></Grid>
        </Grid>
        <Container>
          <Card>
            <CardHeader
              title="Dashboard Alert Settings"
              action={
                <Button variant="text" color="secondary" onClick={onEdit}>
                  Edit
                </Button>
              }
            />
            <CardContent>
              <FormInfo
                label="Text"
                value={dashboardAlertSettings?.dashboard_alert_text}
              />
              <FormInfo
                label="Type"
                value={dashboardAlertSettings?.dashboard_alert_type}
              />
              <FormInfo
                label="Active"
                value={
                  dashboardAlertSettings?.dashboard_alert_is_active
                    ? "Yes"
                    : "No"
                }
              />
            </CardContent>
          </Card>
        </Container>
        <EditDashboardAlertModal
          dashboardAlert={dashboardAlertSettings}
          doneEditing={onDoneEditing}
          show={showEditSettingsModal}
        />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default SettingsPage;
