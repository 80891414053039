import { Box, CssBaseline } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import { APP } from "../../utils/_urls";
import _ from "lodash";

interface LayoutProp {
  children: ReactNode | ReactNode[];
}

const drawerWidth = 240;

const Layout = ({ children }: LayoutProp) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerToggleHandler = () => {
    setMobileOpen(!mobileOpen);
  };
  const navigate = useNavigate();

  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );
  const token = useAppSelector((state) => state.currentUserState.token);
  useEffect(() => {
    if (!token || token === undefined) {
      navigate(APP.LOGIN);
    }
    if (currentUser?.user_type !== "Admin") {
      navigate(APP.UNAUTHORIZED);
    }
  });

  if (token) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Header onToggleMobile={drawerToggleHandler} />
        <Box id="sidebarContainer" sx={{ display: "flex", pt: 6 }}>
          <Sidebar
            drawerWidth={drawerWidth}
            mobileOpen={mobileOpen}
            onToggleMobile={drawerToggleHandler}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pt: 5,
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 48px)",
            }}
          >
            <Box sx={{ px: 3 }}>{children}</Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return <p>Must be logged in</p>;
  }
};

export default Layout;
