import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdatePartnerTestMutation } from "../../../../../redux/features/partnerTests/partnerTestsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge, partial } from "lodash";
import { PartnerTestType } from "../../../../../redux/features/partnerTests/types";
import React from "react";
import PreviewAvailabilityModal from "./components/PreviewAvailability";
import { useGetUsersByTypeQuery } from "../../../../../redux/features/customers/customersApi";
import { CustomerType } from "../../../../../redux/features/customers/types";

type UpdatePartnerTestProps = {
  partnerTest: PartnerTestType | undefined;
  show: boolean;
  doneEditing: (reload: boolean) => void;
};

const UpdatePartnerTestModal = (props: UpdatePartnerTestProps) => {
  const [physicians, setPhysicians] = useState<{ id: string; label: string }[]>(
    []
  );

  const {
    data: userData = [],
    isFetching: isUserFetching,
    isSuccess: isUsersSuccess,
  } = useGetUsersByTypeQuery("Admin");
  const [partnerTestToAdd, setPartnerTestToAdd] = useState<
    PartnerTestType | Record<string, any>
  >();

  useEffect(() => {
    if (isUsersSuccess) {
      setPhysicians(
        userData.map((u: CustomerType) => {
          return {
            label: `${u.last_name}, ${u.first_name}`,
            id: u.id,
          };
        })
      );
    }
  }, [isUsersSuccess]);
  const [showPreviewAvailability, setShowPreviewAvailability] =
    useState<boolean>(false);
  const handleClose = () => {
    props.doneEditing(false);
  };
  const [updatePartnerTest, { isLoading }] = useUpdatePartnerTestMutation();

  const submitHandler = (values: any) => {
    const payload = merge({}, props.partnerTest, values);

    updatePartnerTest(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          props.doneEditing(true);
        }
        console.log(result);
      });
  };

  const onShowCalendarPreview = () => {
    setShowPreviewAvailability(true);
  };

  const onDoneShowingCalendarPreview = () => {
    setShowPreviewAvailability(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      test_type: props.partnerTest?.test_type || "",
      other_identifier: props.partnerTest?.other_identifier || "",
      physician_id: props.partnerTest?.physician_id || "",
      description: props.partnerTest?.description || "",
      integration: props.partnerTest?.integration || null,
      integration_username: props.partnerTest?.integration_username || "",
      integration_password: props.partnerTest?.integration_password || "",
      integration_meta: props.partnerTest?.integration_meta
        ? JSON.stringify(props.partnerTest?.integration_meta)
        : null,
    },
    validationSchema: Yup.object({
      test_type: Yup.string().required("Test Type is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Update a test</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <FormControl fullWidth required>
              <InputLabel id="test_type">Type</InputLabel>
              <Select
                labelId="test_type"
                name="test_type"
                label="Type"
                value={formik.values.test_type}
                error={Boolean(
                  formik.touched?.test_type && formik.errors?.test_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={"BODPOD"}>BOD POD</MenuItem>
                <MenuItem value={"DEXA"}>DEXA</MenuItem>
                <MenuItem value={"DEXABMD"}>DEXA BMD</MenuItem>
                <MenuItem value={"RMR"}>RMR</MenuItem>
                <MenuItem value={"VO2"}>VO2</MenuItem>
                <MenuItem value={"HYDROSTATIC"}>Hydrostatic</MenuItem>
                <MenuItem value={"SECA"}>SECA</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.other_identifier &&
                  formik.errors.other_identifier
              )}
              fullWidth
              label="Other Identifier"
              name="other_identifier"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.other_identifier}
            />
            <FormControl fullWidth>
              <InputLabel id="physician_id">Physician</InputLabel>
              <Select
                labelId="physician_id"
                name="physician_id"
                label="Type"
                value={formik.values.physician_id}
                error={Boolean(
                  formik.touched?.physician_id && formik.errors?.physician_id
                )}
                onChange={formik.handleChange}
              >
                {physicians.map((p) => {
                  return (
                    <MenuItem value={p.id} key={p.id}>
                      {p.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              multiline
              fullWidth
              label="Description"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              rows={4}
              value={formik.values.description}
            />
            <FormControl fullWidth>
              <InputLabel id="integration" shrink>
                Integration
              </InputLabel>
              <Select
                displayEmpty
                labelId="integration"
                label="Integration"
                name="integration"
                value={formik.values.integration}
                error={Boolean(
                  formik.touched?.integration && formik.errors?.integration
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={null as any}>--- None --- </MenuItem>
                <MenuItem value={"ACUITY"}>Acuity Scheduling</MenuItem>
              </Select>
            </FormControl>
            {formik.values.integration !== null && (
              <React.Fragment>
                <TextField
                  error={Boolean(
                    formik.touched.integration_username &&
                      formik.errors.integration_username
                  )}
                  fullWidth
                  label="Integration Username"
                  name="integration_username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.integration_username}
                />
                <TextField
                  error={Boolean(
                    formik.touched.integration_password &&
                      formik.errors.integration_password
                  )}
                  fullWidth
                  label="Integration Password"
                  name="integration_password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.integration_password}
                />
                <TextField
                  error={Boolean(
                    formik.touched.integration_meta &&
                      formik.errors.integration_meta
                  )}
                  fullWidth
                  multiline
                  rows={3}
                  label="Additional Integration Information"
                  name="integration_meta"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.integration_meta}
                />
                <Button variant="outlined" onClick={onShowCalendarPreview}>
                  Preview Calendar
                </Button>
              </React.Fragment>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
      <PreviewAvailabilityModal
        partner_test_id={props.partnerTest?.id}
        show={showPreviewAvailability}
        doneShowing={onDoneShowingCalendarPreview}
      />
    </Dialog>
  );
};

export default UpdatePartnerTestModal;
