import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useUpdateTestStatusMutation } from "../../../../../redux/features/tests/testsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TestType,
  UpdateTestStatusRequestType,
} from "../../../../../redux/features/tests/types";

type CancelAppointmentProps = {
  show: boolean;
  test: TestType | undefined;
  doneCancelling: (
    reload: boolean,
    updated_test: TestType | null | undefined
  ) => void;
};

const CancelAppointmentModal = (props: CancelAppointmentProps) => {
  const [updateTestStatus, { isLoading }] = useUpdateTestStatusMutation();

  const handleClose = () => {
    props.doneCancelling(false, null);
  };

  const onCancelAppointment = () => {
    const payload = {
      appointment_id: props.test?.id!,
      status: "Cancelled",
    } as UpdateTestStatusRequestType;

    updateTestStatus(payload)
      .unwrap()
      .then((result: any) => {
        props.doneCancelling(true, result.updatedTest);
      });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>Update Appointment Status</DialogTitle>
      <DialogContent sx={{ mb: 5 }}>
        <p>Are you sure you want to cancel this appointment?</p>
        <Alert severity="warning">
          Doing so will remove the appointment from the customer portal and from
          here.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          No, Don't Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={onCancelAppointment}
          loading={isLoading}
        >
          Yes, Cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelAppointmentModal;
