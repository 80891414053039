import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreatePartnerTestMutation } from "../../../../../redux/features/partnerTests/partnerTestsApi";
import { useGetUsersByTypeQuery } from "../../../../../redux/features/customers/customersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { PartnerTestType } from "../../../../../redux/features/partnerTests/types";
import { CustomerType } from "../../../../../redux/features/customers/types";

type AddPartnerTestProps = {
  partner_id: string;
  show: boolean;
  doneAdding: (reload: boolean) => void;
};

const AddPartnerTestModal = (props: AddPartnerTestProps) => {
  const [physicians, setPhysicians] = useState<{ id: string; label: string }[]>(
    []
  );
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const [createPartnerTest, { isLoading }] = useCreatePartnerTestMutation();
  const {
    data: userData = [],
    isFetching: isUserFetching,
    isSuccess: isUsersSuccess,
  } = useGetUsersByTypeQuery("Admin");
  const [partnerTestToAdd, setPartnerTestToAdd] = useState<
    PartnerTestType | Record<string, any>
  >();

  useEffect(() => {
    if (isUsersSuccess) {
      setPhysicians(
        userData.map((u: CustomerType) => {
          return {
            label: `${u.last_name}, ${u.first_name}`,
            id: u.id,
          };
        })
      );
    }
  }, [isUsersSuccess]);

  const submitHandler = (values: any) => {
    const payload = merge({}, partnerTestToAdd, values);
    payload.partner_id = props.partner_id;

    createPartnerTest(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          formik.resetForm();
          props.doneAdding(true);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      test_type: "",
      other_identifier: "",
      physician_id: "",
      description: "",
    },
    validationSchema: Yup.object({
      test_type: Yup.string().required("Test Type is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new test</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <FormControl fullWidth required>
              <InputLabel id="test_type">Type</InputLabel>
              <Select
                labelId="test_type"
                name="test_type"
                label="Type"
                value={formik.values.test_type}
                error={Boolean(
                  formik.touched?.test_type && formik.errors?.test_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={"BODPOD"}>BOD POD</MenuItem>
                <MenuItem value={"DEXA"}>DEXA</MenuItem>
                <MenuItem value={"DEXABMD"}>DEXA BMD</MenuItem>
                <MenuItem value={"RMR"}>RMR</MenuItem>
                <MenuItem value={"VO2"}>VO2 Max</MenuItem>
                <MenuItem value={"HYDROSTATIC"}>Hydrostatic</MenuItem>
                <MenuItem value={"SECA"}>SECA</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.other_identifier &&
                  formik.errors.other_identifier
              )}
              fullWidth
              label="Other Identifier"
              name="other_identifier"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.other_identifier}
            />
            <FormControl fullWidth>
              <InputLabel id="physician_id">Physician</InputLabel>
              <Select
                labelId="physician_id"
                name="physician_id"
                label="Type"
                value={formik.values.physician_id}
                error={Boolean(
                  formik.touched?.physician_id && formik.errors?.physician_id
                )}
                onChange={formik.handleChange}
              >
                {physicians.map((p) => {
                  return (
                    <MenuItem value={p.id} key={p.id}>
                      {p.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.other_identifier &&
                  formik.errors.other_identifier
              )}
              fullWidth
              multiline
              rows={4}
              label="Description"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose} //disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPartnerTestModal;
