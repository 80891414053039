import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store";
import {
  GetTestsResultType,
  GetTestResultType,
  TestType,
  UpdateTestResultType,
  CreateAppointmentResultType,
  UpdateTestStatusRequestType,
  GetPreviewResultType,
  UpdateAppointmentResultType,
  UpdateAppointmentRequestType,
  IssueType,
  GetIssuesResultType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const testsApi = createApi({
  reducerPath: "testsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  tagTypes: ["Tests"],
  endpoints: (builder) => ({
    getTests: builder.query<TestType[], boolean>({
      query(include_completed) {
        return {
          url: `tests?include_completed=${include_completed}`,
          method: "GET",
        };
      },
      providesTags: ["Tests"],
      keepUnusedDataFor: 5,
      transformResponse: (result: GetTestsResultType) => {
        return result.data;
      },
    }),
    getIssues: builder.query<IssueType[], {}>({
      query() {
        return {
          url: `tests/issues`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (result: GetIssuesResultType) => {
        return result.data;
      },
    }),
    getUserTests: builder.query<TestType[], string>({
      query(user_id) {
        return {
          url: `tests/user/${user_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetTestsResultType) => {
        return result.data;
      },
    }),
    getTest: builder.query<TestType, string>({
      query(appointment_id) {
        return {
          url: `tests/${appointment_id}`,
          method: "GET",
        };
      },
      transformResponse: (result: GetTestResultType) => {
        return result.data;
      },
    }),
    updateTestResult: builder.mutation<GetTestResultType, UpdateTestResultType>(
      {
        query({ appointment_id, results }) {
          return {
            url: `tests/${appointment_id}/results`,
            method: "PUT",
            body: results,
          };
        },
        transformResponse: (result: any) => {
          return result.data;
        },
        invalidatesTags: ["Tests"],
      }
    ),
    updateAppointment: builder.mutation<
      UpdateAppointmentResultType,
      UpdateAppointmentRequestType
    >({
      query({ appointment_id, data }) {
        return {
          url: `tests/${appointment_id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: any): UpdateAppointmentResultType => {
        return result.data;
      },
      invalidatesTags: ["Tests"],
    }),
    updateTestStatus: builder.mutation<
      GetTestResultType,
      UpdateTestStatusRequestType
    >({
      query({ appointment_id, status, note }) {
        return {
          url: `tests/${appointment_id}/status`,
          method: "PUT",
          body: { status, note },
        };
      },
      transformResponse: (result: any) => {
        return result.data;
      },
      invalidatesTags: ["Tests"],
    }),
    createAppointment: builder.mutation<
      CreateAppointmentResultType,
      Partial<TestType>
    >({
      query(data) {
        return {
          url: `tests`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateAppointmentResultType => {
        return result.data;
      },
      invalidatesTags: ["Tests"],
    }),
    getPreview: builder.query<GetPreviewResultType, string>({
      query(appointment_id) {
        return {
          url: `tests/${appointment_id}/preview`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetTestsQuery,
  useGetUserTestsQuery,
  useGetTestQuery,
  useUpdateTestResultMutation,
  useUpdateTestStatusMutation,
  useCreateAppointmentMutation,
  useUpdateAppointmentMutation,
  useGetPreviewQuery,
  useGetIssuesQuery,
} = testsApi;
