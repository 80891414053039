import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useGetWidgetQuery } from "../../../../../../redux/features/commerce/products/productGroupsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";

type ProductGroupWidgetProps = {
  show: boolean;
  product_group_id: string | undefined;
  doneShowing: () => void;
};

const ProductGroupWidget = (props: ProductGroupWidgetProps) => {
  const [htmlCode, setHtmlCode] = useState<string>();
  const handleClose = () => {
    props.doneShowing();
  };

  const { data } = useGetWidgetQuery(props.product_group_id || skipToken);

  const clickHandler = () => {
    if (htmlCode) navigator.clipboard.writeText(htmlCode);
    alert("Copied to clipboard.");
  };

  useEffect(() => {
    setHtmlCode(data?.html_code);
    console.log("html code", data?.html_code);
  }, [data]);

  return (
    <Dialog open={props.show} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Product Group Widget Code</DialogTitle>
      <DialogContent>
        <TextField fullWidth disabled multiline rows={25} value={htmlCode} />
      </DialogContent>
      <DialogActions>
        <Button onClick={clickHandler}>Copy To Clipboard</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductGroupWidget;
