import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useCreateAppointmentMutation,
  useGetTestsQuery,
} from "../../../../redux/features/tests/testsApi";
import { useGetPartnersQuery } from "../../../../redux/features/partners/partnersApi";
import { useGetCustomersQuery } from "../../../../redux/features/customers/customersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { TestType } from "../../../../redux/features/tests/types";
import { APP } from "../../../../_shared/utils/_urls";
import { PartnerType } from "../../../../redux/features/partners/types";
import { CustomerType } from "../../../../redux/features/customers/types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { test_types } from "../../../../_shared/utils/testTypes";
import { formatISO9075, parse, parseISO } from "date-fns";

type AddAppointmentProps = {
  show: boolean;
  doneAdding: (reload: boolean) => void;
  customer?: string | undefined;
};

const AddAppointmentModal = (props: AddAppointmentProps) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const { refetch } = useGetTestsQuery(true);
  const [createAppointment, { isLoading }] = useCreateAppointmentMutation();
  const [partners, setPartners] = useState<{ id: string; label: string }[]>([]);
  const [users, setUsers] = useState<{ id: string; label: string }[]>([]);
  const [appointmentToAdd, setAppointmentToAdd] = useState<
    TestType | Record<string, any>
  >();

  const submitHandler = (values: any) => {
    const payload = merge({}, appointmentToAdd, values);
    if (payload.appointment_date) {
      payload.appointment_date = formatISO9075(payload.appointment_date);
    }

    createAppointment(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          refetch();
          navigate(`${APP.APPOINTMENTS}/${result.createdAppointment.id}`);
        }
        navigate(`${APP.APPOINTMENTS}/${result.createdAppointment.id}`);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: props.customer === undefined ? "" : props.customer,
      partner_id: "",
      test_type: "",
      appointment_date: null,
    },
    validationSchema: Yup.object({
      user_id: Yup.string().required("Customer is required"),
      partner_id: Yup.string().required("Partner is required"),
      test_type: Yup.string().required("Appointment Type is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const {
    data: partnerData,
    isFetching: isPartnersFetching,
    isSuccess: isPartnersSuccess,
  } = useGetPartnersQuery({});
  const {
    data: userData = [],
    isFetching: isUserFetching,
    isSuccess: isUsersSuccess,
  } = useGetCustomersQuery({});
  useEffect(() => {
    if (isPartnersSuccess) {
      setPartners(
        partnerData.map((p: PartnerType) => {
          return { label: p.name, id: p.id };
        })
      );
    }
    if (isUsersSuccess) {
      setUsers(
        userData.map((u: CustomerType) => {
          return {
            label: `${u.last_name}, ${u.first_name}`,
            id: u.id,
          };
        })
      );
    }
  }, [isPartnersSuccess, isUsersSuccess]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new Appointment</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            {props.customer === undefined && (
              <Autocomplete
                disablePortal
                id="user-combo"
                options={users}
                onChange={(
                  event: any,
                  newValue: { id: string; label: string } | null
                ) => {
                  formik.setFieldValue("user_id", newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Customer" />
                )}
              />
            )}
            <Autocomplete
              disablePortal
              id="partner-combo"
              options={partners}
              onChange={(
                event: any,
                newValue: { id: string; label: string } | null
              ) => {
                formik.setFieldValue("partner_id", newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Partner" />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Appointment Date"
                value={formik.values.appointment_date}
                onChange={(val: any) => {
                  formik.setFieldValue("appointment_date", val);
                }}
                slotProps={{
                  textField: {
                    error: Boolean(
                      formik.touched.appointment_date &&
                        formik.errors.appointment_date
                    ),
                    helperText: `${
                      formik.touched.appointment_date &&
                      formik.errors.appointment_date
                    }`,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl fullWidth required>
              <InputLabel id="test_type">Type</InputLabel>
              <Select
                labelId="test_type"
                name="test_type"
                label="Type"
                value={formik.values.test_type}
                error={Boolean(
                  formik.touched?.test_type && formik.errors?.test_type
                )}
                onChange={formik.handleChange}
              >
                {test_types.map((tt) => {
                  return (
                    <MenuItem value={tt.value} key={tt.value}>
                      {tt.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAppointmentModal;
