import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import OrdersGrid from "./components/OrdersGrid";
import OrdersFilter from "./components/OrdersFilter";
import _ from "lodash";

const statusOptions = [
  { value: "Created", label: "Created" },
  { value: "InProgress", label: "In Progress" },
  { value: "Complete", label: "Complete" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Other", label: "Other" },
];

const OrdersPage = () => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() - 30))
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setDate(today.getDate() + 1))
  );
  const [statuses, setStatuses] = useState<any[]>(
    _.filter(statusOptions, (so) => {
      return so.value !== "Created";
    })
  );

  useEffect(() => {
    setStatuses(
      _.filter(statusOptions, (so) => {
        return so.value !== "Created";
      })
    );

    console.log(statuses);
  }, []);

  const onUpdateFilter = (filter: any | undefined) => {
    console.log(filter);
    setStatuses(filter.statuses);
    setStartDate(filter.startDate);
    setEndDate(filter.endDate);
  };

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Stack spacing={2}>
          <Breadcrumbs sx={{ mt: 1 }}>
            <Typography color="text.primary">Commerce</Typography>
            <Typography color="text.primary">Orders</Typography>
          </Breadcrumbs>
          <OrdersFilter
            statuses={statuses}
            startDate={startDate}
            endDate={endDate}
            changeHandler={onUpdateFilter}
            statusOptions={statusOptions}
          />
          <OrdersGrid
            statuses={statuses}
            startDate={startDate}
            endDate={endDate}
          />
        </Stack>
      </Container>
    </React.Fragment>
  );
};

export default OrdersPage;
