import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { ProductType } from "../../../../../../../redux/features/commerce/products/types";
import { useCreateProductMutation } from "../../../../../../../redux/features/commerce/products/productsApi";
import { useGetTermsQuery } from "../../../../../../../redux/features/commerce/terms/termsApi";
import { TermType } from "../../../../../../../redux/features/commerce/terms/types";
import { useGetPromoCodesQuery, useUpdatePromoCodeMutation } from "../../../../../../../redux/features/commerce/promos/promosApi";
import { SelectAllOptions } from "../../../../../promo-codes/components/PromosAdd";

type AddProductProps = {
  show: boolean;
  product_group_id: string | null | undefined;
  doneAdding: (reload: boolean) => void;
};

export const enum ProductTypeOptions {
  Single = "Single",
  Package = "Package",
};

const AddProductModal = (props: AddProductProps) => {
  const [productToAdd] = useState<ProductType | Record<string, any>>();
  const handleClose = () => {
    props.doneAdding(false);
  };
  const [terms, setTerms] = useState<{ id: string; label: string }[]>([]);

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const [createProduct, { isLoading }] = useCreateProductMutation();

  const { data, isSuccess } = useGetTermsQuery({});

  const { data: promo_codes = [] } = useGetPromoCodesQuery({});
  const [ updatePromo ] = useUpdatePromoCodeMutation();

  useEffect(() => {
    if (isSuccess) {
      setTerms(
        data.map((t: TermType) => {
          return { label: t.name, id: t.id };
        })
      );
    }
  }, [data]);

  const updatePromoCodes = (result: any) => {
    let codesToUpdate = [] = promo_codes.filter((pc) => {
      if ((result.createdProduct.product_type === ProductTypeOptions.Single && pc.select_all_option === SelectAllOptions.SelectAllSingles) ||
      (result.createdProduct.product_type === ProductTypeOptions.Package && pc.select_all_option === SelectAllOptions.SelectAllPackages)
      ) {
        return pc;
      }
    });
    for (let i = 0; i < codesToUpdate.length; i++) {
      const promocodePayload = merge({}, codesToUpdate[i], {product_ids: [...codesToUpdate[i].product_ids, result.createdProduct.id]});
      updatePromo(promocodePayload).unwrap();
    }
  }

  const submitHandler = (values: any) => {
    const payload = merge({}, productToAdd, values);
    payload.product_group_id = props.product_group_id;

    createProduct(payload)
      .unwrap()
      .then((result) => {
        if (result) {
          formik.setSubmitting(false);
          props.doneAdding(true);

          updatePromoCodes(result);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      product_type: "",
      description: "",
      price: null,
      is_active: true,
      color: "",
      terms_id: "",
      display_order: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
        formik.resetForm();
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add a new Product</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.name}
            />
            <FormControl fullWidth required>
              <InputLabel id="product_type">Product Type</InputLabel>
              <Select
                labelId="product_type"
                name="product_type"
                label="Product Type"
                value={formik.values.product_type}
                error={Boolean(
                  formik.touched?.product_type && formik.errors?.product_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value={ProductTypeOptions.Single}>{ProductTypeOptions.Single}</MenuItem>
                <MenuItem value={ProductTypeOptions.Package}>{ProductTypeOptions.Package}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.description && formik.errors.description
              )}
              fullWidth
              label="Description"
              name="description"
              multiline
              rows={2}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <TextField
              error={Boolean(formik.touched.price && formik.errors.price)}
              label="Price"
              name="price"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
            <TextField
              error={Boolean(formik.touched.color && formik.errors.color)}
              fullWidth
              label="Color"
              name="color"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.color}
            />
            <Autocomplete
              disablePortal
              id="term-combo"
              options={terms}
              onChange={(
                event: any,
                newValue: { id: string; label: string } | null
              ) => {
                formik.setFieldValue("terms_id", newValue?.id);
              }}
              renderInput={(params) => (
                <TextField {...params} required label="Term" />
              )}
            />
            <TextField
              error={Boolean(formik.touched.display_order && formik.errors.display_order)}
              fullWidth
              label="Display Order"
              name="display_order"
              type="number"
              InputProps={{
                inputProps: {min: 0, max: Number.MAX_SAFE_INTEGER}
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.display_order}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.is_active}
                  onChange={handleIsActive}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Active"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddProductModal;
