import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import FormTemplatesGrid from "./components/FormTemplateGrid";

const FormTemplatesPage = () => {
  const [showAddFormTemplateModal, setShowAddFormTemplateModal] =
    useState(false);
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddFormTemplateModal(true);
  };

  const onDoneAdding = () => {
    setShowAddFormTemplateModal(false);
  };

  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs sx={{ mt: 1 }}>
            <Typography color="text.primary">Forms</Typography>
            <Typography color="text.primary">Templates</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="text" color="secondary" onClick={onAdd}>
            + Add
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <FormTemplatesGrid />
      </Stack>
    </React.Fragment>
  );
};

export default FormTemplatesPage;
