import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useResendOrderVerificationEmailMutation } from "../../../../../../redux/features/commerce/orders/ordersApi";

type ResendEmailProps = {
  show: boolean;
  orderId: string | undefined;
  doneResendingEmail: () => void;
};

const ResendEmailModal = (props: ResendEmailProps) => {
  const handleClose = () => {
    props.doneResendingEmail();
  };
  const [resentEmail, { isLoading }] =
    useResendOrderVerificationEmailMutation();

  const resetHandler = () => {
    if (props.orderId) {
      resentEmail(props.orderId)
        .unwrap()
        .then((result) => {
          alert("Email sent to user!");
          props.doneResendingEmail();
        });
    }
  };

  return (
    <Dialog open={props.show} onClose={handleClose}>
      <DialogTitle>
        Are you sure you want to resend the order verification email?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isLoading}
          onClick={resetHandler}
          loading={isLoading}
        >
          Send Order Verification Email
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResendEmailModal;
