import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetDashboardAlertSettingsResultType,
  DashboardAlertSettingsType,
  UpdateDashboardAlertSettingsResultType,
} from "./types";
import { store } from "../../store";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const SettingsApi = createApi({
  reducerPath: "settingsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDashboardSettings: builder.query<DashboardAlertSettingsType, {}>({
      query() {
        return {
          url: `settings`,
          method: "GET",
        };
      },
      transformResponse: (result: GetDashboardAlertSettingsResultType) => {
        return result.data;
      },
    }),
    updateSettingsDashboard: builder.mutation<
      boolean,
      Partial<DashboardAlertSettingsType>
    >({
      query({ ...data }) {
        return {
          url: `settings`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (
        result: UpdateDashboardAlertSettingsResultType
      ): boolean => {
        console.log("test", result);
        return result.data.success;
      },
    }),
  }),
});

export const {
  useGetDashboardSettingsQuery,
  useUpdateSettingsDashboardMutation,
} = SettingsApi;
