import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { useCreateProductTestMutation } from "../../../../../../../../../redux/features/commerce/products/productTestsApi";
import { PartnerTestType } from "../../../../../../../../../redux/features/partnerTests/types";
import { useGetPartnerTestsByTestTypeQuery } from "../../../../../../../../../redux/features/partnerTests/partnerTestsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";

type CreateProductTestProps = {
  show: boolean;
  product_id: string | undefined;
  doneAdding: (reload: boolean) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const testTypes = [
  { value: "DEXA", label: "DEXA" },
  { value: "DEXABMD", label: "DEXA BMD" },
  { value: "HYDROSTATIC", label: "Hydrostatic" },
  { value: "RMR", label: "RMR" },
  { value: "SECA", label: "Seca" },
  { value: "VO2", label: "VO2 Max" },
  { value: "BODPOD", label: "Bod Pod" },
];

const CreateProductTestModal = (props: CreateProductTestProps) => {
  const [partners, setPartners] = useState<
    PartnerTestType[] | undefined | null
  >([]);
  const handleClose = () => {
    props.doneAdding(false);
  };
  const [selectedTestType, setSelectedTestType] = useState<string>();
  const { data: partnerTestData } = useGetPartnerTestsByTestTypeQuery(
    selectedTestType || skipToken
  );

  useEffect(() => {
    if (partnerTestData) {
      setPartners(partnerTestData);
    }
  }, [partnerTestData]);

  useEffect(() => {
    formik.setFieldValue("partners", []);
  }, [partners]);

  const [CreateProductTest, { isLoading }] = useCreateProductTestMutation();

  const submitHandler = (values: any) => {
    const payload = merge({ product_id: props.product_id }, values);
    payload.partner_ids = _.map(payload.partners, (p) => {
      return p.partner_id;
    });
    CreateProductTest(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneAdding(true);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      test_type: "",
      quantity: 0,
      partners: [],
    },
    validationSchema: Yup.object({
      test_type: Yup.string().required("Test Type is required"),
      quantity: Yup.number().required("quantity is required").min(1),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setSelectedTestType(formik.values.test_type);
  }, [formik.values.test_type]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Add Test</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormControl fullWidth required>
              <InputLabel id="test_type">Test</InputLabel>
              <Select
                labelId="test_type"
                name="test_type"
                label="Test"
                value={formik.values.test_type}
                error={Boolean(
                  formik.touched?.test_type && formik.errors?.test_type
                )}
                onChange={formik.handleChange}
              >
                {_.map(testTypes, (tt) => {
                  return <MenuItem value={tt.value}>{tt.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              error={Boolean(formik.touched.quantity && formik.errors.quantity)}
              label="Quantity"
              name="quantity"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.quantity}
            />
            <Autocomplete
              multiple
              id="partners"
              options={partners!}
              getOptionLabel={(option) => option.partner_name}
              getOptionKey={(option) => option.id}
              noOptionsText="There are no partners for this test type"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Partners"
                  placeholder="Partners"
                  required={formik.values.partners.length < 1}
                />
              )}
              onChange={(event: any, newValue: PartnerTestType[] | null) => {
                formik.setFieldValue("partners", newValue);
              }}
              value={formik.values.partners}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateProductTestModal;
