import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdatePasswordMutation } from "../../../../redux/features/profile/profileApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
  getErrorMessage,
} from "../../../../_shared/utils/apiHelpers";

type UpdatePasswordProps = {
  show: boolean;
  doneUpdating: (reload: boolean) => void;
};

const UpdatePasswordModal = (props: UpdatePasswordProps) => {
  const [updatePassword, { isLoading, isError, error }] =
    useUpdatePasswordMutation();
  const handleClose = () => {
    formik.resetForm();
    props.doneUpdating(true);
  };

  const submitHandler = (values: any) => {
    const payload = values;
    updatePassword(payload)
      .unwrap()
      .then((result) => {
        formik.resetForm();
        if (result.success) {
          props.doneUpdating(true);
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: "",
      new_password: "",
      confirmation_password: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string()
        .required("Current Password is required")
        .max(255),
      new_password: Yup.string().required("New Password is required").max(255),
      confirmation_password: Yup.string()
        .required("You must re-enter the password is required")
        .max(255)
        .test("match", "Passwords do not match", function (password) {
          return password === this.options.context?.new_password;
        }),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref("new_password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Edit profile</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(
                formik.touched.current_password &&
                  formik.errors.current_password
              )}
              fullWidth
              label="Current Password"
              name="current_password"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.current_password}
            />
            <TextField
              error={Boolean(
                formik.touched.new_password && formik.errors.new_password
              )}
              fullWidth
              label="New Password"
              name="new_password"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.new_password}
            />
            <TextField
              error={Boolean(
                formik.touched.confirmation_password &&
                  formik.errors.confirmation_password
              )}
              helperText={
                formik.touched.confirmation_password &&
                formik.errors.confirmation_password
              }
              {...formik.getFieldProps("confirmation_password")}
              fullWidth
              label="Confirm Password"
              type="password"
              name="confirmation_password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.confirmation_password}
            />
          </Stack>
          <Box sx={{ mt: 3 }}>
            {isError && isFetchBaseQueryError(error) && (
              <Alert severity="error">
                {getErrorMessage(error as FetchBaseQueryError)}
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdatePasswordModal;
