import { Box, FormControlLabel, Switch } from "@mui/material";

type FilterProps = {
  isCompleteHidden: boolean;
  changeHandler: (isCompleteHidden: boolean) => void;
};

const TestsFilter = (props: FilterProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.changeHandler(!event.target.checked);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <FormControlLabel
        control={
          <Switch
            checked={!props.isCompleteHidden}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Show Complete"
      />
    </Box>
  );
};

export default TestsFilter;
