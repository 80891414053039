import {
  Box,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AppointmentsFilter from "./components/AppointmentsFilter";
import AppointmentsGrid from "./components/AppointmentsGrid";
import React, { useState } from "react";
import AddAppointmentModal from "./components/AppointmentsAdd";
import { useAppSelector } from "../../redux/hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import IssuesGrid from "./components/IssuesGrid";

const AppointmentsPage = () => {
  const [showAddAppointmentModal, setShowAddAppointmentModal] = useState(false);
  const [isCompleteHidden, setIsCompleteHidden] = useState(true);
  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );
  const [value, setValue] = React.useState("Appointments");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddAppointmentModal(true);
  };

  const changeHandler = (isCompleteHidden: boolean) => {
    setIsCompleteHidden(isCompleteHidden);
  };

  const onDoneAdding = () => {
    setShowAddAppointmentModal(false);
  };
  return (
    <React.Fragment>
      <Grid container columns={2}>
        <Grid item sm={1}>
          <Breadcrumbs>
            <Typography color="text.primary">Appointments</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
          {currentUser?.role === "SUPERADMIN" && (
            <Button variant="text" color="secondary" onClick={onAdd}>
              + Add
            </Button>
          )}
        </Grid>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="All Appointments" value="Appointments" />
            <Tab label="Issues" value="Issues" />
          </TabList>
        </Box>
        <TabPanel value="Appointments">
          <Stack spacing={2} sx={{ mt: 2 }}>
            <AppointmentsFilter
              isCompleteHidden={isCompleteHidden}
              changeHandler={changeHandler}
            />
            <AppointmentsGrid isCompleteHidden={isCompleteHidden} />
          </Stack>
        </TabPanel>
        <TabPanel value="Issues">
          <IssuesGrid />
        </TabPanel>
      </TabContext>
      <AddAppointmentModal
        doneAdding={onDoneAdding}
        show={showAddAppointmentModal}
      />
    </React.Fragment>
  );
};

export default AppointmentsPage;
