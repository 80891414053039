import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  SecaType,
  TestType,
  UpdateTestResultType,
} from "../../../../../redux/features/tests/types";
import * as Yup from "yup";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import {
  checkNumberIsEmpty,
  getAgeFromDateOfBirth,
  validHeight,
  validWeight,
} from "../../../../../_shared/utils/uiHelpers";
import ErrorPopup from "../error-popup";

type SecaTestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

const inputCheck = (formik: FormikProps<any>) => {
  const ERROR_MARGIN = 1;
  const WAIST_MIN_IN = 25; // in inches
  const WAIST_MAX_IN = 40; // in inches
  const BODY_WATER_MIN = 45; // in percent
  const BODY_WATER_MAX = 65; // in percent

  let validForm: boolean = true;
  const errors: FormikErrors<SecaType> = {};
  const error_message = " ";

  if (!validHeight(formik.values.height)) {
    errors.height = error_message;
  }
  if (!validWeight(formik.values.total_mass)) {
    errors.total_mass = error_message;
  }

  if (formik.values.body_water_percentage != null) {
    if (
      formik.values.body_water_percentage < BODY_WATER_MIN ||
      formik.values.body_water_percentage > BODY_WATER_MAX
    ) {
      errors.body_water_percentage = error_message;
    }
  }

  if (
    formik.values.fat_mass != null &&
    formik.values.fat_free_mass != null &&
    formik.values.total_mass != null
  ) {
    // fat_mass < fat_free_mass < total_mass
    let fat_mass = formik.values.fat_mass * 1.0;
    let fat_free_mass = formik.values.fat_free_mass * 1.0;
    let total_mass = formik.values.total_mass * 1.0;
    let hasError = false;

    let sort = [fat_mass, fat_free_mass, total_mass];
    sort.sort((a, b) => {
      return a - b;
    });

    if (
      sort[0] !== fat_mass ||
      sort[1] !== fat_free_mass ||
      sort[2] !== total_mass
    ) {
      hasError = true;
    }

    // total_mass = fat_mass + fat_free_mass
    let total_mass_check = fat_mass + fat_free_mass;
    if (Math.abs(total_mass - total_mass_check) > ERROR_MARGIN) {
      hasError = true;
    }

    if (hasError) {
      errors.fat_mass = error_message;
      errors.fat_free_mass = error_message;
      errors.total_mass = error_message;
    }
  }

  // fmi = fat_mass / height^2
  if (
    formik.values.fmi != null &&
    formik.values.fat_mass != null &&
    formik.values.height != null
  ) {
    let fat_mass_kg = formik.values.fat_mass / 2.205;
    let height_m = formik.values.height / 39.37;
    let fmi_check = fat_mass_kg / (height_m * height_m);

    if (Math.abs(formik.values.fmi - fmi_check) > ERROR_MARGIN) {
      errors.fmi = error_message;
      errors.fat_mass = error_message;
      errors.height = error_message;
    }
  }

  if (formik.values.waist_circumference != null) {
    if (
      formik.values.waist_circumference < WAIST_MIN_IN ||
      formik.values.waist_circumference > WAIST_MAX_IN
    ) {
      errors.waist_circumference = error_message;
    }
  }

  if (Object.keys(errors).length !== 0) {
    console.log(errors);
    formik.setErrors({ ...errors });
    validForm = false;
  }
  return validForm;
};

const HyrdostaticTest = (props: SecaTestType) => {
  const [results, setResults] = useState<SecaType>();
  const [errorPopup, displayErrorPopup] = useState<boolean>(false);

  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
        setSubmitAttempt(0);
      });
  };

  const onErrorPopupClose = () => {
    displayErrorPopup(false);
  };

  const onErrorOverride = () => {
    displayErrorPopup(false);
    formik.setSubmitting(true);
    submitHandler(formik.values);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      height: results?.height || 0,
      total_mass: results?.total_mass || 0,
      fat_free_mass: results?.fat_free_mass || 0,
      fat_mass: results?.fat_mass || 0,
      fmi: results?.fmi || 0,
      vat: results?.vat || 0,
      waist_circumference: results?.waist_circumference || 0,
      body_water_percentage: results?.body_water_percentage || 0,
      ecw_tbw_ratio: results?.ecw_tbw_ratio || 0,
      original_data: results?.original_data || "",
    },
    validationSchema: Yup.object({
      original_data: Yup.string().url(
        "You must enter a valid URL with http(s) in the URL."
      ),
      age: Yup.number().positive(),
      height: Yup.number().typeError("Please enter a valid number."),
      total_mass: Yup.number()
        .typeError("Please enter a valid number.")
        .positive("Total mass must be greater than 0"),
      fat_free_mass: Yup.number().typeError("Please enter a valid number."),
      fat_mass: Yup.number().typeError("Please enter a valid number."),
      fmi: Yup.number().typeError("Please enter a valid number."),
      vat: Yup.number().typeError("Please enter a valid number."),
      waist_circumference: Yup.number().typeError(
        "Please enter a valid number."
      ),
      body_water_percentage: Yup.number().typeError(
        "Please enter a valid number."
      ),
      ecw_tbw_ratio: Yup.number().typeError("Please enter a valid number."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        let validForm = inputCheck(formik);

        if (validForm) {
          helpers.setSubmitting(true);
          submitHandler(values);
        } else {
          setSubmitAttempt(submitAttempt + 1);
          displayErrorPopup(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  useEffect(() => {
    if (
      !checkNumberIsEmpty(formik.values.fat_mass) &&
      !checkNumberIsEmpty(formik.values.height)
    ) {
      let fat_mass_kg = formik.values.fat_mass / 2.205;
      let height_m = formik.values.height / 39.37;
      let fmi = fat_mass_kg / (height_m * height_m);
      formik.setFieldValue("fmi", fmi);
    } else {
      formik.setFieldValue("fmi", 0);
    }
  }, [formik.values.fat_mass, formik.values.height]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Age"
            name="age"
            required
            error={Boolean(formik.touched.age && formik.errors.age)}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.age}
            disabled={!props.isEditing}
            helperText="Age derived from DOB"
          />
          <FormControl fullWidth required>
            <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
            <Select
              labelId="genderAtBirthLabel"
              name="gender"
              label="Gender at birth"
              value={formik.values.gender}
              error={Boolean(formik.touched?.gender && formik.errors?.gender)}
              onChange={formik.handleChange}
              disabled={!props.isEditing}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
            </Select>
          </FormControl>
          <TextField
            error={Boolean(formik.touched.height && formik.errors.height)}
            fullWidth
            helperText={formik.touched.height && formik.errors.height}
            label="Height in Inches"
            name="height"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.height}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inches</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.total_mass && formik.errors.total_mass
            )}
            fullWidth
            helperText={formik.touched.total_mass && formik.errors.total_mass}
            label="Total Mass"
            name="total_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.total_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.fat_free_mass && formik.errors.fat_free_mass
            )}
            fullWidth
            helperText={
              formik.touched.fat_free_mass && formik.errors.fat_free_mass
            }
            label="Fat Free Mass"
            name="fat_free_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fat_free_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.fat_mass && formik.errors.fat_mass)}
            fullWidth
            helperText={formik.touched.fat_mass && formik.errors.fat_mass}
            label="Fat Mass"
            name="fat_mass"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fat_mass}
            InputProps={{
              endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.fmi && formik.errors.fmi)}
            fullWidth
            helperText={formik.touched.fmi && formik.errors.fmi}
            label="FMI"
            name="fmi"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.fmi}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">kg/m2</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(formik.touched.vat && formik.errors.vat)}
            fullWidth
            helperText={formik.touched.vat && formik.errors.vat}
            label="VAT"
            name="vat"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.vat}
            InputProps={{
              endAdornment: <InputAdornment position="end">L</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.waist_circumference &&
                formik.errors.waist_circumference
            )}
            fullWidth
            helperText={
              formik.touched.waist_circumference &&
              formik.errors.waist_circumference
            }
            label="Waist Circumference"
            name="waist_circumference"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.waist_circumference}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inches</InputAdornment>
              ),
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.body_water_percentage &&
                formik.errors.body_water_percentage
            )}
            fullWidth
            helperText={
              formik.touched.body_water_percentage &&
              formik.errors.body_water_percentage
            }
            label="Body Water Percentage"
            name="body_water_percentage"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.body_water_percentage}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.ecw_tbw_ratio && formik.errors.ecw_tbw_ratio
            )}
            fullWidth
            helperText={
              formik.touched.ecw_tbw_ratio && formik.errors.ecw_tbw_ratio
            }
            label="ECT/TBW Ratio"
            name="ecw_tbw_ratio"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.ecw_tbw_ratio}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputMode: "numeric",
            }}
            disabled={!props.isEditing}
          />
          <TextField
            error={Boolean(
              formik.touched.original_data && formik.errors.original_data
            )}
            fullWidth
            label="Link to Original Data"
            name="original_data"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.original_data}
            disabled={!props.isEditing}
            helperText={
              formik.touched.original_data && formik.errors.original_data
            }
          />
        </Stack>
        {props.isEditing && (
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mt: 3, justifyContent: "flex-end" }}
            >
              <Button
                variant="text"
                onClick={handleCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                disabled={isLoading}
                type="submit"
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </Stack>
            <p>&nbsp;</p>
          </Box>
        )}
        <ErrorPopup
          show={errorPopup}
          submits={submitAttempt}
          onClose={onErrorPopupClose}
          onOverride={onErrorOverride}
        />
      </form>
    </Container>
  );
};

export default HyrdostaticTest;
