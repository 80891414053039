import { Box, Button, Paper } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { APP } from "../../../_shared/utils/_urls";
import { logout } from "../../../redux/features/auth/currentUser.slice";

const Unauthorized401Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = (e: any) => {
    e.preventDefault();
    dispatch(logout());
    navigate(APP.LOGIN);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Paper sx={{ width: 300, backgroundColor: "#ffffff" }}>
          <Box sx={{ p: 2 }}>
            <p>UNAUTHORIZED</p>
            <p>You are not authorized to view the page.</p>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" onClick={onLogout}>
              Logout
            </Button>
          </Box>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default Unauthorized401Page;
