import { Slide, ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { theme } from "./_shared/theme";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import React from "react";
import AuthLayout from "./_shared/layouts/authLayout";
import { LicenseInfo } from "@mui/x-license-pro";
import LoginPage from "./pages/auth/login";
import AppointmentsPage from "./pages/appointments";
import AppointmentDetailPage from "./pages/appointments/detail";
import Layout from "./_shared/layouts/layout";
import CustomersPage from "./pages/customers";
import CustomerDetailPage from "./pages/customers/detail";

import PartnersPage from "./pages/partners";
import PartnerDetailPage from "./pages/partners/detail";

import ProfilePage from "./pages/profile";
import Unauthorized401Page from "./pages/errors/401";
import UpdatePasswordPage from "./pages/auth/update-password";
import VerifyLogin from "./pages/auth/verify-login";
import ProductsPage from "./pages/commerce/product-groups";
import ProductGroupDetailPage from "./pages/commerce/product-groups/detail";
import SettingsPage from "./pages/settings";
import OrdersPage from "./pages/commerce/orders";
import PromosPage from "./pages/commerce/promo-codes";
import PromoDetailPage from "./pages/commerce/promo-codes/detail";
import OrderDetailPage from "./pages/commerce/orders/detail";
import GiftCertificatesPage from "./pages/commerce/gift-certifcates";
import GiftCertificateDetailPage from "./pages/commerce/gift-certifcates/detail";
import FormTemplatesPage from "./pages/forms/templates";
import FormTemplateDetailPage from "./pages/forms/templates/detail";
import FormSignPage from "./pages/forms/sign";

LicenseInfo.setLicenseKey(
  "4fb4f055ad13bd9b99ad06c0adf09c71Tz04MTUxOCxFPTE3MzU5NDMyMTIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  let persistor = persistStore(store);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ToastContainer
              containerId={1}
              transition={Slide}
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              enableMultiContainer={false}
              closeOnClick={true}
              rtl={false}
              draggable={false}
              pauseOnHover={true}
              position="top-right"
            />
            <Routes>
              <Route
                path="/"
                element={
                  <Layout>
                    <AppointmentsPage />
                  </Layout>
                }
              />
              <Route
                path="/appointments"
                element={
                  <Layout>
                    <AppointmentsPage />
                  </Layout>
                }
              />
              <Route
                path="/appointments/:appointment_id"
                element={
                  <Layout>
                    <AppointmentDetailPage />
                  </Layout>
                }
              />
              <Route
                path="/customers"
                element={
                  <Layout>
                    <CustomersPage />
                  </Layout>
                }
              />
              <Route
                path="/customers/:customer_id"
                element={
                  <Layout>
                    <CustomerDetailPage />
                  </Layout>
                }
              />
              <Route
                path="/partners"
                element={
                  <Layout>
                    <PartnersPage />
                  </Layout>
                }
              />
              <Route
                path="/partners/:partner_id"
                element={
                  <Layout>
                    <PartnerDetailPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/orders"
                element={
                  <Layout>
                    <OrdersPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/orders/:order_id"
                element={
                  <Layout>
                    <OrderDetailPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/products"
                element={
                  <Layout>
                    <ProductsPage />
                  </Layout>
                }
              />
              <Route
                path="/commerce/products/groups/:product_group_id"
                element={
                  <Layout>
                    <ProductGroupDetailPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/promo-codes"
                element={
                  <Layout>
                    <PromosPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/promo-codes/:promo_id"
                element={
                  <Layout>
                    <PromoDetailPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/gift-certificates"
                element={
                  <Layout>
                    <GiftCertificatesPage />
                  </Layout>
                }
              />
              <Route
                path="commerce/gift-certificates/:gift_certificate_id"
                element={
                  <Layout>
                    <GiftCertificateDetailPage />
                  </Layout>
                }
              />
              <Route
                path="forms/templates/"
                element={
                  <Layout>
                    <FormTemplatesPage />
                  </Layout>
                }
              />
              <Route
                path="forms/templates/:form_template_id"
                element={
                  <Layout>
                    <FormTemplateDetailPage />
                  </Layout>
                }
              />
              <Route
                path="/settings"
                element={
                  <Layout>
                    <SettingsPage />
                  </Layout>
                }
              />
              <Route
                path="/profile"
                element={
                  <Layout>
                    <ProfilePage />
                  </Layout>
                }
              />
            </Routes>
            <Routes>
              <Route
                path="/errors/401"
                element={
                  <AuthLayout>
                    <Unauthorized401Page />
                  </AuthLayout>
                }
              />
              <Route
                path="/auth/login"
                element={
                  <AuthLayout>
                    <LoginPage />
                  </AuthLayout>
                }
              />
              <Route
                path="/auth/update-password"
                element={
                  <AuthLayout>
                    <UpdatePasswordPage />
                  </AuthLayout>
                }
              />
              <Route
                path="/auth/verify-login/:hash"
                element={
                  <AuthLayout>
                    <VerifyLogin />
                  </AuthLayout>
                }
              />
              <Route
                path="/forms/sign"
                element={
                  <AuthLayout>
                    <FormSignPage />
                  </AuthLayout>
                }
              />
            </Routes>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
