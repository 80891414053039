import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useState } from "react";
import GiftCertificatesGrid from "./components/GiftCertificatesGrid";
import AddGiftCertificateModal from "./components/GiftCertificatesAdd";

const GiftCertificatesPage = () => {
  const [showAddGiftCertificateModal, setShowAddGiftCertificateModal] =
    useState(false);
  const onAdd = (e: any) => {
    e.preventDefault();
    setShowAddGiftCertificateModal(true);
  };

  const onDoneAdding = () => {
    setShowAddGiftCertificateModal(false);
  };

  return (
    <React.Fragment>
      <Container maxWidth={false}>
        <Grid container columns={2}>
          <Grid item sm={1}>
            <Breadcrumbs sx={{ mt: 1 }}>
              <Typography color="text.primary">Commerce</Typography>
              <Typography color="text.primary">Gift Certificates</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="text" color="secondary" onClick={onAdd}>
              + Add
            </Button>
          </Grid>
        </Grid>
        <GiftCertificatesGrid />
      </Container>
      <AddGiftCertificateModal
        doneAdding={onDoneAdding}
        show={showAddGiftCertificateModal}
      />
    </React.Fragment>
  );
};

export default GiftCertificatesPage;
