import {
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormikErrors, FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  TestType,
  UpdateTestResultType,
  VO2MaxType,
} from "../../../../../redux/features/tests/types";
import * as Yup from "yup";
import styles from "./vo2.module.css";
import { merge } from "lodash";
import { useUpdateTestResultMutation } from "../../../../../redux/features/tests/testsApi";
import { LoadingButton } from "@mui/lab";
import { checkNumberIsEmpty, getAgeFromDateOfBirth, roundOff, validHeight, validWeight } from "../../../../../_shared/utils/uiHelpers";
import ErrorPopup from "../error-popup";

type VO2TestType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
};

const inputCheck = (formik : FormikProps<any>) => {
  const START_HR_MIN = 60;
  const START_HR_MAX = 120;
  const MAX_HR_MIN = 100;
  const MAX_HR_MAX = 220;
  const VO2_CONST = 3.5;
  const ERROR_MARGIN = 1;

  let validForm : boolean = true;
  const errors : FormikErrors<VO2MaxType> = {};
  const error_message = " ";

  if (!validHeight(formik.values.height)) {
    errors.height = error_message;
  }
  if (!validWeight(formik.values.weight)) {
    errors.weight = error_message;
  }

  // START_HR_MIN <= starting_hr <= START_HR_MAX
  if (formik.values.starting_hr != null) {
    if (formik.values.starting_hr < START_HR_MIN || formik.values.starting_hr > START_HR_MAX) {
      errors.starting_hr = error_message;
    }
  }

  // MAX_HR_MIN <= max_hr <= MAX_HR_MAX
  if (formik.values.max_hr != null) {
    if (formik.values.max_hr < MAX_HR_MIN || formik.values.max_hr > MAX_HR_MAX) {
      errors.max_hr = error_message; 
    }
  }

  if (formik.values.starting_hr != null && formik.values.aerobic_hr != null && formik.values.max_hr != null) {
    let starting_hr = formik.values.starting_hr * 1.0;
    let max_hr = formik.values.max_hr * 1.0;
    let hasError = false;
    let hasAerobic = false;
    let hasAnaerobic = false;
    let hrs : number[] = [
      starting_hr,
      max_hr,
    ];

    if (formik.values.anaerobic_hr != null && formik.values.anaerobic_hr != 0) {
      hasAnaerobic = true;
      let anaerobic_hr = formik.values.anaerobic_hr * 1.0;
      hrs.push(anaerobic_hr);
    } 
    if (formik.values.aerobic_hr != null && formik.values.aerobic_hr != 0) {
      hasAerobic = true;
      let aerobic_hr = formik.values.aerobic_hr * 1.0;
      hrs.push(aerobic_hr);
    } 
    hrs.sort((a, b) => { return a - b });
    
    // starting_hr < aerobic_hr? < anaerobic_hr? < max_hr
    if (hrs[0] !== starting_hr) {
      hasError = true;
    }
    if (hasAerobic && hasAnaerobic) {
      if (hrs[1] !== formik.values.aerobic_hr * 1.0 || hrs[2] !== formik.values.anaerobic_hr * 1.0) {
        hasError = true;
        errors.aerobic_hr = error_message;
        errors.anaerobic_hr = error_message;
      }
    } else {
      if (hasAerobic) {
        if (hrs[1] !== formik.values.aerobic_hr * 1.0) {
          hasError = true;
          errors.aerobic_hr = error_message;
        }
      }
      if (hasAnaerobic) {
        if (hrs[1] !== formik.values.anaerobic_hr * 1.0) {
          hasError = true;
          errors.anaerobic_hr = error_message;
        }
      }
    }
    if (hrs[hrs.length - 1] !== max_hr) {
      hasError = true;
    }
    if (hasError) {
        errors.starting_hr = error_message;
        errors.max_hr = error_message;
    }
  }

  // hrr_one_min > hrr_two_min
  if (formik.values.hrr_one_min != null && formik.values.hrr_two_min != null) {
    if (formik.values.hrr_one_min < formik.values.hrr_two_min) {
      errors.hrr_one_min = error_message;
      errors.hrr_two_min = error_message;
    }
  }

  if (formik.values.starting_vo2 != null && formik.values.max_vo2 != null) {
    let starting_vo2 = formik.values.starting_vo2 * 1.0;
    let max_vo2 = formik.values.max_vo2 * 1.0;
    let hasError = false;
    let hasAerobic = false;
    let hasAnaerobic = false;
    let vo2s : number[] = [
      starting_vo2,
      max_vo2,
    ];

    if (formik.values.anaerobic_vo2 != null && formik.values.anaerobic_vo2 != 0) {
      hasAnaerobic = true;
      let anaerobic_vo2 = formik.values.anaerobic_vo2 * 1.0;
      vo2s.push(anaerobic_vo2);
    } 
    if (formik.values.aerobic_vo2 != null && formik.values.aerobic_vo2 != 0) {
      hasAerobic = true;
      let aerobic_vo2 = formik.values.aerobic_vo2 * 1.0;
      vo2s.push(aerobic_vo2);
    }

    vo2s.sort((a, b) => { return a - b });

    // starting_vo2 < aerobic_vo2? < anaerobic_vo2? < max_vo2
    if (vo2s[0] !== starting_vo2) {
      hasError = true;
    }
    if (hasAerobic && hasAnaerobic) {
      if (vo2s[1] !== formik.values.aerobic_vo2 * 1.0 || vo2s[2] !== formik.values.anaerobic_vo2 * 1.0) {
        hasError = true;
        errors.aerobic_vo2 = error_message;
        errors.anaerobic_vo2 = error_message;
      }
    } else {
      if (hasAerobic) {
        if (vo2s[1] !== formik.values.aerobic_vo2 * 1.0) {
          hasError = true;
          errors.aerobic_vo2 = error_message;
        }
      }
      if (hasAnaerobic) {
        if (vo2s[1] !== formik.values.anaerobic_vo2 * 1.0) {
          hasError = true;
          errors.anaerobic_vo2 = error_message;
        }
      }
    }
    if (vo2s[vo2s.length - 1] !== max_vo2) {
      hasError = true;
    }

    if (hasError) {
      errors.starting_vo2 = error_message;
      errors.max_vo2 = error_message;
    }
    console.log(vo2s);
  }

  // starting_mets = starting_vo2 / 3.5
  if (formik.values.starting_mets != null && formik.values.starting_vo2 != null) {
    let metsCheck = formik.values.starting_vo2 / VO2_CONST;
    if (Math.abs(formik.values.starting_mets - metsCheck) > ERROR_MARGIN) {
      errors.starting_vo2 = error_message;
      errors.starting_mets = error_message;
    }
  }
  if (formik.values.aerobic_mets != null && formik.values.aerobic_vo2 != null) {
    let metsCheck = formik.values.aerobic_vo2 / VO2_CONST;
    if (Math.abs(formik.values.aerobic_mets - metsCheck) > ERROR_MARGIN) {
      errors.aerobic_vo2 = error_message;
      errors.aerobic_mets = error_message;
    }
  }
  if (formik.values.peak_mets != null && formik.values.max_vo2 != null) {
    let metsCheck = formik.values.max_vo2 / VO2_CONST;
    if (Math.abs(formik.values.peak_mets - metsCheck) > ERROR_MARGIN) {
      errors.max_vo2 = error_message;
      errors.peak_mets = error_message;
    }
  }

  // starting_mets < aerobic_mets? < peak_mets
  if (formik.values.starting_mets != null && formik.values.peak_mets != null) {
    let starting_mets = formik.values.starting_mets * 1.0;
    let peak_mets = formik.values.peak_mets * 1.0;

    if (formik.values.aerobic_mets != null) {
      let aerobic_mets = formik.values.aerobic_mets * 1.0;
      let mets = [
        starting_mets,
        aerobic_mets,
        peak_mets,
      ];
      mets.sort((a, b) => { return a - b });
      if (mets[0] !== starting_mets || mets[1] !== aerobic_mets || mets[2] !== peak_mets) {
        errors.starting_mets = error_message;
        errors.aerobic_mets = error_message;
        errors.peak_mets = error_message;
      }
      console.log(mets);
    } else {
      if (starting_mets >= peak_mets) {
        errors.starting_mets = error_message;
        errors.peak_mets = error_message
      }
    }
  }

  if (Object.keys(errors).length !== 0) {
    console.log(errors);
    formik.setErrors({...errors});
    validForm = false;
  }
  return validForm;
}
const VO2Test = (props: VO2TestType) => {
  const [results, setResults] = useState<VO2MaxType>();
  const [errorPopup, displayErrorPopup] = useState<boolean>(false);
  
  // keep track of submit attempts, resets after successful submit
  const [submitAttempt, setSubmitAttempt] = useState<number>(0);

  useEffect(() => {
    if (props?.test?.results !== undefined) {
      setResults(props?.test?.results as any);
    }
  }, [props]);

  const [updateTestResult, { isLoading }] = useUpdateTestResultMutation();

  const handleCancel = () => {
    props.doneEditing(false);
  };

  const submitHandler = (values: any) => {
    const payload = {
      appointment_id: props.test?.id!,
      results: merge({}, results, values),
    } as UpdateTestResultType;

    updateTestResult(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
        setSubmitAttempt(0);
      });
  };

  const onErrorPopupClose = () => {
    displayErrorPopup(false);
  }

  const onErrorOverride = () => {
    displayErrorPopup(false);
    formik.setSubmitting(true);
    submitHandler(formik.values);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      age:
        results?.age ||
        getAgeFromDateOfBirth(props.test?.customer_profile?.dob) ||
        0,
      weight: results?.weight || 0,
      height: results?.height || 0,
      gender: results?.gender || props.test?.customer_profile?.gender,
      starting_vo2: results?.starting_vo2 || 0,
      starting_hr: results?.starting_hr || 0,
      aerobic_vo2: results?.aerobic_vo2 || null,
      aerobic_hr: results?.aerobic_hr || null,
      anaerobic_vo2: results?.anaerobic_vo2 || null,
      anaerobic_hr: results?.anaerobic_hr || null,
      max_vo2: results?.max_vo2 || 0,
      max_hr: results?.max_hr || 0,
      hrr_one_min: results?.hrr_one_min || null,
      hrr_two_min: results?.hrr_two_min || null,
      starting_mets: results?.starting_mets || 0,
      aerobic_mets: results?.aerobic_mets || null,
      peak_mets: results?.peak_mets || 0,
      test_type: results?.test_type || "",
    },
    validationSchema: Yup.object({
      age: Yup.number().positive(),
      weight: Yup.number().typeError("Please enter a valid number."),
      height: Yup.number().typeError("Please enter a valid number."),

      starting_vo2: Yup.number().typeError("Please enter a valid number."),
      starting_hr: Yup.number().typeError("Please enter a valid number."),
      aerobic_vo2: Yup.number().nullable().typeError("Please enter a valid number."),
      aerobic_hr: Yup.number().nullable().typeError("Please enter a valid number."),
      anaerobic_vo2: Yup.number().nullable().typeError("Please enter a valid number."),
      anaerobic_hr: Yup.number().nullable().typeError("Please enter a valid number."),
      max_vo2: Yup.number().typeError("Please enter a valid number."),
      max_hr: Yup.number().typeError("Please enter a valid number."),
      hrr_one_min: Yup.number().nullable().typeError("Please enter a valid number."),
      hrr_two_min: Yup.number().nullable().typeError("Please enter a valid number."),
      starting_mets: Yup.number().typeError("Please enter a valid number."),
      aerobic_mets: Yup.number().nullable().typeError("Please enter a valid number."),
      peak_mets: Yup.number().typeError("Please enter a valid number."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        let validForm = inputCheck(formik);

        if (validForm) {
          helpers.setSubmitting(true);
          submitHandler(values);
        } else {
          setSubmitAttempt(submitAttempt + 1);
          displayErrorPopup(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    validateOnBlur:false,
    validateOnChange:false,
  });

  useEffect(() => {
    if (!checkNumberIsEmpty(formik.values.starting_vo2)) {
      formik.setFieldValue("starting_mets",
        roundOff(formik.values.starting_vo2! / 3.5, 1)
      );
    }
  }, [formik.values.starting_vo2]);

  useEffect(() => {
    if (!checkNumberIsEmpty(formik.values.aerobic_vo2)) {
      formik.setFieldValue("aerobic_mets",
        roundOff(formik.values.aerobic_vo2! / 3.5, 1)
      );
    }
  }, [formik.values.aerobic_vo2]);

  useEffect(() => {
    if (!checkNumberIsEmpty(formik.values.max_vo2)) {
      formik.setFieldValue("peak_mets",
        roundOff(formik.values.max_vo2! / 3.5, 1)
      );
    }
  }, [formik.values.max_vo2]);

  if (results !== undefined) {
    return (
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Age"
              name="age"
              required
              error={Boolean(formik.touched.age && formik.errors.age)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.age}
              disabled={!props.isEditing}
              helperText="Age derived from DOB"
            />
            <TextField
              error={Boolean(formik.touched.height && formik.errors.height)}
              fullWidth
              helperText={formik.touched.height && formik.errors.height}
              label="Height in Inches"
              name="height"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.height}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">inches</InputAdornment>
                ),
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(formik.touched.weight && formik.errors.weight)}
              fullWidth
              helperText={formik.touched.weight && formik.errors.weight}
              label="Weight"
              name="weight"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.weight}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">lbs</InputAdornment>
                ),
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <FormControl fullWidth required>
              <InputLabel id="genderAtBirthLabel">Gender at birth</InputLabel>
              <Select
                labelId="genderAtBirthLabel"
                name="gender"
                label="Gender at birth"
                value={formik.values.gender}
                error={Boolean(formik.touched?.gender && formik.errors?.gender)}
                onChange={formik.handleChange}
                disabled={!props.isEditing}
              >
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={Boolean(
                formik.touched.starting_vo2 && formik.errors.starting_vo2
              )}
              fullWidth
              helperText={
                formik.touched.starting_vo2 && formik.errors.starting_vo2
              }
              label="Starting VO2"
              name="starting_vo2"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.starting_vo2}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.starting_hr && formik.errors.starting_hr
              )}
              fullWidth
              helperText={
                formik.touched.starting_hr && formik.errors.starting_hr
              }
              label="Starting HR"
              name="starting_hr"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.starting_hr}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.aerobic_vo2 && formik.errors.aerobic_vo2
              )}
              fullWidth
              helperText={
                formik.touched.aerobic_vo2 && formik.errors.aerobic_vo2
              }
              label="Aerobic VO2"
              name="aerobic_vo2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.aerobic_vo2}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.aerobic_hr && formik.errors.aerobic_hr
              )}
              fullWidth
              helperText={formik.touched.aerobic_hr && formik.errors.aerobic_hr}
              label="Aerobic HR"
              name="aerobic_hr"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.aerobic_hr}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.anaerobic_vo2 && formik.errors.anaerobic_vo2
              )}
              fullWidth
              helperText={
                formik.touched.anaerobic_vo2 && formik.errors.anaerobic_vo2
              }
              label="Anaerobic VO2"
              name="anaerobic_vo2"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.anaerobic_vo2}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.anaerobic_hr && formik.errors.anaerobic_hr
              )}
              fullWidth
              helperText={
                formik.touched.anaerobic_hr && formik.errors.anaerobic_hr
              }
              label="Anaerobic HR"
              name="anaerobic_hr"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.anaerobic_hr}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(formik.touched.max_vo2 && formik.errors.max_vo2)}
              fullWidth
              helperText={formik.touched.max_vo2 && formik.errors.max_vo2}
              label="Max VO2"
              name="max_vo2"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.max_vo2}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(formik.touched.max_hr && formik.errors.max_hr)}
              fullWidth
              helperText={formik.touched.max_hr && formik.errors.max_hr}
              label="Max HR"
              name="max_hr"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.max_hr}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.hrr_one_min && formik.errors.hrr_one_min
              )}
              fullWidth
              helperText={
                formik.touched.hrr_one_min && formik.errors.hrr_one_min
              }
              label="HRR One Minute"
              name="hrr_one_min"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.hrr_one_min}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.hrr_two_min && formik.errors.hrr_two_min
              )}
              fullWidth
              helperText={
                formik.touched.hrr_two_min && formik.errors.hrr_two_min
              }
              label="HRR Two Minute"
              name="hrr_two_min"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.hrr_two_min}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.starting_mets && formik.errors.starting_mets
              )}
              fullWidth
              helperText={
                formik.touched.starting_mets && formik.errors.starting_mets
              }
              label="Starting Mets"
              name="starting_mets"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.starting_mets}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.aerobic_mets && formik.errors.aerobic_mets
              )}
              fullWidth
              helperText={
                formik.touched.aerobic_mets && formik.errors.aerobic_mets
              }
              label="Aerobic Mets"
              name="aerobic_mets"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.aerobic_mets}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <TextField
              error={Boolean(
                formik.touched.peak_mets && formik.errors.peak_mets
              )}
              fullWidth
              helperText={formik.touched.peak_mets && formik.errors.peak_mets}
              label="Peak Mets"
              name="peak_mets"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.peak_mets}
              InputProps={{
                inputMode: "numeric",
              }}
              disabled={!props.isEditing}
            />
            <FormControl fullWidth required>
              <InputLabel id="editTestType">Test Type</InputLabel>
              <Select
                labelId="editTestType"
                name="test_type"
                label="Test Type"
                value={formik.values.test_type}
                error={Boolean(
                  formik.touched.test_type && formik.errors.test_type
                )}
                onChange={formik.handleChange}
                disabled={!props.isEditing}
              >
                <MenuItem value={""} disabled>
                  Select a test type
                </MenuItem>
                <MenuItem value={"Treadmill"}>Treadmill</MenuItem>
                <MenuItem value={"Cycle"}>Cycle</MenuItem>
                <MenuItem value={"Rower"}>Rower</MenuItem>
                <MenuItem value={"Elliptical"}>Elliptical</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          {props.isEditing && (
            <Box sx={{ width: "100%" }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 3, justifyContent: "flex-end" }}
              >
                <Button
                  variant="text"
                  onClick={handleCancel}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              </Stack>
              <p>&nbsp;</p>
            </Box>
          )}
          <ErrorPopup
            show={errorPopup}
            submits={submitAttempt}
            onClose={onErrorPopupClose}
            onOverride={onErrorOverride}
          />
        </form>
      </Container>
    );
  } else {
    return <p>Loading ...</p>;
  }
};

export default VO2Test;
