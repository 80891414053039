import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Stack, Switch } from "@mui/material";
import { isNull, merge } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { ProductGroupType } from "../../../../../../redux/features/commerce/products/types";
import { useUpdateProductGroupMutation } from "../../../../../../redux/features/commerce/products/productGroupsApi";

type UpdateProductGroupProps = {
  show: boolean;
  productGroupToUpdate: ProductGroupType | Record<string, any> | undefined;
  doneEditing: (reload: boolean) => void;
};

const UpdateProductGroupModal = (props: UpdateProductGroupProps) => {
  const handleClose = () => {
    props.doneEditing(false);
  };
  const [productGroupToUpdate, setProductGroupToUpdate] = useState<
    ProductGroupType | Record<string, any>
  >();
  const [updateProductGroup, { isLoading }] = useUpdateProductGroupMutation();

  const submitHandler = (values: any) => {
    const payload = merge({}, productGroupToUpdate, values);
    updateProductGroup(payload)
      .unwrap()
      .then(() => {
        formik.setSubmitting(false);
        props.doneEditing(true);
      });
  };

  const handleIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("is_active", event.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: productGroupToUpdate?.name || "",
      description: productGroupToUpdate?.description || "",
      is_active: productGroupToUpdate?.is_active,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").max(255),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setProductGroupToUpdate(props.productGroupToUpdate);
  }, [props.productGroupToUpdate]);

  if (formik !== undefined) {
    return (
      <Dialog open={props.show} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Update Product Group</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack spacing={2} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                required
                error={Boolean(formik.touched.name && formik.errors.name)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <TextField
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                fullWidth
                multiline={true}
                label="Description"
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.description}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.is_active}
                    onChange={handleIsActive}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Active"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
            <LoadingButton
              disabled={isLoading}
              type="submit"
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  } else {
    return <p>There was an issue loading the record.</p>;
  }
};

export default UpdateProductGroupModal;
