import _ from "lodash";
import { ProductType } from "../../../../../../redux/features/commerce/products/types";
import { Box, Chip, Typography } from "@mui/material";

type ProductsList = {
  products: ProductType[] | undefined;
};

const ProductsList = (props: ProductsList) => {
  const product_groups = _.groupBy(props.products, "product_group_id");

  return (
    <Box>
      {_.map(_.keys(product_groups), (key) => {
        const group = product_groups[key];
        console.log(group[0].product_group_name);
        return (
          <Box>
            <Typography variant="h6">{group[0].product_group_name}</Typography>
            <Box sx={{ ml: 1 }}>
              {_.map(group, (p) => {
                return (
                  <Chip
                    key={p.id}
                    label={p.name}
                    sx={{ backgroundColor: p.color, m: 1 }}
                  />
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ProductsList;
