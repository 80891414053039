import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, Stack, Typography } from "@mui/material";
import { APP } from "../../../../../_shared/utils/_urls";
import { ProductGroupType } from "../../../../../redux/features/commerce/products/types";
import React from "react";
import { useGetFormTemplatesQuery } from "../../../../../redux/features/forms/formTemplatesApi";
import { FormTemplateType } from "../../../../../redux/features/forms/types";

const FormTemplatesGrid = () => {
  const navigate = useNavigate();
  const [formTemplates, setFormTemplates] = useState<FormTemplateType[]>([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Form Template",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Stack>
            <Link
              onClick={() => {
                navigate(`${APP.FORMTEMPLATES}/${params.row.id}`);
              }}
            >
              {params.value}
            </Link>
          </Stack>
        </React.Fragment>
      ),
    },
    {
      field: "form_type",
      headerName: "Type",
      flex: 1,
      width: 50,
    },
    {
      field: "template_id",
      headerName: "Hello Sign Template",
      flex: 1,
    },
    {
      field: "replace",
      headerName: "Replace",
      flex: 1,
      width: 50,
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.created_at)} by ${
          params.row.created_by_name
        }`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];
  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetFormTemplatesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setFormTemplates(data);
    }
  });

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={formTemplates}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </div>
  );
};

export default FormTemplatesGrid;
