import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LoginType, LoginResponseType, ResetPasswordResultType, ResetPasswordRequestType, VerifyLoginResponseType, VerifyLoginType } from './types';
import { setCurrentUser } from './currentUser.slice';

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/auth/`,
        prepareHeaders: (headers) => {
            headers.set('x-api-key', API_KEY);
            headers.set('app', 'ADMIN');
            return headers;
        }
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation<LoginResponseType, LoginType>
        ({
            query(data) {
                return {
                    url: 'login',
                    method: 'POST',
                    body: data,
                };
            },
            transformResponse: (result: any): LoginResponseType => {
                return result.data;
            }
        }),
        verifyLoginUser: builder.mutation<VerifyLoginResponseType, VerifyLoginType>
        ({
            query(data) {
                return {
                    url: 'verify-login',
                    method: 'POST',
                    body: data,
                };
            },
            async onQueryStarted(args, { dispatch, queryFulfilled}) {
                try {
                    const { data } = await queryFulfilled;                    
                    dispatch(setCurrentUser({currentUser: data.data.user, token: data.data.token}));
                } catch (error) {}
            }
        }),
        resetPassword: builder.mutation<ResetPasswordResultType, ResetPasswordRequestType>
        (
            {
                query({...data}) {
                    return {
                        url: `update-password`,
                        method: 'POST',
                        body: data
                    }
                },
                transformResponse: (result: any):ResetPasswordResultType => {
                    return result.data;
                }
            }
        )     
    })
});

export const {
    useLoginUserMutation,
    useVerifyLoginUserMutation,
    useResetPasswordMutation
} = authApi;