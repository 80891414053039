import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetPartnersQuery } from "../../../../redux/features/partners/partnersApi";
import { PartnerType } from "../../../../redux/features/partners/types";
import {
  formatDateTime,
  friendlyName,
} from "../../../../_shared/utils/uiHelpers";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../_shared/utils/_urls";
import _ from "lodash";

type FilterProps = {
  searchText: string | undefined;
};

const PartnersGrid = (props: FilterProps) => {
  const navigate = useNavigate();
  const [partners, setPartners] = useState<PartnerType[]>([]);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          onClick={() => {
            navigate(`${APP.PARTNERS}/${params.row.id}`);
          }}
        >
          {`${params.row.name}${
            params.row.abbreviation !== null
              ? ` - ${params.row.abbreviation}`
              : ""
          }`}
        </Link>
      ),
    },
    { field: "phone_number", headerName: "Phone", flex: 1 },
    {
      field: "city",
      headerName: "Location",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.city}, ${params.row.state}`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];
  const { data = [], isFetching, isSuccess, refetch } = useGetPartnersQuery({});

  const filteredPartners = _.filter(partners, (p) => {
    const pValues = _.values(p);
    return (
      _.findIndex(pValues, (v: any) => {
        return _.toUpper(v).includes(_.toUpper(props.searchText));
      }) !== -1
    );
  });

  useEffect(() => {
    if (isSuccess) {
      setPartners(data);
    }
  });

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          disableRowSelectionOnClick={true}
          rows={filteredPartners}
          columns={columns}
          loading={isFetching}
        />
      </div>
    </Box>
  );
};

export default PartnersGrid;
