import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useGetGiftCertificatesQuery } from "../../../../../redux/features/commerce/gift-certificates/giftCertificatesApi";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Link } from "@mui/material";
import { APP } from "../../../../../_shared/utils/_urls";
import React from "react";
import { formatDateTime } from "../../../../../_shared/utils/uiHelpers";
import { GiftCertificateType } from "../../../../../redux/features/commerce/gift-certificates/types";

const GiftCertificatesGrid = () => {
  const navigate = useNavigate();
  const [giftCertificates, setGiftCertificates] = useState<
    GiftCertificateType[]
  >([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Gift Certificate",
      width: 350,
      renderCell: (params: GridRenderCellParams) => (
        <React.Fragment>
          <Link
            onClick={() => {
              navigate(`${APP.GIFT_CERTIFICATES}/${params.row.id}`);
            }}
          >
            {params.value}
          </Link>
        </React.Fragment>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      renderCell: (params: GridRenderCellParams) => `\$${params.value}`,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.created_at)} by ${
          params.row.created_by_name
        }`;
      },
    },
    {
      field: "updated",
      headerName: "Updated",
      flex: 1,
      valueGetter: (params) => {
        return `${formatDateTime(params.row.updated_at)} by ${
          params.row.updated_by_name
        }`;
      },
    },
  ];

  const {
    data = [],
    isFetching,
    isSuccess,
    refetch,
  } = useGetGiftCertificatesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setGiftCertificates(data);
    }
  });

  return (
    <Box style={{ display: "flex", height: "100%" }}>
      <Box style={{ flexGrow: 1 }}>
        <DataGridPro
          autoHeight
          density="compact"
          disableRowSelectionOnClick={true}
          rows={giftCertificates}
          columns={columns}
          loading={isFetching}
        />
      </Box>
    </Box>
  );
};

export default GiftCertificatesGrid;
