import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useUpdateSettingsDashboardMutation,
  useGetDashboardSettingsQuery,
} from "../../../../redux/features/settings/settingsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { merge } from "lodash";
import { ProfileType } from "../../../../redux/features/profile/types";
import { APP } from "../../../../_shared/utils/_urls";
import { DashboardAlertSettingsType } from "../../../../redux/features/settings/types";

type EditDashboardAlertProps = {
  show: boolean;
  dashboardAlert: DashboardAlertSettingsType | Record<string, any> | undefined;
  doneEditing: (reload: boolean) => void;
};

const EditDashboardAlertModal = (props: EditDashboardAlertProps) => {
  const handleClose = () => {
    props.doneEditing(true);
  };
  const [updateDashboardAlertSettings, { isLoading }] =
    useUpdateSettingsDashboardMutation();
  const [settingsToUpdate, setSettingsToUpdate] = useState<
    DashboardAlertSettingsType | Record<string, any> | undefined
  >();

  const submitHandler = (values: any) => {
    const payload = merge({}, settingsToUpdate, values);
    updateDashboardAlertSettings(payload)
      .unwrap()
      .then((result) => {
        console.log(result);
        if (result) {
          props.doneEditing(true);
        } else {
          alert(
            "There was an error updating the dashboard settings. Please review your inputs and try again."
          );
        }
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dashboard_alert_text: settingsToUpdate?.dashboard_alert_text,
      dashboard_alert_type: settingsToUpdate?.dashboard_alert_type,
      dashboard_alert_is_active: settingsToUpdate?.dashboard_alert_is_active,
    },
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        submitHandler(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  useEffect(() => {
    setSettingsToUpdate(props.dashboardAlert);
  }, [props.dashboardAlert]);

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Edit profile</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 3 }}>
            <TextField
              error={Boolean(
                formik.touched.dashboard_alert_text &&
                  formik.errors.dashboard_alert_text
              )}
              fullWidth
              label="Alert Text"
              name="dashboard_alert_text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              required
              value={formik.values.dashboard_alert_text}
            />
            <FormControl fullWidth required>
              <InputLabel id="dashboard_alert_type">Type</InputLabel>
              <Select
                labelId="dashboard_alert_type"
                name="dashboard_alert_type"
                label="Type"
                value={formik.values.dashboard_alert_type}
                error={Boolean(
                  formik.touched?.dashboard_alert_type &&
                    formik.errors?.dashboard_alert_type
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value="warning" key="warning">
                  Warning
                </MenuItem>
                <MenuItem value="info" key="info">
                  Info
                </MenuItem>
                <MenuItem value="success" key="success">
                  Success
                </MenuItem>
                <MenuItem value="error" key="error">
                  Error
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel id="dashboard_alert_is_active">Active</InputLabel>
              <Select
                labelId="dashboard_alert_is_active"
                name="dashboard_alert_is_active"
                label="Active"
                value={formik.values.dashboard_alert_is_active}
                error={Boolean(
                  formik.touched?.dashboard_alert_is_active &&
                    formik.errors?.dashboard_alert_is_active
                )}
                onChange={formik.handleChange}
              >
                <MenuItem value="true" key={"true"}>
                  Yes
                </MenuItem>
                <MenuItem value="false" key="false">
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditDashboardAlertModal;
