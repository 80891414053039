import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Divider,
  Stack,
  styled,
  Typography,
  Chip,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DexaType, TestType } from "../../../redux/features/tests/types";
import { APP } from "../../../_shared/utils/_urls";
import { useGetTestQuery } from "../../../redux/features/tests/testsApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { formatAudit, formatDateTime } from "../../../_shared/utils/uiHelpers";
import DexaTest from "./components/dexa/dexa-standard";
import DexaLimitedTest from "./components/dexa/dexa-limited";
import RmrTest from "./components/rmr";
import BodPodTest from "./components/bod-pod";
import VO2Test from "./components/vo2";
import SecaTest from "./components/seca";
import EditIcon from "@mui/icons-material/Edit";
import UpdateStatusModal from "./components/StatusUpdate";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import styles from "./detail.module.css";
import { useAppSelector } from "../../../redux/hooks";
import CancelAppointmentModal from "./components/CancelAppointment";
import PreviewIcon from "@mui/icons-material/Preview";
import GeneratePreviewModal from "./components/GeneratePreview";
import HyrdostaticTest from "./components/hydrostatic";
import UpdateAppointmentModal from "./components/UpdateAppointment";
import { merge } from "lodash";
import DexaBmdTest from "./components/dexa-bmd/standard";
import { test_types } from "../../../_shared/utils/testTypes";
import DexaBmdStandardTest from "./components/dexa-bmd/standard";
import DexaBmdLimitedTest from "./components/dexa-bmd/limited";

type TestResultsType = {
  test: TestType | undefined;
  isEditing: boolean;
  doneEditing: (reload: boolean) => void;
  standardOrLimited: string;
};

const TestResults = (props: TestResultsType) => {
  if (props.test?.test_type === "DEXA") {
    if (props.standardOrLimited === "Standard") {
      return (
        <DexaTest
          test={props.test}
          isEditing={props.isEditing}
          doneEditing={props.doneEditing}
        />
      );
    } else {
      return (
        <DexaLimitedTest
          test={props.test}
          isEditing={props.isEditing}
          doneEditing={props.doneEditing}
        />
      );
    }
  } else if (props.test?.test_type === "DEXABMD") {
    if (props.standardOrLimited === "Standard") {
      return (
        <DexaBmdStandardTest
          test={props.test}
          isEditing={props.isEditing}
          doneEditing={props.doneEditing}
        />
      );
    } else {
      return (
        <DexaBmdLimitedTest
          test={props.test}
          isEditing={props.isEditing}
          doneEditing={props.doneEditing}
        />
      );
    }
  } else if (props.test?.test_type === "RMR") {
    return (
      <RmrTest
        test={props.test}
        isEditing={props.isEditing}
        doneEditing={props.doneEditing}
      />
    );
  } else if (props.test?.test_type === "BODPOD") {
    return (
      <BodPodTest
        test={props.test}
        isEditing={props.isEditing}
        doneEditing={props.doneEditing}
      />
    );
  } else if (props.test?.test_type === "VO2") {
    return (
      <VO2Test
        test={props.test}
        isEditing={props.isEditing}
        doneEditing={props.doneEditing}
      />
    );
  } else if (props.test?.test_type === "HYDROSTATIC") {
    return (
      <HyrdostaticTest
        test={props.test}
        isEditing={props.isEditing}
        doneEditing={props.doneEditing}
      />
    );
  } else if (props.test?.test_type === "SECA") {
    return (
      <SecaTest
        test={props.test}
        isEditing={props.isEditing}
        doneEditing={props.doneEditing}
      />
    );
  } else {
    return <p>Appointment type unknown</p>;
  }
};

const StatusChip = (props: any) => {
  if (props.status === "Complete" || props.status === "CompleteAndSend") {
    return (
      <Chip
        label={
          props.status === "CompleteAndSend"
            ? "Complete and Sent"
            : props.status
        }
        color="success"
      />
    );
  } else if (props.status === "Flagged") {
    return <Chip label={props.status} color="error" />;
  } else if (props.status === "NeedsApproval") {
    return <Chip label="Needs Approval" color="warning" />;
  } else {
    return <Chip label={props.status} />;
  }
};

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const AppointmentDetail = () => {
  const currentUser = useAppSelector(
    (state) => state.currentUserState.currentUser
  );
  const params = useParams();
  const navigate = useNavigate();
  const [test, setTest] = useState<TestType>();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canEditResults, setCanEditResults] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditingResults, setIsEditingResults] = useState<boolean>(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isGeneratingPreview, setIsGeneratingPreview] =
    useState<boolean>(false);
  const [dataScreen, setDataScreen] = useState<string>("");

  const {
    data = undefined,
    isFetching,
    isSuccess,
    refetch,
  } = useGetTestQuery(params.appointment_id ?? skipToken);

  const onCancelAppointment = () => {
    setIsCancelling(true);
  };

  const onGeneratePreview = () => {
    setIsGeneratingPreview(true);
  };

  const onDoneGeneratingPreview = () => {
    setIsGeneratingPreview(false);
  };

  const onEdit = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const onDoneEditing = (updatedTest?: TestType) => {
    if (updatedTest) {
      setTest(merge({}, test, updatedTest));
    }
    setIsEditing(false);
  };

  const onChangeDataScreen = (newScreen: string) => {
    setDataScreen(newScreen);
  };

  const onEditResults = (e: any) => {
    e.preventDefault();
    setIsEditingResults(true);
  };

  const onDoneEditingResults = (reload: boolean) => {
    setIsEditingResults(false);
    if (reload) {
      refetch();
      setIsUpdatingStatus(true);
    }
  };

  const onDoneCancelling = (
    reload: boolean,
    updated_test: TestType | null | undefined
  ) => {
    setIsCancelling(false);
    if (reload) {
      setTest(updated_test!);
      navigate(`${APP.APPOINTMENTS}`);
    }
  };

  const onDoneUpdatingStatus = (
    reload: boolean,
    updated_test: TestType | null | undefined
  ) => {
    setIsUpdatingStatus(false);
    if (reload) {
      setTest(updated_test!);
      navigate(`${APP.CUSTOMERS}/${updated_test?.user_id}`);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setTest(data);
    }
  }, [data]);

  useEffect(() => {
    setCanEditResults(
      test?.status !== "Complete" || currentUser?.role === "SUPERADMIN"
    );
    setCanEdit(currentUser?.role === "SUPERADMIN");

    if (test?.test_type === "DEXA" || test?.test_type === "DEXABMD") {
      if (test?.results !== null) {
        let type = (test?.results as DexaType).screen_type as string;
        setDataScreen(type);
      } else {
        setDataScreen("Standard");
      }
    }
  }, [test]);

  if (test !== undefined) {
    return (
      <React.Fragment>
        <Container>
          <Breadcrumbs>
            <Link
              underline="hover"
              color="inherit"
              onClick={() => {
                navigate(`${APP.APPOINTMENTS}`);
              }}
            >
              Appointments
            </Link>
            <Typography color="text.primary">
              {`${formatDateTime(test?.appointment_date)} - ${
                test?.customer_name
              } -
                  ${test?.partner_name}`}
            </Typography>
          </Breadcrumbs>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
          >
            <IconButton
              onClick={onGeneratePreview}
              color="primary"
              aria-label="Preview"
            >
              <PreviewIcon />
            </IconButton>
            {test?.email_sent && (
              <Chip
                icon={<MarkEmailReadIcon />}
                className={styles.chipNoLabel}
                title="Results have been emailed to Customer"
              />
            )}

            <StatusChip status={test?.status} />
            {currentUser?.role === "SUPERADMIN" && (
              <Button variant="text" onClick={onCancelAppointment}>
                Cancel
              </Button>
            )}
          </Stack>
        </Container>
        {test?.status === "Flagged" && (
          <Container sx={{ my: 3 }}>
            <Alert severity="error">Note: {test?.note}</Alert>
          </Container>
        )}

        <Container>
          <Grid container spacing={2} sx={{ my: 4 }}>
            <Grid item xs={12} sm={6}>
              <Item>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14, mb: 1.5 }}
                    color="text.secondary"
                  >
                    Customer Information
                  </Typography>
                  <Typography variant="h5" component="div">
                    {test?.customer_name}
                  </Typography>
                  {currentUser?.role === "SUPERADMIN" && (
                    <React.Fragment>
                      <Typography color="text.secondary">
                        {test?.customer_email}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {test?.customer_phone}
                      </Typography>
                      <Typography variant="body2">
                        {test?.customer_profile?.address_line_1}
                        <br />
                        {test?.customer_profile?.address_line_2}
                        <br />
                        {test?.customer_profile?.city}
                        {test?.customer_profile?.state !== undefined && ", "}
                        {test?.customer_profile?.state}
                        {test?.customer_profile?.postal_code !== undefined &&
                          ", "}
                        {test?.customer_profile?.postal_code}
                      </Typography>
                    </React.Fragment>
                  )}
                </CardContent>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Item>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14, mb: 1.5 }}
                    color="text.secondary"
                  >
                    Appointment Information
                    {canEdit && !isEditing && (
                      <Button
                        variant="text"
                        onClick={onEdit}
                        sx={{ float: "right" }}
                      >
                        <EditIcon />
                      </Button>
                    )}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatDateTime(test?.appointment_date)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {test?.test_type}
                  </Typography>
                  {currentUser?.role === "SUPERADMIN" && (
                    <React.Fragment>
                      <Typography variant="body2">
                        {test?.partner_name} <br />
                        {test?.partner.phone_number}
                        <br />
                        {test?.partner.address_line_1}
                        <br />
                        {test?.partner.address_line_2}
                        {test?.partner.address_line_2 !== null &&
                          test?.partner.address_line_2.length > 0 && <br />}
                        {test?.partner.city}
                        {", "}
                        {test?.partner.state}
                        {", "}
                        {test?.partner.postal_code}
                      </Typography>
                      {test?.test_type === "VO2" && (
                        <Typography>
                          Locked: {test?.locked ? "Yes" : "No"}
                        </Typography>
                      )}
                    </React.Fragment>
                  )}
                </CardContent>
              </Item>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
            Results
            {canEditResults && !isEditingResults && (
              <Stack direction="row" spacing={2} sx={{ float: "right" }}>
                {(test.test_type === "DEXA" ||
                  test.test_type === "DEXABMD") && (
                  <ButtonGroup variant="outlined" color="secondary">
                    <Button
                      color="secondary"
                      onClick={() => onChangeDataScreen("Limited")}
                      style={{
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        minWidth: "107px",
                        backgroundColor:
                          dataScreen === "Limited" ? "#b8a8b6" : "white",
                        color: dataScreen === "Limited" ? "white" : "#b8a8b6",
                      }}
                    >
                      Limited
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => onChangeDataScreen("Standard")}
                      style={{
                        borderTopRightRadius: "20px",
                        borderBottomRightRadius: "20px",
                        minWidth: "107px",
                        backgroundColor:
                          dataScreen === "Standard" ? "#b8a8b6" : "white",
                        color: dataScreen === "Standard" ? "white" : "#b8a8b6",
                      }}
                    >
                      Standard
                    </Button>
                  </ButtonGroup>
                )}
                <Button variant="text" onClick={onEditResults}>
                  <EditIcon />
                </Button>
              </Stack>
            )}
          </Typography>
          <Divider />
          <TestResults
            test={test}
            isEditing={isEditingResults && canEditResults}
            doneEditing={onDoneEditingResults}
            standardOrLimited={dataScreen}
          />
        </Container>
        <Container sx={{ mt: 3 }}>
          <List>
            <ListItem>
              <ListItemText
                primary={formatAudit(
                  test?.created_by_name,
                  test?.created_at,
                  "Created"
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={formatAudit(
                  test?.updated_by_name,
                  test?.updated_at,
                  "Updated"
                )}
              />
            </ListItem>
          </List>
        </Container>
        <UpdateStatusModal
          test={test}
          show={isUpdatingStatus}
          doneUpdatingStatus={onDoneUpdatingStatus}
        />
        <CancelAppointmentModal
          test={test}
          show={isCancelling}
          doneCancelling={onDoneCancelling}
        />
        <GeneratePreviewModal
          test={test}
          show={isGeneratingPreview}
          close={onDoneGeneratingPreview}
        />
        <UpdateAppointmentModal
          doneUpdating={onDoneEditing}
          appointmentToUpdate={test}
          show={isEditing}
        />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
};

export default AppointmentDetail;
