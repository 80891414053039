import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store";
import {
  CreateProductTestResult,
  GetProductTestResult,
  GetProductTestsResult,
  ProductTestType,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_HOST as string;
const API_KEY = process.env.REACT_APP_API_KEY as string;

export const ProductTestsApi = createApi({
  reducerPath: "ProductTestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/admin/commerce/`,
    prepareHeaders: (headers) => {
      headers.set("x-api-key", API_KEY);
      headers.set("Authorization", store.getState().currentUserState.token!);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllProductTestsByProduct: builder.query<ProductTestType[], {}>({
      query(product_id) {
        return {
          url: `products/${product_id}/product-tests`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (result: GetProductTestsResult) => {
        return result.data;
      },
    }),
    updateProductTest: builder.mutation<
      ProductTestType,
      Partial<ProductTestType> & Pick<ProductTestType, "id">
    >({
      query({ id, ...data }) {
        return {
          url: `product-tests/${id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (result: GetProductTestResult) => {
        return result.data;
      },
    }),
    createProductTest: builder.mutation<
      CreateProductTestResult,
      Partial<ProductTestType>
    >({
      query(data) {
        return {
          url: `product-tests`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (result: any): CreateProductTestResult => {
        return result.data;
      },
    }),
    deleteProductTest: builder.mutation<boolean, string>({
      query(Product_id) {
        return {
          url: `product-tests/${Product_id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllProductTestsByProductQuery,
  useCreateProductTestMutation,
  useUpdateProductTestMutation,
  useDeleteProductTestMutation,
} = ProductTestsApi;
